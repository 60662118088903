import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SearchModule } from 'src/app/modules/SearchModule';
import { CatagoyrService } from 'src/app/service/catagory/catagory.service';
import { LocationService } from 'src/app/service/location/Sales_location.service';

@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.css']
})
export class SearchFormComponent implements OnInit {

  public catForm: FormGroup;
  public communityMember: any
  public catagorys:any
  public  locationsList :any
  constructor(
    public dialogRef: MatDialogRef<SearchFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SearchModule,
    private catServive: CatagoyrService,
    private locationService: LocationService,
    private  route:Router,
    public snackBar: MatSnackBar) { }

    ngOnInit(): void {
       
    this.getSource()
   
    }
    async  getSource(){
  
      const c = await this.catServive.getallProductCatagory().toPromise();
      if (c != null){
        this.catagorys = c;
          } 
      const l = await this.locationService.getallSalesLocation().toPromise();
      if (l != null){
        this.locationsList = l;
        
     } 
    }
  onNoClick(): void {
    this.dialogRef.close();
  }
  onSearchClick(){
    var c ,l
   
  if(this.data.catagory === undefined  || this.data.catagory ==="0" ) {
    c=0
  }    else c=this.data.catagory.id
   if(this.data.location === undefined  ||  this.data.location === "0"  )  l=0
   else  l=this.data.location.id
    
  this.route.navigate(['/search'],{ queryParams: { query: this.data.query,loc:l,subcat:c } }).then(() => {
    window.location.reload();
  });
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

}

