import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-image-viewer-dialog',
  standalone: true,
  imports: [],
  templateUrl: './image-viewer-dialog.component.html',
  styleUrl: './image-viewer-dialog.component.css'
})
export class ImageViewerDialogComponent {
  currentIndex: number = 0;

  constructor(
    public dialogRef: MatDialogRef<ImageViewerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { images: string[] }
  ) { }

  closeDialog(): void {
    this.dialogRef.close();
  }

  
  nextImage(event: Event): void {
    event.stopPropagation();
    if (this.currentIndex < this.data.images.length - 1) {
      this.currentIndex++;
    } else  this.currentIndex=0
  }

  previousImage(event: Event): void {
    event.stopPropagation();
    if (this.currentIndex > 0) {
      this.currentIndex--;
    }else  this.currentIndex=this.data.images.length - 1
  }

  preventClosing(event: Event): void {
    event.stopPropagation();
  }
}