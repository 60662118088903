import { Component, OnInit } from '@angular/core';
import {  Validators, FormBuilder, SelectControlValueAccessor } from '@angular/forms';
import { UserService } from 'src/app/service/user/user.service';
import { User } from 'src/app/modules/User';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Address } from 'src/app/modules/Address';
import { AddressService } from 'src/app/service/address/address.service';
import { LocationService } from 'src/app/service/location/Sales_location.service';

@Component({
  selector: 'app-change-address',
  templateUrl: './change-address.component.html',
  styleUrls: ['./change-address.component.css']
})
export class ChangeAddressComponent implements OnInit {
   addressId
  address=new Address(0,"",null) 
  public salesLocation;
  form=this.fb.group({
    address: [this.address.address,[Validators.required]],
     location: [this.address.location,[Validators.required]]
   });
  constructor(private  adressService:AddressService,
              private fb: FormBuilder,
              private salesLocationService: LocationService,
              private snackbar:MatSnackBar) {

               }

  ngOnInit(): void {
    this.getSource()
  }
  async getSource(){
      const s = await this.salesLocationService.getallSalesLocation().toPromise();
    if (s != null) this.salesLocation = s;
    else console.log('  Sales Location retrieve failed!!! ');

   const p=await this.adressService.getAddress().toPromise()
   console.log("address <= "+JSON.stringify(p))
   if(p != null){
     this.address=p
      this.setValues(p);
   } else {
     this.setValues(this.address);
   }
}

  onSubmit(){
    console.log("Submit Button Clicked")
    this.address.id=this.addressId
    this.address= new Address(this.address.id,this.form.value.address,this.form.value.location) 
    
    // console.log("address = > "+JSON.stringify(this.address))
     this.adressService.saveAddress(this.address).subscribe(
       reuslt=> {
         this.openSnackBar("User Address Updated Successfully","Message")
       },
       error => { this.openSnackBar("User Address Updated Fail","Error")}
     )
  }
  openSnackBar( message,type) {
    this.snackbar.open(message, type, {
      duration: 2000,
    });
  }

  setValues(p:Address){
    this.form.patchValue({
      address: p.address,
    location: p.location,
       
    })
    for (let s = 0; s < this.salesLocation.length; s++) {
      if (this.salesLocation[s].id == this.address.location.id) {
        this.form.patchValue({
          location: this.salesLocation[s]
        })
      }


    }
    this.addressId=p.id
  }
}
