import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-coracel',
  templateUrl: './coracel.component.html',
  styleUrls: ['./coracel.component.css']
})
export class CoracelComponent implements OnInit, OnDestroy {
  images = [
    { url: 'assets/corasel.jpg' , actionUrl: '/about_us',text:"እንቛዕ ብደሓን መጻኹም ሸያጥን ዓዳግን ዝራኸብሉ መኣዲ ኣብ ጁባ"},
    { url: 'assets/coracel 2.jpg' , actionUrl: '/about_us',text:"ካብ ገዛኻ ኸይወጻኻ  ዕዳጋ ጁባ  ዳህስስ "},
   
  ];

  activeSlide = 0;
  intervalId?: number;

  ngOnInit() {
    this.startAutoSlide();
  }

  ngOnDestroy() {
    this.clearAutoSlide();
  }

  startAutoSlide(): void {
    this.intervalId = window.setInterval(() => {
      this.next();
    }, 5000);
  }

  clearAutoSlide(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  next(): void {
    this.activeSlide++
    if(this.activeSlide>this.images.length-1)
      this.activeSlide=0;
    // this.activeSlide = (this.activeSlide + 1) % this.images.length;
  }

  previous(): void {
    this.activeSlide--
    if(0>this.activeSlide)
      this.activeSlide=this.images.length-1;
    // this.activeSlide = (this.activeSlide - 1 + this.images.length) % this.images.length;
  }

  onButtonClick(url: string): void {
    window.location.href = url;
  }
  getText(){
    return this.images[this.activeSlide].text
  }
}