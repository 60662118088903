<section fxLayout="row" fxLayoutAlign="start center" style="margin: 20px 20px 20px 20px;">

  <mat-card fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="0px" style="width: 100%;padding-top: 5px;" class="mat-elevation-z8">

      <div class="" fxLayout="row" fxLayoutAlign="center end" style="width: 100%;">
          <mat-card-header>
      <mat-card-title>List Of Sent Email</mat-card-title>
  </mat-card-header>
      </div>

      <mat-card-content>
          <div fxLayoutAlign="space-between">
              <mat-form-field fxFlex="30%" appearance="outline">
                  <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Filter" />
              </mat-form-field>
              <div class="date-range">
                  <mat-form-field appearance="outline" >
                      <mat-label>Enter a date range</mat-label>
                      <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                        <input matStartDate formControlName="start" placeholder="Start date">
                        <input matEndDate formControlName="end" placeholder="End date">
                      </mat-date-range-input>
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-date-range-picker #picker></mat-date-range-picker>
                    
                      <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                      <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                    </mat-form-field>
                    <button  mat-button (click)="dateRangeFilter()"><i class="fas fa-search " style="color: #00a651;"></i></button>
              </div>
              
             
          </div>

          <mat-table [dataSource]="dataSource" matSort>
              <ng-container matColumnDef="id">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Id</mat-header-cell>
                  <mat-cell *matCellDef="let element">{{ element.id }}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="user">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>User</mat-header-cell>
                  <mat-cell *matCellDef="let element"><a [routerLink]="['/admin/userdetail']" target="_blank" [queryParams]="{uid: element.user.id}" >{{ element.user.fullName }}</a></mat-cell>
              </ng-container>
              <ng-container matColumnDef="email_title">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Title</mat-header-cell>
                  <mat-cell *matCellDef="let element">{{ element.title}}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="status">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
                  <mat-cell *matCellDef="let element">{{ element.status}}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="action">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>action</mat-header-cell>
                  <mat-cell *matCellDef="let element" >
                       <button mat-button  (click)="showDetail(element)" >Detail</button>
                       <button mat-button  color="primary" (click)="deleteEmailSent(element.id)" >Delete</button>

                  </mat-cell>
              </ng-container>
               

              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          </mat-table>

         
      </mat-card-content>


      <mat-card-footer fxLayout="row" fxLayoutAlign="space-around space-around">
         <div class="pagginator">
          <mat-paginator [pageSize]="10" [pageSizeOptions]="[1, 5, 10, 20]">
             
          </mat-paginator>
         </div> 
          
         
      </mat-card-footer>
  </mat-card>
</section>
