import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateAgo',
  pure: true
})
export class DateAgoPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (!value) return '';
       console.log("Date Value ==>"+JSON.stringify(value));
       
        const seconds = Math.floor((new Date()).getTime() - this.convertDate(value).getTime() )/1000;
        const intervals:any = {
          'ዓመት': 31536000,
          'ወርሒ': 2592000,
          'ሰሙን': 604800,
          'መዓልቲ': 86400,
          'ሰዓት': 3600,
          'ደቂቅ': 60,
          'ካልኢት': 1
        };
    
        for (const interval in intervals) {
          const count = Math.floor(seconds / intervals[interval]);
          if (count >= 1) {
            return count === 1 ? ` ቅድሚ ${count} ${interval}` : `ቅድሚ ${count} ${interval}`;
          }
        }
    
        return 'ቀረባ ግዜ';
    
      }
       convertDate(v:any):Date{
      return new Date(v[0], v[1] - 1, v[2], v[3], v[4], v[5]);
    }
       
       
       
    
    }