import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
 
 
import { MatPaginator } from '@angular/material/paginator';
 
 
 
import { UserPostService } from 'src/app/service/post/User_post.service';
import { UserTransaction } from 'src/app/modules/forms/user-transaction/user-transaction.module';
 import { DepositeReceiptService } from 'src/app/service/depositeSerivce/deposite-receipt-service.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { DepositReciept } from 'src/app/modules/DepositReciept';
import { ReceiptDetailDialogComponent } from '../receipt-detail-dialog/receipt-detail-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { PostDetailDialogComponent } from '../post-detail-dialog/post-detail-dialog.component';
 
 

@Component({
  selector: 'app-payment-list',
  templateUrl: './payment-list.component.html',
  styleUrls: ['./payment-list.component.css']
})
export class PaymentListComponent implements OnInit {
  displayedColumns = [ 'date',  'type','deposite','expense','balance','show'];
  dataSource = new MatTableDataSource<UserTransaction>();
   
 
  @ViewChild(MatPaginator) paginator: MatPaginator;
 
 

  constructor(
    private userService: UserPostService,
    private deposteReciptService:DepositeReceiptService,
    public dialog: MatDialog,
    public snackbar: MatSnackBar
  ) {}
 



  ngOnInit(): void {
    this.getSourcedata();
  }
  getSourcedata(){
    this.userService.getUserTransactions().subscribe(
      data=> {
         this.dataSource.data =data.sort((x, y) => +new Date(x.date) - +new Date(y.date));
             },
      error=>{
       
      }
    );
    
  }
    ngAfterViewInit() {
 
    this.dataSource.paginator = this.paginator;
  }
  getBalance(){
  let total=0

  
  for (let index = 0; index < this.dataSource.data.length; index++) {
   
    total=total+(this.dataSource.data[index].deposite_amount -this.dataSource.data[index].expense_amaount)
    this.dataSource.data[index].balance=total
  }

  return total;
  }

 /**
  * displays dialog for detail of receipt or summary of Post 
  * 
  */
  showRowDetail(transaction:UserTransaction){
     
 if(transaction.transactionType==='DEPOSITE'){
   this.displayDeposite(transaction.receiptOrPost_id);
 }else {  
   this.openPostDetailDialog(transaction.receiptOrPost_id)
 }
  }


  /**
   *  retrieve depositeReceipt Data  and send the data to dialog
   * @param id  depositeReceipt  id 
   */
  displayDeposite(id:number) {
    this.deposteReciptService.getDepositeReceiptById(id).subscribe(
      result => {
        this.openDepositeReceiptDialog(result)
      },
      error => {
        this.openSnackBar("Retrieve Data Fail", "error")
      }
    )
     
    }
  
    openDepositeReceiptDialog(depositDetail: DepositReciept): void {
      const dialogRef = this.dialog.open(ReceiptDetailDialogComponent, {
        width: '650px',
        data: depositDetail,
      });
    }

     
    openPostDetailDialog(post_id: number): void {
      const dialogRef = this.dialog.open(PostDetailDialogComponent, {
        width: '650px',
        data: post_id,
      });
    }
    openSnackBar(message: string, action: string) {
    this.snackbar.open(message, action, {
      duration: 2000,
    });
  }
}
