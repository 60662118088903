import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenStorageService } from '../tokenStorage/token-storage.service';
import { GlobalConstants } from 'src/app/utility/global-constants';
import { SentEmailToUser } from 'src/app/modules/SentEmailToUser';


const AUTH_API = GlobalConstants.serverUrl+'adv/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class SentEmailToUserService {
   
  constructor(
    private http: HttpClient,
    private userToken: TokenStorageService
  ) { }

  getAllRowIdDesc() {
    return this.http.get<SentEmailToUser[]>(`${AUTH_API}send_email`, httpOptions);
  }


  getSentEmailToUserById(id: number) {
    return this.http.get<SentEmailToUser>(`${AUTH_API}send_email/${id}`, httpOptions);
  }
 
  getSentEmailToUserByDate(start, end) {
    return this.http.get<SentEmailToUser[]>(`${AUTH_API}send_email/start/${start}/end/${end}`, httpOptions);
  }
  deleteSentEmailToUserById(id: number) {
    return this.http.delete(`${AUTH_API}send_email/${id}`, httpOptions);
  }

  saveNewSentEmailToUser(sentEmail:SentEmailToUser,id: number) {
    return this.http.post(`${AUTH_API}send_email/user/${id}`,sentEmail, httpOptions);
  }
 
  getCurrentUserId() {
    return this.userToken.getCurrentUserId();
  }
}