<div class="dialog-title">
    <h3>Sent Email Detail</h3>
  </div>
  <div class="dialog-body">
    <h3>Title : {{data.title}}</h3>
    <P> message : {{data.message}}</P>
  </div>
  <div class="dialog-button">
    
    <button class="btn-save"  mat-button  color="primary" (click)="onOk()">OK</button>
  </div>

