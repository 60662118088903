<app-lodding-spinner></app-lodding-spinner>
<section>
<section>


    <div class="wrapper" *ngIf="!startPosting">
     
       <app-prepost></app-prepost>
     

    </div>   



    <div class="wrapper" *ngIf="startPosting">
        <div class="notice-bord  " *ngIf="this.post.post_status.status=='ERROR'">
            <p>{{this.post.post_status.rejectionReason}}</p>
            <p>And Apply agian!!!</p>
        </div>
        <div class="notice-bord  " *ngIf="this.post.post_status.status=='DISABLED'">
            <p>Post Disabled By Admin !!!</p>
        </div>

        <div class="header_New">
            <h1>Edit Ads Form</h1>
            <p  style="display: none;">{{ postForm.value | json}}</p>
        </div>
        <hr>
        <!-- (ngSubmit)="submitPost1()" -->
        <form [formGroup]="postForm">
            <div  class="flex_column_center_center">
                <!-- id field -->
                <mat-form-field style="display: none;" appearance="outline">
                    <mat-label>Id</mat-label>
                    <input matInput placeholder="Id" type="text" formControlName="id">
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>Catagory</mat-label>
                    <mat-select formControlName="productSubCatagory" (selectionChange)="onCatagoryChange($event)"
                        required>
                        <mat-option value=""> </mat-option>
                        <mat-optgroup *ngFor="let catagory of catagorys | disableOption" [label]="catagory.name" style="background-color: rgba(87, 102, 84, 0.5); display: block">
                            <mat-option *ngFor="let subcat of catagory.productSubCatagory | disableOption" [value]="subcat" style="background-color: white; display: block">
                                {{subcat.name}}
                            </mat-option>
                        </mat-optgroup>
                    </mat-select>
                </mat-form-field>

                <!-- //sale Location   -->
                <mat-form-field  appearance="outline">
                    <mat-label>Sales Location</mat-label>
                    <mat-select formControlName="salesLocation" required>
                        <mat-option *ngFor="let sal of salesLocations" [value]="sal">
                            {{sal.city}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <!-- Product or Service description -->
                <div class="" *ngIf="isSubCatSelected">

                    <mat-form-field  appearance="outline">
                        <mat-label>Product or Service description</mat-label>
                        <input matInput placeholder=" " required formControlName="description" required>
                    </mat-form-field>
                </div>

                <!-- specification  start  -->
                <div class="" *ngIf="isSubCatSelected" formGroupName="specification">
                    <div *ngFor="let form_elem of selectSubCat.specificationList">
                        <div [ngSwitch]="form_elem.controlType">
                            <!-- text box section -->
                            <div class="select-None" *ngIf="(form_elem.controlType==='textbox')">
                                <mat-form-field  appearance="outline">
                                    <!-- <mat-label>{{form_elem.label}}</mat-label> -->
                                    <mat-label>{{form_elem.placeholder}}</mat-label>
                                    <input matInput [pattern]="form_elem.pattern" formControlName="{{form_elem.key}}"
                                         [id]="form_elem.key" [type]="form_elem.type">
                                    <!-- <div color="accent" *ngIf="!isValid">{{question.label}} is required</div> -->
                                </mat-form-field>
                                <!-- drop down selection  section   -->
                            </div>
                            <div *ngIf="(form_elem.controlType==='dropdown')&&(form_elem.selectType==='NONE')">
                                <mat-form-field  appearance="outline">
                                    <!-- <mat-label>{{form_elem.label}}</mat-label> -->

                                    <mat-label>{{form_elem.placeholder}}</mat-label>
                                     
                                    <mat-select [formControlName]="form_elem.key"    [id]="form_elem.key">
                                        <mat-option *ngFor="let opt of form_elem.options" [value]="opt.item">
                                            {{opt.item}}

                                        </mat-option>
                                        <mat-option value="OTHER">OTHER</mat-option>
                                    </mat-select>
                                </mat-form-field>
                          
                            </div>
                            <div *ngIf="(form_elem.controlType==='dropdown')&&(form_elem.selectType==='PARENT')">
                                <mat-form-field appearance="outline">
                                    <!-- <div class="select-parent" *ngIf="form_elem.selectType==='PARENT'"> -->
                                    <!-- <mat-label>{{form_elem.label}}</mat-label> -->
                                    <mat-label>{{form_elem.placeholder}}</mat-label>
                                    <mat-select [formControlName]="form_elem.key"    
                                        (ngModelChange)="changeParent($event,form_elem.key)" [id]="form_elem.key">
                                        <mat-option *ngFor="let opt of form_elem.options" [value]="opt.item">
                                            {{opt.item}}
                                        </mat-option>
                                        <mat-option value="OTHER">OTHER</mat-option>
                                    </mat-select>
                                </mat-form-field>
                               
                            </div>

                            <div class="select-child"
                                *ngIf="(form_elem.controlType==='dropdown')&&(form_elem.selectType==='CHILD')">
                                <mat-form-field  appearance="outline">

                                    <mat-label>{{form_elem.placeholder}}</mat-label>
                                    <mat-select   [formControlName]="form_elem.key"     [id]="form_elem.key">
                                        <mat-option
                                            *ngFor="let opt of form_elem.options | filterList:form_elem.parentvalue  "
                                            [value]="opt.item"  >
                                            {{opt.item}}
                                        </mat-option>
                                        <mat-option value="OTHER">OTHER</mat-option>
                                    </mat-select>
                                    
                                </mat-form-field>
                           
                            </div>



                            <!-- textarea section  -->
                            <div class="select-child" *ngIf="(form_elem.controlType==='textarea')">
                                <mat-form-field  appearance="outline">
                                    <!-- <mat-label>{{form_elem.label}}</mat-label> -->
                                    <mat-label>{{form_elem.placeholder}}</mat-label>
                                    <textarea matInput formControlName="{{form_elem.key}}" [id]="form_elem.key"
                                        [value]="form_elem.value"></textarea>
                                </mat-form-field>
 
                            </div>

                        </div>
                    </div>
                </div>

                <!-- specification  end -->
                <!-- detail -->
                <div   *ngIf="isSubCatSelected">
                    <mat-form-field class="example-full-width" appearance="outline">
                           <mat-label>Product or Service detail</mat-label>
                           <textarea matInput formControlName="detail" ></textarea>
                         </mat-form-field>
                   </div>
                 
                                   <!-- remark text box -->
                <div   *ngIf="isSubCatSelected">
                    <mat-form-field  appearance="outline">
                           <mat-label i18n>remark</mat-label>
                           <textarea matInput formControlName="remark" ></textarea>
                           <mat-hint i18n>abave text is only visible to you </mat-hint>
                         </mat-form-field>
                   </div>

                <!-- //price -->
    
                <!-- file upload -->
                <div class="old_image_container" *ngIf="isSubCatSelected">


                    <div fxLayout="row wrap" fxLayoutGap="10px">
                        <div *ngFor="let imge of image_old ; let i=index ">
                            <div class="img-wraps">
                                <span class="closes" title="Delete" (click)="deleteOldImage(imge)">&times; </span>
                                <img [src]="getImageUrl(imge.name)" style="height: 100px; width:100px"
                                    class="img-responsive">


                            </div>
                        </div>
                    </div>


                </div>



                <div   *ngIf="isSubCatSelected" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
                    <button  class="adv-button" mat-raised-button color="primary" (click)="uploadMultipleFiles()"><mat-icon>add</mat-icon>{{chooseLabel}}</button>
                
                    <!-- <div fxLayout="row wrap" fxLayoutGap="10px" style="max-width: 500px;">
                        <div *ngFor="let  result of imgResultAfterCompress ; let i=index ">
                            <div class="img-wraps">
                                <span class="closes" title="Delete" (click)="removeFile($event,i)">&times; </span> -->
                                <!-- <img [src]="imageSrc[i]" style="height: 100px; width:100px" class="img-responsive"> -->
                                <!-- <img  [src]="result" alt="" style="height: 100px; width:100px" class="img-responsive" /> -->

                            <!-- </div>
                        </div>
                        
                    </div> -->

                </div>





                <!-- payment   options  -->

                <div class="post_payment" *ngIf="isSubCatSelected" formGroupName="post_payment">
                    <mat-radio-group formControlName="payment_option" [required]="payment_required()"
                        (change)="paymentOnChange()">
                        <div class="" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">

                            <div fxLayout="row" *ngIf="selectSubCat.contact" fxLayoutAlign="space-between"
                                fxLayoutGap="20px">

                                <mat-radio-button value="CONTACT">contact for Price</mat-radio-button>



                            </div>
                            <div fxLayout="row" *ngIf="selectSubCat.price" fxLayoutAlign="space-between"
                                fxLayoutGap="20px">
                                <mat-radio-button value="PRICE" style="padding-top:20px">Price</mat-radio-button>
                                <div class="" fxLayout="column" fxLayoutAlign="start start">

                                    <div class="price_curency" fxLayout="column">
                                        <mat-form-field class="example-full-width" style="max-width: 200px;" appearance="outline">
                                            <mat-label>Price</mat-label>
                                            <!-- pattern="[0-9]+" -->
                                            <input matInput placeholder="selling Price" pattern="[0-9]+"
                                                formControlName="price_amount" [readonly]="price_readonly"
                                                [required]="price_required">
                                        </mat-form-field>

                                        <!-- //currency -->
                                        <mat-form-field class="currency"  appearance="outline">
                                            <mat-label>Currency</mat-label>
                                            <mat-select formControlName="price_currency" [disabled]="price_readonly"
                                                [required]="price_required">
                                                <mat-option *ngFor="let price_curr of currency1" [value]="price_curr">
                                                    {{price_curr.shortName}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>


                                    <div class="">
                                        <mat-checkbox formControlName="negotiable" [disabled]="negotiable_readonly">
                                            Negotiable</mat-checkbox>
                                    </div>
                                </div>

                            </div>
                            <div fxLayout="row" *ngIf="selectSubCat.commision" fxLayoutAlign="space-between"
                                fxLayoutGap="20px">
                                <mat-radio-button value="COMMISSION"> Commition</mat-radio-button>

                            </div>
                            <div fxLayout="row" *ngIf="selectSubCat.range" fxLayoutAlign="space-between"
                                fxLayoutGap="20px">
                                <mat-radio-button value="RANGE" style="padding-top: 10px;">Range</mat-radio-button>
                                <div class="" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="20px">
                                    <mat-form-field  appearance="outline">
                                        <mat-label>Min</mat-label>
                                        <!-- pattern="[0-9]+" -->
                                        <input matInput placeholder="Minimum" pattern="[0-9]+"  formControlName="min"
                                            [readonly]="min_readonly" [required]="min_required">
                                        <!--  -->
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Max</mat-label>
                                        <!-- pattern="[0-9]+" -->
                                        <input matInput placeholder="Max" pattern="[0-9]+"  formControlName="max"
                                            [readonly]="max_readonly" [required]="max_required">

                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Currency</mat-label>
                                        <mat-select formControlName="range_currency" [disabled]="max_readonly"
                                            [required]="max_required">
                                            <mat-option *ngFor="let range_curr of currency1" [value]="range_curr">
                                                {{range_curr.shortName}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>


                        </div>
                    </mat-radio-group>
                </div>

                <!-- service  type and charge options  -->
                <mat-card *ngIf="isSubCatSelected" style="background-color: rgb(230, 224, 224);">
                    <h4> promote my ads </h4>
                    <div>
                        <mat-radio-group aria-label="Select package" formControlName="package_fee"
                            [required]="fee_required()">
                            <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
                                <!-- free package -->
                                <div *ngIf="selectSubCat.fee_free_enable" class="promote_item">
                                    <mat-radio-button value="1"></mat-radio-button>
                                    <p>Post Ads for 30 days <span> {{selectSubCat.fee_free}} SSP </span> </p>

                                </div>
                                <!--week  Package -->
                                <div *ngIf="selectSubCat.fee_week_enable" class="promote_item">
                                    <mat-radio-button value="2"> </mat-radio-button>
                                    <p> Post Ads for 7 days promote your ads - <span> {{selectSubCat.fee_week}} SSP
                                        </span> </p>


                                </div>
                                <!--month  Package -->
                                <div *ngIf="selectSubCat.fee_month_enable" class="promote_item">
                                    <mat-radio-button value="3"></mat-radio-button>
                                    <p> Post Ads for 30 days promote your ads <span> {{selectSubCat.fee_month}} SSP
                                        </span> </p>

                                </div>
                                <!--Year  Package -->
                                <div *ngIf="selectSubCat.fee_year_enable" class="promote_item">
                                    <mat-radio-button value="4"> </mat-radio-button>
                                    <p> Post Ads for 1 Year promote your Ads - <span> {{selectSubCat.fee_year}} SSP
                                        </span> </p>

                                </div>
                            </div>

                        </mat-radio-group>
                    </div>



                </mat-card>
                <!-- submit  -->
                <div class="submit-button" *ngIf="isSubCatSelected">
                    <p style="max-width: 380px;">By clicking Save Ads you accept the <a href="">Terms of Service</a> and <a href="">Privacy
                            Policy</a> and agree to receive newsletters and promo offers from us.</p>
   
                    <button class="adv-button" mat-button (click)="submitPost1()" [disabled]="!postForm.valid">Save Ads
                    </button>
                    <!-- [disabled]="!postForm.valid" -->
                    <p style="color: red;" *ngIf="!postForm.valid">Please complete the form!!!</p>


                </div>
            </div>
        </form>

    </div>

</section>