import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Post } from 'src/app/modules/Post';
import { User } from 'src/app/modules/User';
import { GlobalConstants } from 'src/app/utility/global-constants';
import { TokenStorageService } from '../tokenStorage/token-storage.service';
import { Message } from 'src/app/modules/Message';

const AUTH_API = GlobalConstants.serverUrl+'adv/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private http: HttpClient,
    private  tokenStorage: TokenStorageService) { }


searchPost(query,subcatagory:number,location:number) {

return this.http.get<Post[]>(`${AUTH_API}common/search/query/${query}/catagory/${subcatagory}/location/${location}`, httpOptions);
}
isUserInfoComplete() {
var id=this.tokenStorage.getCurrentUserId()
  return this.http.get<boolean>(`${AUTH_API}common/user/${id}/info/complete`, httpOptions);
  }
getUserViewById(selectedid: number) {
  return this.http.get<User>(`${AUTH_API}user/${selectedid}`, httpOptions);
}
test() {
  return this.http.get<any>("https://192.168.10.13:8443/adv/admin/currency" , httpOptions );
}
}
