import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/service/user/user.service';
 
import { Utility } from 'src/app/utility/Utility';
import { GlobalConstants } from 'src/app/utility/global-constants';
  


const SERVER_URL = GlobalConstants.serverUrl;


const pageMapping: Record<string, number> = {
  '/user/profile': 0,
  '/user/post': 1,
  '/user/payment': 2,
  '/user/privacy': 3,
};
 
@Component({
  selector: 'app-right-panel',
  templateUrl: './right-panel.component.html',
  styleUrl: './right-panel.component.css'
})
export class RightPanelComponent  implements  OnInit {
  userImage:string=""
  userName:String=""
  user:any

  page_url:string=""
  index:number=0

    constructor(public  userService:UserService,
              private route: Router) { }
  
    ngOnInit(): void {
      this.setUserInfo()

      this.page_url = this.route.routerState.snapshot.url ;

// Use the mapping object to set the index
this.index = pageMapping[this.page_url] || 0;

// If the URL is not in the mapping, navigate to the default URL
if (!pageMapping[this.page_url]) {
  this.route.navigate(['user/profile']);
}
    }
  
  
    async setUserInfo() {
      const s=await   this.userService.getCurrentUser().toPromise();
      if(s){
        this.userImage=Utility.getUserImageURL(s.image_name)
        this.userName=s.fullName
      }
       
  
      }


      getLink(inddex:number){
         return   pageMapping[this.index]
      }
  
  }
  











