 

 
 <mat-card   >
    <mat-card-title    > Email Notification</mat-card-title>
    <mat-card-content>
        <form (ngSubmit)="onSubmit()" [formGroup]="form">
          <div class="slides">
            <mat-slide-toggle   formControlName="subscription">Subscription</mat-slide-toggle>
            <mat-slide-toggle formControlName="messages">Messages</mat-slide-toggle>
            <mat-slide-toggle formControlName="info_about_your_Ads">Information About Your Ads</mat-slide-toggle>
            <mat-slide-toggle formControlName="hot_deals_recomendations">Hot Deals And Recomendation</mat-slide-toggle>
 
          </div>
         
            <div class="save-button">
              <button   class="adv-button" mat-raised-button color="primary" type="submit"      >Save Changes</button> 
            </div>
               
              
                
         </form>
      </mat-card-content>
      <mat-card-actions align="start">
         
          
      </mat-card-actions>
   
  </mat-card>
  
  
