<div class="wrapper">
    <div class="notice-bord  " *ngIf="this.post.post_status.status=='ERROR'">
        <p>{{this.post.post_status.rejectionReason}}</p>
        <p>And Apply agian!!!</p>
    </div>
    <div class="notice-bord  " *ngIf="this.post.post_status.status=='DISABLED'">
        <p>Post Disabled By Admin !!!</p>
    </div>
    <div class="notice-bord  " *ngIf="this.post.post_status.status=='PENDING'">
        <p>Post is waiting for Evaluation !!!</p>
    </div>
    <div class="detail-left ">


        <div class="main-image ">
            <img src="{{imagePath}} " alt=" ">
            <div class="price-tag ">{{displayPrice()}}</div>

            <div class="image-number ">
                <p>
                    <mat-icon>camera_alt</mat-icon>{{getIndex_lenth()}}</p>
            </div>
            <a class="prev2 " (click)="preveousSlides() ">
                <mat-icon>chevron_left</mat-icon>
            </a>
            <a class="next2 " (click)="nextSlides() ">
                <mat-icon>chevron_right</mat-icon>
            </a>

        </div>

        <div class="product-info ">

            <div class="post-date ">
                <p>
                    <mat-icon>watch_later</mat-icon> Posted {{post.post_status.apply_date|date}}</p>
                <p>
                    <mat-icon>location_on</mat-icon> {{post.salesLocation.state}}, {{post.salesLocation.city}} </p>
                <p>
                    <mat-icon>visibility</mat-icon> {{post.view}} views </p>
            </div>
            <div class="post-title ">
                <h1>{{post.description}}</h1>
            </div>
            <hr>


            <div class="post-specification ">
                <h1> Specifiation </h1>
                <div class="specification-table ">
                    <table *ngFor="let sp of post.specificationList ">
                        <tr>
                            <td class="specificaiton-name ">{{sp.specification.key}}</td>
                            <td> ........................................</td>
                            <td class="specification-value ">{{sp.value}}</td>
                        </tr>


                    </table>
                </div>
            </div>


        </div>

        <div class="post-description ">
            <h2> Detail </h2>
            <hr>
            <div [innerHtml]="post.detail"> </div>
        </div>


        <div class="decision">
            <h1> Diable or Enable Ads</h1>
            <hr>
            <form>
                <div class=" " fxLayout="column " fxLayoutAlign="start start " fxLayoutGap="10px ">

                    <div class=" ">
                        <mat-checkbox class="example-margin " name="post_disable " [(ngModel)]="post_disable ">Disable Post</mat-checkbox>
                    </div>




                    <div class="submit_button " fxLayoutAlign="space-between ">
                        <button class="adv-button" mat-button (click)="submitForm() ">Save</button>

                    </div>
                </div>
            </form>

        </div>
    </div>
    <div class="right-side ">
        <div class="seller-detail ">
            <div class="seller-img ">

                <img [src]="getUserImage(post.user.image_name) " width="100 " height="100 " alt=" ">
            </div>
            <div class="seller-info ">

                <p>Owner :<a [routerLink]="['/admin/userdetail']" [queryParams]="{uid: post.user.id}" >  {{post.user.fullName}}</a> </p>

                <p>Phone : <span style="color: #00a651; ">{{post.user.contact.phone}}</span> </p>

            </div>
        </div>

        <div class="reporter-detail" style="margin-top: 10px;">
            <div class="seller-detail ">
                <div class="seller-img ">
    
                    <img [src]="getUserImage(froudPost.user.image_name) " width="100 " height="100 " alt=" ">
                </div>
                <div class="seller-info ">
                     <p>Fraud Reporter : <br><a [routerLink]="['/admin/userdetail']" [queryParams]="{uid: froudPost.user.id}" >  {{froudPost.user.fullName}}</a> </p>
    
                    <p>Phone : <span style="color: #00a651; ">{{froudPost.user.contact.phone}}</span> </p>
    
                </div>
            </div>
        </div>
        <div class="fraud-detail" style="margin-top: 10px;">
           
                <div class="title">Reason for  Abuse report</div>
                <div class="reason">
                    <p>{{froudPost.reason}}</p>
                </div>
                <p></p>
               
        
        </div>
        <div class="conclussion" style="margin-top: 10px;">
           
            <div class="title">Autority Conclusion</div>
            <div class="form">
                <mat-form-field  appearance="outline">
                    <mat-label>Conclusion Note</mat-label>
                    <textarea matInput [(ngModel)]="froudPost.conculusion" name="fraud_conclusion"  [value]="froudPost.conculusion"></textarea>
                  </mat-form-field>
                  <mat-radio-group [(ngModel)]="froudPost.case_closed"   [ngModelOptions]="{standalone: true}"  >
                    <mat-radio-button  [value]="false" [checked]="froudPost.case_closed==false" >Case Not Closed</mat-radio-button>
                    <mat-radio-button [value]="true"  [checked]="froudPost.case_closed==true" >case Closed</mat-radio-button>
                  </mat-radio-group>
                  
                  <button mat-button (click)="caseClosed()">Save</button>
            </div>
            <p></p>
           
    
    </div>

    </div>


</div>
