<div class="personal-option">
     <ul>
        <!-- <li class="active"><a href="#"><i class="fas fa-tachometer-alt"></i>Dashboard </a></li> -->
        <li [ngClass]="{active: index==0}"><a routerLink="/admin/setting/user"><i class="fas fa-user-cog"></i>User Setting </a>
        </li>
        <li [ngClass]="{active: index==1}"><a routerLink="/admin/setting/sales_location"><i class="fas fa-map-marker-alt"></i>Sales Location </a></li>

        <li [ngClass]="{active: index==2}"><a routerLink="/admin/setting/currency"><i class="fas fa-wallet"></i> Currency</a></li>
        <li [ngClass]="{active: index==3}"><a routerLink="/admin/setting/sent_mail"><i class="fas fa-mail-bulk"></i> Sent Mail</a></li>
        <li [ngClass]="{active: index==4}"><a routerLink="/admin/setting/system_property"><i class="fas fa-server"></i> System Property</a></li>


    </ul>
</div>

 