<div class="deposit-form">
    <div id="watermark" *ngIf="data.receipt_void">
        <P>VOID RECEIPT</P></div>
    <div class="title">Deposite Recipt Detail</div>
  
    <div class="reciptNumberDate">
        <div class="recipt-number"><p>Receipt Number :  {{data.receipt_No}}</p></div>
        <div class="recipt-date"> Date <span>{{data.createdDate | date}}</span></div>
    </div>
    <div class="customerName">
        <p>Customer Name : <span class="name">{{data.user.username}}</span></p>
    </div>
    <div class="reason"> <p>Deposite Reason: <span class="reason">{{data.description}}</span></p></div>
    <div class="amount"> <p>Amount : <span class="amount"> {{data.amount | number}} SSP</span></p></div>

    <div class="casherName"> <p>Casher Name: <span class="name">AHMED MOHAMMED AHMED</span></p></div>
<hr>
 
<div class="void-detail" *ngIf="data.receipt_void">
    <div class="reason"> <p>Void By: <span class="reason">{{data.user_void.username}}</span></p></div>
    <div class="amount"> <p>Void Date : <span> {{data.void_date | date}} </span></p></div>

</div>


</div>