<!-- <h1>Error Page </h1>  -->

 
 <div class="wrapper">
   <mat-card>
       <div class="title">
         <h1 *ngIf="code==='404'" >404 Error</h1>
         <h1 *ngIf="code==='401'">401 Error</h1>
 
       </div>
       <div class="content">
         <h2 *ngIf="code==='404'"  i18n> page Not Found </h2>
         <h2 *ngIf="code==='401'"   i18n> AnUthorized Access </h2>
       </div>
       <div class="card-button">
         <a href="/" mat-button  i18n>Back To Home</a>
       </div>
   </mat-card>
 </div>
 