<div class="wrapper">

  <div class="detail-section">
    <div class="detail-section-title">Personal Information</div>
    <div class="pi-body">
      <div class="image-section">
        <img src="{{getImageUrl()}}" width="150" height="150">

      </div>
      <div class="pi-information">
        <p class="name">Full Name : {{user.fullName}}</p>
        <P class="email">Email : {{user.email}}</P>
        <P class="phone">Phone : {{user.contact.phone}} </P>

      </div>
    </div>
  </div>

  <div class="detail-section">
    <div class="detail-section-title">Post Information </div>
    <div class="post-info-body">
      <div class="post-info-status">

        <div class="">
          Post Status :
          <button mat-button style="color:#00a651">ACTIVE ({{getCount('ACTIVE')}})</button>
          <button mat-button style="color:rgb(138, 132, 132);">PENDING ({{getCount('PENDING')}})</button>
          <button mat-button style="color: rgb(228, 74, 74);">ERROR ({{getCount('ERROR')}})</button>
          <button mat-button style="color:rgb(56, 74, 153);">DISABLED ({{getCount('DISABLED')}})</button>
          <button mat-button style="color: rgb(34, 31, 31);">ARCHIVE ({{getCount('ARCHIVE')}})</button>
          <button mat-button style="color: rgb(104, 91, 91);">ALL ({{getCount('ALL')}})</button>
        </div>
      </div>

    </div>
  </div>









</div>