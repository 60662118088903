import { Pipe, PipeTransform } from '@angular/core';
 

@Pipe({
  name: 'customDatePipe' 
})
export class CustomDatePipe implements PipeTransform {
  transform(value: any): string | null {
    if (!value) return null;

    return this.formatDate(this.convertDate(value));
  }

  private formatDate(date: Date): string {
    const day = this.padZero(date.getDate());
    const month = this.padZero(date.getMonth() + 1); // Months are zero-based
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  private padZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  convertDate(v:any):Date{
    return new Date(v[0], v[1] - 1, v[2], v[3], v[4], v[5]);
  }
}