import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/service/login/Authentication.service';
import { NgForm } from '@angular/forms';
import { User } from 'src/app/modules/User';
import { SignupRequest } from 'src/app/modules/forms/SignupRequest';
 

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  form: any = {};
  isSuccessful = false;
  isSignUpFailed = false;
  errorMessage = '';
  


  enableSubmit:boolean=true
  
  constructor(private authService:AuthenticationService,
      ) { }

  ngOnInit(): void {
  }


  onSubmit(form:NgForm) {
 this.enableSubmit=false
    if(form.value.password.match(form.value.password2) == null) {
       this.errorMessage="Password MisMatch !!!"
       this.isSignUpFailed=true;
       
    }else {
             let email=form.value.email.replace(/\s/g,"").toLowerCase();
 
             var signUpRequest=new SignupRequest(form.value.fullName,
              email,"Local","",form.value.password,form.value.phone
             )
              console.log("register data ==> "+JSON.stringify(signUpRequest));
              
              
      this.authService.register(signUpRequest).subscribe(
        data => {
          console.log(data);
          this.isSuccessful = true;
          this.isSignUpFailed = false;
             
        },
        err => {
          this.errorMessage = err.error.message;
          this.isSignUpFailed = true;
             
        }
      );
    }
    }

    removeSpace_Cap(value:String){
      var  s=value.replace
    }
}

 