 <mat-card>
     <mat-card-header>
         <mat-card-title>Report Abuse Form</mat-card-title>
         
     </mat-card-header>
     
     <mat-card-content>
      <div class="dialog-body">
      
        <mat-form-field  appearance="outline"  >
            <mat-label>Reason please..</mat-label>
            <textarea matInput  [(ngModel)]="reason"  required ></textarea>
          </mat-form-field>
       </div>
     </mat-card-content>
     <mat-card-actions  >
      <div class="dialog-button">
        <button class="btn-cancel" mat-button  (click)="onDismiss()">Cancel</button>
        <button class="btn-save"  color="primary" mat-button  [disabled]="!reason"   (click)="onConfirm()">Confirm</button>
      </div>
          </mat-card-actions>
       </mat-card>
 
  
 