<form action=""      #f="ngForm"   >
    <section fxLayout="column" fxLayoutAlign="center center" > 
   
   
    <h1 mat-dialog-title> New/Edit System Info  </h1>
   <div mat-dialog-content fxLayout="column"  style="width: 100%;" >
     
     <mat-form-field style="display: none;" appearance="outline">
       <input type="text" matInput placeholder="Id"   name="id" disabled [(ngModel)]="data.id" >
     </mat-form-field>
        <mat-form-field  appearance="outline">
        <mat-label>Name</mat-label>
        <mat-select [(ngModel)]="data.name" placeholder="name" required name="name" >
          <mat-option *ngFor="let opt of list" [value]="opt">
            {{opt}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    
     <mat-form-field appearance="outline">
       <input type="text" matInput placeholder="value" required name="value"   [(ngModel)]="data.value" >
   
     </mat-form-field>
      </div>
   <div mat-dialog-actions>
     <button mat-button (click)="onNoClick()">Cancel</button>
      <button   [disabled]="f.invalid" mat-button [mat-dialog-close]="data" cdkFocusInitial>Save</button>
   </div>
   
   </section>
   </form>
