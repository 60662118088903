<section fxLayout="row" fxLayoutAlign="start center" style="margin: 20px 20px 20px 20px;">

    <mat-card fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="0px"  style="width: 100%;padding-top: 5px;" class="mat-elevation-z8"  >  
     
    <div class=""  fxLayout="row" fxLayoutAlign="center end" style="width: 100%;" >
      <mat-card-header   >
            <mat-card-title>Abuse Reports List</mat-card-title>
          </mat-card-header>
      </div>
          <mat-card-content>
            <div fxLayoutAlign="space-between">
                <mat-form-field appearance="outline" >
                    <mat-label>abuse Notification Case</mat-label>
                    <mat-select  (selectionChange)="onStatusChange($event)" >
                      <mat-option selected value="all">All</mat-option> 
                      <mat-option value="false">Not Closed</mat-option>
                      <mat-option value="true">Closed</mat-option>
                      
                       
                    </mat-select>
                  </mat-form-field>   
                
              </div>
              
              <table  mat-table [dataSource]="dataSource" matSort  fxFlexFill >
   
                <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> id</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.id }}</mat-cell>
                  </ng-container>
                <ng-container matColumnDef="abuseReporter">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Abuse Reporter</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.user.fullName }}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="case_status">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Case Status</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.case_closed}}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="date">
                      <mat-header-cell *matHeaderCellDef mat-sort-header> Date</mat-header-cell>
                      <mat-cell *matCellDef="let element">{{ element.createdDate | date}}</mat-cell>
                    </ng-container>
                  <ng-container matColumnDef="detail">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Detail</mat-header-cell>
                    <mat-cell *matCellDef="let element" > 
                         <a [routerLink]="['/admin/post/frauddisplay']" [queryParams]="{fid: element.id}" >Detail</a>
                    
                    </mat-cell>   
                    <!-- routerLink="/admin/subcatagory" [queryParams]="{cid:element.id}"  -->
                 
                  </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
              </table>
              <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 25, 50, 100]">
              </mat-paginator>
          </mat-card-content>
          
         
          <mat-card-footer>
             </mat-card-footer>
      </mat-card>
    </section>

