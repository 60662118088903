import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})
export class SettingComponent implements OnInit {
   

    page_url:String=""
    index:number=0
    constructor(private route: Router){
  
    }
    title = 'angular-example';
  
    ngOnInit(){
      this.page_url=this.route.routerState.snapshot.url
      
    if(this.page_url==="/admin/setting/user") {
      this.index=0;
       }else if(this.page_url==="/admin/setting/sales_location") {
         this.index=1;
       }else if(this.page_url==="/admin/setting/currency") {
         this.index=2;
       }
       else if(this.page_url==="/admin/setting/sent_mail") {
         this.index=3;
       }
       else if(this.page_url==="/admin/setting/system_property") {
        this.index=4;
      }
       else  {
         this.index=0;
         this.route.navigate(["admin/setting/user"])
       }
  }
  getDescription(){
      
    if(this.page_url==="/admin/setting/user") {
      return "Users Setting "
       }else if(this.page_url==="/admin/setting/sales_location") {
        return "Sales Location "
       }else if(this.page_url==="/admin/setting/currency") {
        return "Currency Setting "
       }
       else if(this.page_url==="/admin/setting/system_property") {
        return "System Property Setting "
       }
       else if(this.page_url==="/admin/setting/sent_mail") {
        return "Sent Email Setting "
       }
       else  {
         this.index=0;
         this.route.navigate(["admin/setting/user"])
       }
  }
  }
  