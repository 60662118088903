<section class="catagoryList" >
    <div class="wrapper">
       <div class="catagory-title">
        <h1 i18n>Browse By Catagories</h1>
      
        <!-- <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> -->
     <hr>
       </div>
        <div class="items  "   > 
          
          <div  *ngFor="let cat of  catagories |disableOption " >
            <div class="item"  *ngIf="cat.disable==false"  style="min-height: 250px;"> 
          <a routerLink="/subcat" [queryParams]="{cid:cat.id}" >
            <!-- <img [src]="getImageUrl(cat.img)" width="100" height="100" > -->
          </a>
            <h3><a routerLink="/subcat" [queryParams]="{cid:cat.id}">{{cat.name}}</a></h3>
            <div class="sub-item"  >
              <div class="" *ngFor="let subcat of  cat.productSubCatagory  |disableOption ; let i=index">
              <p class="sub-item-list" *ngIf="i<4"><i class="fas fa-angle-double-right"></i><a routerLink="/subcat" [queryParams]="{cid:cat.id, scid:subcat.id}">{{subcat.name}}</a></p>
            </div>
            <div class="sub-item-footer" *ngIf="cat.productSubCatagory.length>4">
             <p><a  mat-button routerLink="/subcat" [queryParams]="{cid:cat.id}" i18n>.. More</a> </p> 
            
            </div>
          </div>
        </div>
          </div>
          </div>
          <div class="accordion">
            <mat-accordion >
              <mat-expansion-panel  class="item" *ngFor="let cat of  catagories  |disableOption"  >
                <mat-expansion-panel-header *ngIf="cat.disable==false" style="background-color: beige;">
                  <mat-panel-title>
                    <h3><a >{{cat.name}}</a></h3>
                  </mat-panel-title>
                 </mat-expansion-panel-header>
                 <div class="sub-item"  >
                  <div class="" *ngFor="let subcat of  cat.productSubCatagory  |disableOption ; let i=index ">
                  <p class="sub-item-list" *ngIf="i<4"><i class="fas fa-angle-double-right"></i><a routerLink="/subcat" [queryParams]="{cid:cat.id, scid:subcat.id}">{{subcat.name}}</a></p>
                </div>
                <div class="sub-item-footer" *ngIf="cat.productSubCatagory.length>4">
                  <p><a  mat-button routerLink="/subcat" [queryParams]="{cid:cat.id}" i18n>.. More</a> </p> 
                 
                 </div>
              </div>
              </mat-expansion-panel>
               
              
            </mat-accordion>
          </div>
        </div>
  </section>
  
  
  
   

