 
<div class="personal-info">
    <img src="{{userImage}}" alt="">

    <div class="personal-name">{{userName}}</div>



</div>

<div class="personal-option">
    <ul>
        <!-- <li class="active"><a href="#"><i class="fas fa-tachometer-alt"></i>Dashboard </a></li> -->
        <li [ngClass]="{active: index==0}"><a routerLink="/user/profile"> <i class="fas fa-user-cog"></i><span i18n>Profile Setting</span></a>
         <li [ngClass]="{active: index==1}"  ><a routerLink="/user/post"><i class="fas fa-list-alt"></i><span i18n>My Ads</span> </a></li>
         <li [ngClass]="{active: index==2}"  ><a routerLink="/user/payment"><i class="fas fa-wallet"></i> <span i18n>Payment</span></a></li>
         <li [ngClass]="{active: index==3}"  ><a routerLink="/user/privacy"><i class="fas fa-cog"></i> <span i18n>Setting</span></a></li>
        
         </ul>
</div>
