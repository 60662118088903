<mat-card fxLayout="column"  fxLayoutGap="10px" class="user-card" class="mat-elevation-z8"  >  
        
        
        <mat-card-content   >
          <div  fxLayout="row" fxLayoutAlign="space-between"  >
              <mat-form-field  appearance="outline" >
                <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Filter" />
              </mat-form-field>
              <button mat-button (click)="newSalesLocation()" ><mat-icon class="mat-18">create_new_folder</mat-icon> New Sales Location</button>
     
            </div>
            
            <table  mat-table [dataSource]="dataSource" matSort style="width: 100%;">
              <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Id</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.id }}</mat-cell>
              </ng-container>
            
              <ng-container matColumnDef="city" style="width: 100px;">
                <mat-header-cell *matHeaderCellDef mat-sort-header>City</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.city}}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="state">
                <mat-header-cell *matHeaderCellDef mat-sort-header>State</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.state}}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="country">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Country</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.country}}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="Edit">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Edit</mat-header-cell>
                  <mat-cell *matCellDef="let element"><a    mat-button  (click)="editSalesLocation($event, element.id)" ><mat-icon class="mat-16">edit</mat-icon></a>  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Delete">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Delete</mat-header-cell>
                  <mat-cell *matCellDef="let element"><a    mat-button  (click)="deleteSalesLocation($event, element.id)" ><mat-icon class="mat-16">delete</mat-icon></a>  </mat-cell>
                </ng-container>
              
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </table>
            <mat-paginator [pageSize]="10" [pageSizeOptions]="[1, 5, 10, 20]">
            </mat-paginator>
        </mat-card-content>
       
        
    </mat-card>
   

