 
     <section>
      <div class="container1"  *ngIf="isSuccessfull">
        <p  i18n>Password Reset Link was sent to your Email !!!!</p>
      </div>
        <div class="container"  *ngIf="!isSuccessfull">
            <form class="form" role="form"  (ngSubmit)="f.valid&&onSubmit()" #f="ngForm">
            <div class="header">
                <h1>Forgot Password</h1>
            </div>
            <div class="instruction  ">
                <p  i18n>Please Enter your email and we will send to you a reset link. </p>
            </div>
            <div class="input ">
                <mat-form-field  appearance="outline" >
                    <mat-label  i18n>Enter your email</mat-label>
                    <input matInput  [(ngModel)]="email" email required name=email>
    
                </mat-form-field>
    
            </div>
            <div class="action">
                <button class="adv-button" color="primary" type="submit" mat-raised-button    [disabled]="f.invalid " mat-raised-button>Submit</button>
            </div>
        </form>
     </div>
 
    </section>