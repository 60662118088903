<section  >
    <mat-card  class="card_status">
      <div class="search-text">
        <mat-form-field  class="search-text-field" appearance="outline">
          <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Filter" />
      </mat-form-field>
      </div>
      <div class="status-button">
        <button mat-button  (click)="getSourceByStatus('ACTIVE')" style="color:#00a651" >ACTIVE ({{getCount('ACTIVE')}})</button>
        <button mat-button (click)="getSourceByStatus('PENDING')" style="color:rgb(138, 132, 132);" >PENDING ({{getCount('PENDING')}})</button>
        <button mat-button (click)="getSourceByStatus('ERROR')" style="color: rgb(228, 74, 74);" >ERROR ({{getCount('ERROR')}})</button>
        <button mat-button (click)="getSourceByStatus('DISABLED')" style="color:rgb(56, 74, 153);" >DISABLED ({{getCount('DISABLED')}})</button>
        <button mat-button  (click)="getSourceByStatus('ARCHIVE')" style="color: rgb(34, 31, 31);" >ARCHIVE ({{getCount('ARCHIVE')}})</button>
        <button mat-button (click)="getSourcedata()" style="color: rgb(104, 91, 91);" >ALL ({{getCount('ALL')}})</button>
       
      </div>
    
      </mat-card>

      <div class="">
        <div class="items  ">

            <div class="product-card  " class="product-card  " *ngFor="let p of posts  | async  " >
              <div class="product-image">
                 <a routerLink="/user/postdisplay" [queryParams]="{pid:p.id}" target="_blank" > <img [src]="getImageUrl(p)"     alt=""></a>
                           
              </div>
              <div class="product-data">
              <div class="price_status"><div class="price-tag">{{displayPrice(p)}}</div> <span class="post_status">
                <p class="status" *ngIf="getStatus(p)=='PENDING'"  style="background-color:rgb(138, 132, 132);">PENDING</p>
                    <p class="status" *ngIf="getStatus(p)=='ACTIVE'" style="background-color:#00a651">ACTIVE</p>
                    <p class="status" *ngIf="getStatus(p)=='ERROR'" style="background-color: rgb(228, 74, 74);">ERROR</p>
                    <p class="status" *ngIf="getStatus(p)=='DISABLED'" style="background-color:rgb(241, 238, 238);">DISABLED</p>
                     <p class="status" *ngIf="getStatus(p)=='ARCHIVE'" style="background-color: rgb(34, 31, 31);color: white;">ARCHIVE</p>
              </span></div>    
               <div class="post-title"><a   routerLink="/user/postdisplay" [queryParams]="{pid:p.id}" target="_blank" > <p>{{p.description}} </p></a></div>
               
               <div class="post_action">
                <a routerLink="/user/postdisplay" [queryParams]="{pid:p.id}" target="_blank" class="display"   matTooltipPosition="above" matTooltip="display"><i class="  fas  fa-eye"  ></i></a>
                <button mat-button (click)="onArchive(p.id)" *ngIf="showArchiveButton(p)"  class="archive"  matTooltipPosition="above"  matTooltip="Archive"><i class="fas  fa-archive"></i></button>
                <a  *ngIf="showEditButton(p)"  routerLink="/user/editpost" [queryParams]="{pid:p.id}" class="edit"  matTooltipPosition="above"  matTooltip="Edit"><i class="fas fa-pencil-alt"></i></a>
                <button mat-button *ngIf="showDeleteButton(p)"  (click)="confirmDelete(p.id)" class="delete"   matTooltipPosition="above" matTooltip="delete"><i class="fas fa-trash-alt"></i></button>
             <!-- <button mat-button (click)="confirmDelete()">Action</button> -->
                </div>
                         
              </div>
          </div> 
          </div>
          <div class="paginator">
            <mat-paginator [length]="100"
            [pageSize]="25"
            [pageSizeOptions]="[10, 25, 50]"
            aria-label="Select page">
</mat-paginator>
          </div>
      </div>
 
    </section>
