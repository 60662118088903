<mat-card fxLayout="column" fxLayoutGap="10px" class="user-card" class="mat-elevation-z8">


  <mat-card-content>
    <div fxLayout="row">
      <mat-form-field appearance="outline">
        <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Filter" />
      </mat-form-field>
    </div>
    <div class="example-table-container">
      <table mat-table [dataSource]="dataSource" matSort style="width: 100%;">
        <ng-container matColumnDef="Id">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Id</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.id }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="Name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>

          <mat-cell *matCellDef="let element"><a [routerLink]="['/admin/userdetail']" [queryParams]="{uid: element.id}"
              mat-button target="_blank">{{ element.fullName }}</a>  
              <i *ngIf="element.disabledbyAdmin" class=" fas fa-user-slash"
              style=" color:red ; padding-left:20px;"></i> </mat-cell>
        </ng-container>active
        <ng-container matColumnDef="Email">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.email }} </mat-cell>
        </ng-container>
        
        <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Action</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="action_btn">
              <button mat-icon-button [matMenuTriggerFor]="action"  >
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #action="matMenu">
                <button mat-menu-item  (click)="detail($event, element.id)">
                   <mat-icon
                    class="mat-16">edit</mat-icon> Edit User
                </button>
                <button mat-menu-item  (click)="deleteUser($event, element.id)">
                  <mat-icon class="mat-16">delete</mat-icon> Delete User
               </button>
                <button mat-menu-item  *ngIf="!isUserAccountActive(element.id)"  (click)="activateUserAccountManually(element.id)">
                  <mat-icon class="mat-16  ">Not Acctivated</mat-icon>
                </button>
              </mat-menu>

            </div>


          </mat-cell>
        </ng-container>


        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </table>
    </div>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[1, 5, 10, 20]">
    </mat-paginator>
  </mat-card-content>
  <mat-card-actions align="start">


  </mat-card-actions>


</mat-card>