import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SystemInfo } from 'src/app/modules/system-info';
import { SystemInfoService } from 'src/app/service/system-info-service.service';
import { SystemInfoFormComponent } from './system-info-form/system-info-form.component';
import { ConfirmDialogModel } from 'src/app/utility/ConfirmDialogModel';
import { ConfirmationDialogComponent } from 'src/app/utility/confirmation-dialog/confirmation-dialog.component';
 

@Component({
  selector: 'app-system-info-list',
  templateUrl: './system-info-list.component.html',
  styleUrls: ['./system-info-list.component.css']
})
export class SystemInfoListComponent implements OnInit {
  displayedColumns = ['id', 'name',  'value',  'action'];
  dataSource = new MatTableDataSource<SystemInfo>();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
 
 

  constructor(
    private systemInfoService: SystemInfoService,
    public dialog: MatDialog,
    private snackbar:MatSnackBar
  ) {}
  ngOnInit(): void {
    this.getSourcedata();
  }
  getSourcedata(){
    this.systemInfoService.getallSystemInfo().subscribe(
      data=> {
         
        this.dataSource.data =data
        console.log("system info get Data ==>"+JSON.stringify(data));
        
      },
      error=>{
        this.snackbar.open("Retrieve Data Fail","error")._dismissAfter(2000)
      }
    );
    
  }
  
    
 
 

  saveSystemInfo(result:SystemInfo){
    this.systemInfoService.saveSystemInfo(result).subscribe(
      data=> { 
          this.snackbar.open("SystemInfo Saved Successfully !","Message")._dismissAfter(2000)
          this.getSourcedata()
      },
      error=>{   this.snackbar.open("SystemInfo save failed !!","error")._dismissAfter(2000)
         
      }
    );
     
  }
   
 

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  newSystemInfo(){
   
    var catagory=new  SystemInfo(-1,"","")
    this.openDialog(catagory);
  }
  doFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  deleteSystemInfo(event, id){
     this.confirmDelete(id);
    
   }
   
  editSystemInfo(event, id) {
 
    var temp=this.dataSource.data.find(x=>x.id===id)  
    this.openDialog(temp);
    }

  openDialog( catagory:SystemInfo): void {
    
    const dialogRef = this.dialog.open(SystemInfoFormComponent, {
      width: '80%',
      data: catagory,
    });

    dialogRef.afterClosed().subscribe((result) => {
     
      
      if(result != undefined){
       
            this.saveSystemInfo(result);
      }
    });
  }
  confirmDelete(id:number): void {
    this.deletePostDialog("Confirmation","Do you really want to Delete System Property ?",id);
   }
   deletePostDialog(title,message,id){
     
       const dialogData = new ConfirmDialogModel(title, message);
   
     const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
       maxWidth: "400px",
       data: dialogData
     });
   
     dialogRef.afterClosed().subscribe(dialogResult => {
        dialogResult;

        if( dialogResult){
        this.systemInfoService.deleteSystemInfo(id).subscribe(
          data=> { 
              this.snackbar.open("SystemInfo Deleted Successfully !!!","Message")._dismissAfter(2000)
              this.getSourcedata()
          },
          error=>{   this.snackbar.open("SystemInfo Delete failed !!","error")._dismissAfter(2000)
             
          }
        );
       }
     });
   
   }
   // end to delete dialog 

 
  openSnackBar( message,type) {
    this.snackbar.open(message, type, {
      duration: 2000,
    });
  }
}
