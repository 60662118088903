
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Address } from 'src/app/modules/Address';
import { Post_status } from 'src/app/modules/Post_status';
import { User } from 'src/app/modules/User';
import { AddressService } from 'src/app/service/address/address.service';
import { UserPostService } from 'src/app/service/post/User_post.service';
import { UserService } from 'src/app/service/user/user.service';
import { GlobalConstants } from 'src/app/utility/global-constants';
import { UserSendEmailDialogComponent } from '../setting/users/user-send-email-dialog/user-send-email-dialog.component';
import { MatDialog } from '@angular/material/dialog';
const AUTH_PATH = GlobalConstants.serverUrl

@Component({
  selector: 'app-admin-user-detail',
  templateUrl: './admin-user-detail.component.html',
  styleUrls: ['./admin-user-detail.component.css']
})
export class AdminUserDetailComponent implements OnInit {
  user: User
  userAddress: Address
  uid: number
  post_status_list
  status: Post_status


  constructor(private userService: UserService,
    private route: ActivatedRoute,
    private routr: Router,
    private postService: UserPostService,
    private addresService: AddressService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe(
      r => {
        this.uid = r.uid
        console.log("query param => " + JSON.stringify(r))
        if (r.uid === undefined) this.routr.navigate(['/error'])
        this.getSource()
      },
      e => {
        this.routr.navigate(['/error'])
      }
    )


  }
  async getSource() {
    // const p=await this.route.queryParams.toPromise()
    // if (p != undefined) {
    //   this.uid=p.uid
  //   if(p=== null)  this.routr.navigate(['/error'])

    // }else   this.routr.navigate(['/error'])

    const s = await this.userService.getPostCatagoryByStatusOfUserNotCurrent(this.uid).toPromise()
    if (s) this.post_status_list = s;

    const u = await this.userService.getUserById(this.uid).toPromise()

    if (u != undefined) {
      this.user = u
      if (u === null) this.routr.navigate(['/error'])

    } else this.routr.navigate(['/error'])
    // get user Address  
    const ad = await this.addresService.getAddressByUserId(this.user.id).toPromise()
    if (ad) {
      this.userAddress = ad;
    } else {
      this.userAddress = null;
    }
  }
  getRoles() {
    return JSON.stringify(this.user.roles)
  }

  getImageUrl() {
    if (this.user.image_name === undefined) {
      console.log("image path ==> " + "assets/img/default_user.png")
      return "assets/img/default_user.png"
    } else {
      console.log("image path ==> " + AUTH_PATH + "adv/img/" + this.user.image_name)
      return AUTH_PATH + "adv/img/" + this.user.image_name
    }
  }


  getCount(status: string) {
    var n
    if (status === 'ACTIVE') {
      n = this.post_status_list.find(x => x.status === Post_status.active)
      if (n != undefined) return n.qty
      return 0

    } else if (status === 'PENDING') {
      n = this.post_status_list.find(x => x.status === Post_status.pending)
      if (n != undefined) return n.qty
      return 0
    } else if (status === 'DISABLED') {
      n = this.post_status_list.find(x => x.status === Post_status.disabled)
      if (n != undefined) return n.qty
      return 0
    } else if (status === 'ARCHIVE') {
      n = this.post_status_list.find(x => x.status === Post_status.archive)
      if (n != undefined) return n.qty
      return 0
    } else if (status === 'ERROR') {
      n = this.post_status_list.find(x => x.status === Post_status.error)
      if (n != undefined) return n.qty
      return 0
    } else if (status === 'ALL') {
      var sum = 0
      this.post_status_list.forEach(a => sum += a.qty);
      return sum

    }
  }
  sendEmailToUser() {
    const dialogRef = this.dialog.open(UserSendEmailDialogComponent, {
      width: "80%",
      data: this.uid
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      dialogResult;

    });

  }

  messageDialog(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }
}

