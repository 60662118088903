 
import { User } from './User';
enum ESentEmailToUserStatus {
  PENDING,
  SENT,
  FAIL
}

export class SentEmailToUser {

  constructor(

 
    public title: String,
    public message:String,
    public id?: number,
    public status?: ESentEmailToUserStatus ,
    public sentDate?: Date,
    public createdDate?: Date,
    public user?: User
  ) {
  }

}