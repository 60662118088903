
<mat-card>
    <h1>&nbsp;ክትረኽቡና ምስ እትደልዩ</h1>
    <hr />
    <div class="content">
       
      <h3> <i class="fas fa-phone"></i>&nbsp;&nbsp;&nbsp; : <a href="tel:+211981400409">+211981400409 </a></h3>
     <h3><i class="far fa-envelope"></i> &nbsp; ፥ <a href="mailto:meazn.ss@gmail.com">meazn.ss&#64;gmail.com</a></h3>
        <h3> <i class="fab fa-whatsapp"></i>&nbsp;&nbsp;&nbsp; : <a href="https://wa.me/message/3ME2SYIJCIYSE1">+211981400409 </a></h3>
     
      </div>
</mat-card>