<div class="carousel-container">
    
  <div class="carousel-slide" *ngFor="let image of images; let i = index"
       [class.active]="i === activeSlide">
    <img [src]="image.url" alt="Slide image" class="image">
    <button class="image-button" (click)="onButtonClick(image.actionUrl)"><p i18n>Learn More</p></button>
  </div>
  <div class="text"> 
    <p style="color: #00a651; background-color: #cfd6d2;">{{getText()}}</p>
  </div>
  <button class="previous_btn"  mat-icon-button (click)="previous()">
      <mat-icon>arrow_back</mat-icon>
    </button>
  <button  class="next_btn"  mat-icon-button (click)="next()">
    <mat-icon>arrow_forward</mat-icon>
  </button>
  
</div>
 
