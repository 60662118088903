<ng-image-fullscreen-view
    [images]="getPostImageObect()"
    [imageIndex]="selectedImageIndex"
    [show]="showFlag"
    (close)="closeEventHandler()"></ng-image-fullscreen-view>
<div class="wrapper">
    <div class="notice-bord  " *ngIf="this.post.post_status.status=='ERROR'">
        <p>{{this.post.post_status.rejectionReason}}</p>
        <p>And Apply agian!!!</p>
    </div>
    <div class="notice-bord  " *ngIf="this.post.post_status.status=='DISABLED'">
        <p>Post Disabled By Admin !!!</p>
    </div>
    <div class="detail-left ">

        <div class="main-image">
            <img src="{{imagePath}}" (click)="showImageInFullScreen()" >
            
   
    <div class="image-number">
        <p> <mat-icon>camera_alt</mat-icon>{{getIndex_lenth()}}</p></div>
            <a class="prev2" (click)="preveousSlides()"><mat-icon>chevron_left</mat-icon></a>
            <a class="next2" (click)="nextSlides()"><mat-icon>chevron_right</mat-icon> </a>

        </div>

        <div class="product-info ">
            
            <div class="post-date">
                <p> <i class="far fa-clock"></i> {{post.post_status.apply_date| dateAgo}}</p>
                <p><i class="fas fa-map-marker-alt"></i> {{post.salesLocation.state}}, {{post.salesLocation.city}} </p> 
                <p><i class="far fa-eye"></i> {{post.view}}  </p> 
            </div>
            <div class="price-tag">{{displayPrice()}}<span *ngIf="displayNegotiable()" class="negotiable-tag">(negotiable)</span></div>
            <div class="post-title">
                <h1>{{post.description}}</h1> 
            </div>
          

</div>

            <div class="post-specification">
                <h1 i18n>   Specifiation </h1>
                <hr>
                 <div class="specification-table">
                    <table   *ngFor="let sp of post.specificationList"     >
                        <tr  class="table-row">
                          <td class="specificaiton-name" >{{sp.specification.key}}</td>
                        
                          <td class="specification-value"  >{{sp.value}}</td>
                        </tr>
                     </table>
                 </div>
            </div>


            <div class="seller-loction">
                <h1 i18n> Detail </h1>
                <hr>
                <div [innerHtml]="post.detail"> </div>
              </div>

              <div class="seller-loction">
                <h1 i18n> Remark </h1>
                <hr>
                <div [innerHtml]="post.remark"> </div>
              </div>
          
              <div class="seller-loction">
            <h1> Extra Ads Info </h1>
            <hr>
            <div ><b> Ads Status : </b><i>{{post.post_status.status}}</i></div>
            <div ><b> Apply Date : </b><i>{{post.post_status.apply_date | customDatePipe}}</i></div>
            <div *ngIf="post.post_status.status==='ACTIVE'" ><b> Expire Date : </b><i>{{post.post_status.expire_date | customDatePipe}}</i></div>
        </div>
    </div>
    <div class="right-side ">
        <div class="seller-detail ">
            
            <div class="seller-img ">

                <img src="{{getUserImageUrl(post.user.image_name)}} " width="100 " height="100 " alt=" ">
            </div>
            <div class="seller-info ">

                <p>Seller : <a class="link " href="# "> {{post.user.fullName}}</a> </p>

                <p>Phone : <span style="color: #00a651; ">{{post.user.contact.phone}}</span> </p>

            </div>
        </div>



    </div>


</div>