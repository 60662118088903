import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { User } from 'src/app/modules/User';
import { Message } from 'src/app/modules/Message';
import {PostCatagoryByStatus} from 'src/app/modules/PostCatagoryByStatus'
import { TokenStorageService } from '../tokenStorage/token-storage.service';
import { GlobalConstants } from 'src/app/utility/global-constants';
import { UserTransaction } from 'src/app/modules/forms/user-transaction/user-transaction.module';
import { Notification } from 'src/app/modules/Notification';
import { DataUrl } from 'ngx-image-compress';
 
const AUTH_API = GlobalConstants.serverUrl+'adv/admin/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})

export class UserService {

  constructor(
    private http: HttpClient,
    private userToken: TokenStorageService
  ) { }

  deleteUser(id: number) {
    return this.http.delete(AUTH_API + 'user/' + id, httpOptions);
  }

  saveUser(usr: User) {
    
    return this.http.post<User>(`${AUTH_API}user`, usr, httpOptions);
  }
 

   saveUserImage(imageURi: DataUrl ) {
   var id=this.getCurrentUserId()


   const formData: FormData = new FormData();
     
   formData.append('file', this.getImageFile(imageURi));

    
    const req = new HttpRequest('POST',`${AUTH_API}user/${id}/image`, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
     
  }
  

  getUserImageUrl() {
    var id=this.getCurrentUserId()
    
    return this.http.get<Message>(`${AUTH_API}user/${id}/image`, httpOptions);
  }
  getUserImageUrlById(id) {
     
    
    return this.http.get<Message>(`${AUTH_API}user/${id}/image`, httpOptions);
  }
  getallUser() {
    return this.http.get<User[]>(AUTH_API + 'user', httpOptions);
  }
  getUserById(selectedid: number) {
    return this.http.get<User>(AUTH_API + 'user/' + selectedid, httpOptions);
  }
  getUserByEmail(email: String) {
    return this.http.get<User>(`${AUTH_API}user/email/${email}`, httpOptions);
  }
  getCurrentUser() {
    return this.http.get<User>(AUTH_API + 'user/' + this.getCurrentUserId(), httpOptions);
  }
  getPostCatagoryByStatusOfUser() {
    var id=this.getCurrentUserId()

    return this.http.get<PostCatagoryByStatus>(`${AUTH_API}user/${id}/post_catagory_status`, httpOptions);
    
  }
  getPostCatagoryByStatusOfUserNotCurrent(id:any) {
     

    return this.http.get<PostCatagoryByStatus>(`${AUTH_API}user/${id}/post_catagory_status`, httpOptions);
    
  }
  // setting  email notification for user 
  getUserNotification() {
    var id=this.getCurrentUserId()

    return this.http.get<Notification>(`${AUTH_API}user/${id}/notification`, httpOptions);
    
  }
  setUserNotification(n:any) {
    var id=this.getCurrentUserId()
    return this.http.post<Message>(`${AUTH_API}user/${id}/notification`, n, httpOptions);
  }
  getCurrentUserId() {
    return this.userToken.getCurrentUserId();
  }
  activateNewUserManually(id:number) {
    return this.http.get<Message>(`${AUTH_API}user/${id}/activate_manual`, httpOptions);
  }

  getUserActivePostCount(id:number) {
    return this.http.get<Message>(`${AUTH_API}user/${id}/active_post_count`, httpOptions);
  }
  userExist(id:number) {
    return this.http.get<Message>(`${AUTH_API}user/${id}/exist`, httpOptions);
  }

// dataURI to image converting
getImageFile(data:DataUrl){
     
  const base64 = this.getSubString(data);
const imageName = "sss.png";
const imageBlob = this.dataURItoBlob(base64);
const imageFile = new File([imageBlob], imageName, { type: 'image/png' });
return imageFile;
}

dataURItoBlob(dataURI) {
const byteString = window.atob(dataURI);
const arrayBuffer = new ArrayBuffer(byteString.length);
const int8Array = new Uint8Array(arrayBuffer);
for (let i = 0; i < byteString.length; i++) {
  int8Array[i] = byteString.charCodeAt(i);
}
const blob = new Blob([int8Array], { type: 'image/png' });    
return blob;
}
getSubString(data:DataUrl){
var index=data.indexOf("base64,")
return  data.substring(index+7,data.length)
}
}
 