import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Currency } from 'src/app/modules/Currency';
import { SystemInfo } from 'src/app/modules/system-info';


@Component({
  selector: 'app-system-info-form',
  templateUrl: './system-info-form.component.html',
  styleUrls: ['./system-info-form.component.css']
})
export class SystemInfoFormComponent implements OnInit {
  public catForm: FormGroup;
  public  list=SystemInfo.name_array
  constructor( 
   public dialogRef: MatDialogRef<SystemInfoFormComponent>,
      @Inject(MAT_DIALOG_DATA) public data: SystemInfo) {}
 
   ngOnInit(): void {
     
   }
   onNoClick(): void {
     this.dialogRef.close();
   }
 
 }
 