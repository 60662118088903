export class SystemInfo {

  public static readonly  name_array:string[]=[
     "LOCAL_STORAGE",
      "FREE_ADS_DURATION",
      "LOCAL_FRONT_END_URL",
      "CLOUD_FRONT_END_URL"
]
    constructor( 
        public id:number,
        public name :string,
        public value:string,
        ){

         
}
}
