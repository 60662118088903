<section fxLayout="row" fxLayoutAlign="start center" style="margin: 20px 20px 20px 20px;">

  <mat-card fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="0px"  style="width: 100%;padding-top: 5px;" class="mat-elevation-z8 "  >  
   <button mat-button  color="primary" (click)="goBack()"  >
       <mat-icon class="mat-18">arrow_back</mat-icon>back
   </button>
  <div class=""  fxLayout="row" fxLayoutAlign="center end" style="width: 100%;" >
    <mat-card-header   >
      
        <mat-card-title>Specification List</mat-card-title>
      
         
         
      </mat-card-header>
    </div>
      
      <mat-card-content>
        <div fxLayoutAlign="space-between">
            <mat-form-field fxFlex="30%" appearance="outline" >
              <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Filter" />
            </mat-form-field>
            <a mat-button  routerLink="/admin/specificationform"  [queryParams]="{sid:'-1'}" queryParamsHandling="merge" ><mat-icon class="mat-18">create_new_folder</mat-icon>New Specification</a>
     
          </div>
            <mat-table [dataSource]="dataSource" matSort>
              <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Id</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.id }}</mat-cell>
              </ng-container>
            
              <ng-container matColumnDef="label">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Label</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.label }}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="ControlType">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Label</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.controlType }}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="action">
                  <mat-header-cell *matHeaderCellDef mat-sort-header >Action</mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <a    mat-button  (click)="editSpecification($event, element.id)"  ><mat-icon class="mat-16">edit</mat-icon></a>
                    <a    mat-button  (click)="deleteSpecification($event, element.id)"  ><mat-icon class="mat-16">delete</mat-icon></a>  
                  </mat-cell>
                </ng-container>
 
                <ng-container matColumnDef="go to Post">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>go to Post</mat-header-cell>
                  <mat-cell *matCellDef="let element"  ><a    style="padding-left: 20px;" mat-button-a  href="/" ><mat-icon class="mat-16  "    >forward</mat-icon></a></mat-cell>
                </ng-container>
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>
            <mat-paginator [pageSize]="10" [pageSizeOptions]="[1, 5, 10, 20]">
            </mat-paginator>
        </mat-card-content>
        
    </mat-card>
  </section>

