import { Component } from '@angular/core';
 
@Component({
  selector: 'app-ng-spinner',
  templateUrl: './ng-spinner.component.html',
  styleUrls: ['./ng-spinner.component.css']
})
export class NgSpinnerComponent {
  constructor( ) {}

  ngOnInit() {
    /** spinner starts on init */
     

    setTimeout(() => {
      /** spinner ends after 5 seconds */
         ;
    }, 5000);
  }
}