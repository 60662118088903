import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
 
import { TokenStorageService } from '../tokenStorage/token-storage.service';
import { GlobalConstants } from 'src/app/utility/global-constants';
import { Post_Fraud } from 'src/app/modules/Post_Fraud';
 
 
const AUTH_API = GlobalConstants.serverUrl+'adv/admin/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class AdminPostFraudService {
  
  constructor(private http: HttpClient,
              private  tokenStorage: TokenStorageService) { }

 
getAllPost_FraudByC(post_case:boolean) {
    
    return this.http.get<Post_Fraud[]>(`${AUTH_API}postfraud/caseclosed/${post_case}`, httpOptions);
  }
  getAllPostFraud() {
    
    return this.http.get<Post_Fraud[]>(`${AUTH_API}postfraud`, httpOptions);
  }
  getPost_Fraud(id:number) {
 
    return this.http.get<Post_Fraud>(`${AUTH_API}postfraud/${id}`, httpOptions);
  }
  authoritiyUpdateFraudData(f:Post_Fraud) {
     const uid = this.tokenStorage.getUserId();
      return this.http.post(`${AUTH_API}postfraud/edit/user/${uid}`,f, httpOptions);
    }
    enableDisalbePost(pid:number,enable_diable:boolean) {
      const uid = this.tokenStorage.getUserId();
      return this.http.post(`${AUTH_API}disable_post/${pid}/user/${uid}`,enable_diable, httpOptions)
      
    }
  //  deletePostFraud(id: number) {
  //   return this.http.delete(`${AUTH_API}postfraud/${id}`, httpOptions);
  // }
  // getCurrentUserId() {
  //   return this.tokenStorage.getCurrentUserId();
  // }
  

}
