import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DepositReciept } from 'src/app/modules/DepositReciept';
import { DepositeReceiptFormComponent } from '../deposite-receipt-form/deposite-receipt-form.component';

@Component({
  selector: 'app-deposit-recipt-detail',
  templateUrl: './deposit-recipt-detail.component.html',
  styleUrls: ['./deposit-recipt-detail.component.css']
})
export class DepositReciptDetailComponent implements OnInit {
  
  constructor( 
   public dialogRef: MatDialogRef<DepositReciptDetailComponent>,
   @Inject(MAT_DIALOG_DATA) public data: DepositReciept) {}
 
   ngOnInit(): void {
   
   }
   onNoClick(): void {
     this.dialogRef.close();
   }
  }
 