import { User } from './User';
import { Directive } from "@angular/core";

 
@Directive()
export class Contact  {

constructor(  
             public phone:String 
             ){        
}
 
 
 
  ngOnInit(): void {
      
          
  }
}