
 <div class="section  ">
 
    <mat-card>
        <h1 class="geez">&nbsp;መኣዝን ከመይ ትጥቀመሉ</h1>
        <div class=" geez">
            <p> መኣዝን ሸያጥን ዓዳግን ዝራኸበሉ መኣዲ ኮይኑ ፥ ክትሸይጥ ምስ ትደሊ  ክትምዝገብ ይግብኣካ ፥ ገዛኢ ብምዃን ኣብዚ ገጽ ዘሎ ናይ ሽያጢ ቁጽሪ ስልኪ ክትርኢ ምስ ትደሊ ውን  ክትምዝገብ ይግብኣካ ።</p>
        </div>
    </mat-card>
   
    <!-- expantion pannel  straighten -->
    <mat-accordion>
        
        <mat-expansion-panel  >
          <mat-expansion-panel-header>
            <mat-panel-title class="geez" > ብኸመይ ኣብ መኣዝን እምዝገብ ? </mat-panel-title>
            
          </mat-expansion-panel-header>
          <p class="geez">ኣብ መኣዝን ከመይ ከምትዝገብ ንምርኣይ  ኣብዚ ታሕቲ ዘሎ ቪድዮ ሊንክ ጠውቅ።</p>
          <p><button mat-button color="primary" (click)="howToRegister()"> ብኸመይ ንምዝገብ ኣብ ዩቱብ ንምዕዛብ </button></p>
        </mat-expansion-panel>
        <mat-expansion-panel  >
            <mat-expansion-panel-header>
              <mat-panel-title class="geez" > ብኸመይ ኣብ መኣዝን እሸይጥ ? </mat-panel-title>
              
            </mat-expansion-panel-header>
            <p>I'm visible because I am open</p>
          </mat-expansion-panel>
      </mat-accordion>
      
    
    <!-- expantion pannel  straighter -->
 </div>
    

 
