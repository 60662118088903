<mat-drawer-container class="wrapper2" hasBackdrop="">
  <mat-drawer #drawer mode="over">
    <div class="left-side-draw">
      <div class="cat-sub-list">
          <ul *ngFor="let cat of catagories">
            <!-- <img src="http://localhost:8080/adv/img/{{cat.img}}" width="20" height="20"> -->
              <li  ><a routerLink="/subcat" [queryParams]="{cid:cat.id}" > {{cat.name}} <span>({{cat.qty}})</span></a></li>
            
               
          </ul>
    </div>
          

    </div>
  </mat-drawer>
  <mat-drawer-content>
    <div class="wrapper">

      <div class="left-side">
        <div class="cat-sub-list">
            <ul *ngFor="let cat of catagories">
              <!-- <img src="http://localhost:8080/adv/img/{{cat.img}}" width="20" height="20">  -->
                <li  ><a routerLink="/subcat" [queryParams]="{cid:cat.id}" >{{cat.name}} <span>({{cat.qty}})</span></a></li>
              
                 
            </ul>
      </div>
            
  
      </div>
      <div class="right-side"  >
          <!-- -->
          <div class="right-side-card">
            <button class="right-side-menu" mat-button (click)="drawer.toggle()"><i class="fas fa-bars"></i></button>
         </div>
          <div class="card-list">
              <div class="product-card  " class="product-card  " *ngFor="let p of posts  | async  " >
                  <div class="product-image">
                     <a routerLink="/display" [queryParams]="{pid:p.id}"> <img [src]="getImageUrl(p)"     alt=""></a>
                       <div class="image-number "> <i class="fas fas fa-camera"></i> {{displayImangeNumber(p)}} </div>
               
                  </div>
                  <div class="product-data">
                    <div class="price-tag  flex_row_space_between">
                      <p>{{displayPrice(p)}}</p>
                      <p *ngIf="adminRole()" ><i   class="far fa-eye"></i>  {{p.view}}</p>
        
                    </div> 
        
                   <div class="post-title"><a   routerLink="/display" [queryParams]="{pid:p.id}"> <p>{{p.description}} </p></a></div>
                   
                             
                  </div>
              </div> 
        </div>
          <div class="paginator">
              <!-- <mat-paginator [pageSizeOptions]="[10]"></mat-paginator> -->
              <mat-paginator [length]="100"
              [pageSize]="100"
              [pageSizeOptions]="[10, 25, 50,100]"
              aria-label="Select page">
</mat-paginator>

            </div>
   
          </div>
        
   
  </div>   
   
  
     
     
  </mat-drawer-content>
</mat-drawer-container>

