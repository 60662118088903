import { DatePipe } from '@angular/common';
import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SentEmailToUser } from 'src/app/modules/SentEmailToUser';
import { SentEmailToUserService } from 'src/app/service/SentEmailToUserService/sent-email-to-user-service.service';
import { SentEmailDetailDialogComponent } from './sent-email-detail-dialog/sent-email-detail-dialog.component';
import { ConfirmDialogModel } from 'src/app/utility/ConfirmDialogModel';
import { ConfirmationDialogComponent } from 'src/app/utility/confirmation-dialog/confirmation-dialog.component';
 

@Component({
  selector: 'app-sent-email-list',
  templateUrl: './sent-email-list.component.html',
  styleUrls: ['./sent-email-list.component.css']
})
export class SentEmailListComponent implements OnInit {
  displayedColumns = ['id', 'user', 'email_title', 'status','action'];
  dataSource = new MatTableDataSource<SentEmailToUser>();

  total = 0
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;


  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });


  constructor(
    private sentEmailService: SentEmailToUserService,
    public dialog: MatDialog,
    private snackbar: MatSnackBar,
    // private datePipe: DatePipe, 
  ) { }
  ngOnInit(): void {
    this.getSourcedata();
  }
  getSourcedata() {
    this.sentEmailService.getAllRowIdDesc().subscribe(
      data => {

        this.dataSource.data = data
       
      },
      error => {
        this.snackbar.open("Retrieve Data Fail", "error")._dismissAfter(2000)
      }
    );

  }


  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  doFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
 

  dateRangeFilter() {
    var datePipe = new DatePipe('en-GB');

    this.sentEmailService.getSentEmailToUserByDate(
      datePipe.transform(this.range.value.start, 'dd-MM-yyyy'),
      datePipe.transform(this.range.value.end, 'dd-MM-yyyy')
    ).subscribe(
      data => {

        this.dataSource.data = data
        console.log("error ==>"+JSON.stringify(data));
        
    
      },
      error => {
        this.snackbar.open("Retrieve Data Fail", "error")._dismissAfter(2000)
      }
    );
  }
  deleteEmailSent(id:number){
    this.deletePostDialog("Confirmation","Do you really want to Delete Email Data ?",id);
  }
 
   deletePostDialog(title,message,id){
     
       const dialogData = new ConfirmDialogModel(title, message);
   
     const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
       maxWidth: "400px",
       data: dialogData
     });
   
     dialogRef.afterClosed().subscribe(dialogResult => {
        dialogResult;
       if( dialogResult){
           this.sentEmailService.deleteSentEmailToUserById(id).subscribe(
             r=>{
               this.openSnackBar("Post Deleted !!!","Message")
               this.getSourcedata()
                  
                },
                e=>{
                  this.openSnackBar("Post Delete Fail !!!","Error")
                }
              );
       }
     });
   
   }
   // end to delete dialog 

  showDetail(sentEmail:SentEmailToUser): void {
    const dialogRef = this.dialog.open(SentEmailDetailDialogComponent, {
      width: '650px',
      data: sentEmail,
    });
  }
 
  openSnackBar(message: string, action: string) {
    this.snackbar.open(message, action, {
      duration: 3000,
    });
  }
}
