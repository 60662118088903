import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DepositReciept } from 'src/app/modules/DepositReciept';
import { PostRecipt } from 'src/app/modules/PostRecipt';
import { PostReceiptService } from 'src/app/service/post/post-receipt.service';
 

@Component({
  selector: 'app-post-detail-dialog',
  templateUrl: './post-detail-dialog.component.html',
  styleUrls: ['./post-detail-dialog.component.css']
})
export class PostDetailDialogComponent implements OnInit {

 

  public  postReceipt:PostRecipt
  constructor( 
     public  postReciptService:PostReceiptService,
    public dialogRef: MatDialogRef<PostDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public postReceiptId: number) {}
  
    ngOnInit(): void {
     
      this.postReciptService.getPostReceiptByReceiptNumber(this.postReceiptId).subscribe(
        r=>{
               this.postReceipt=r
               console.log("fetch data  ==>" + JSON.stringify(r));
               
        },e=>{
           
        }
      )
    }
    onNoClick(): void {
      this.dialogRef.close();
    }
   }
  