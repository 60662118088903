<h1 mat-dialog-title>Confirm ?</h1>
<div mat-dialog-content>
  <p>Do you Really want To Delete User  " {{data.fullName}}"   ?</p>
 
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">No </button>
  <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Yes</button>
</div>

