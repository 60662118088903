<section fxLayout="row" fxLayoutAlign="start center"  >

    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="0px"       >  
    
          <div fxLayoutAlign="space-between">
             
              <button mat-button (click)="newRole()"  color="primary"><mat-icon class="mat-18">create_new_folder</mat-icon> New Role</button>
       
            </div>
            
            <mat-table [dataSource]="dataSource" matSort    >
              <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Id</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.id }}</mat-cell>
              </ng-container>
            
              <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
              </ng-container>
              
                <ng-container matColumnDef="Delete">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Delete</mat-header-cell>
                  <mat-cell *matCellDef="let element"><a    mat-button  (click)="deleteRole($event, element.id)" ><mat-icon class="mat-16">delete</mat-icon></a>  </mat-cell>
                </ng-container>
               
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>
            
     
       </div>
  </section>
