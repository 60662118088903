<section   style="margin: 20px 20px 20px 20px;">

    <mat-card  class="flex_column_center_center"  style="width: 100%;padding:10px;" >

        <div>
            <mat-card-header  class="flex_row_center_end" style="width: 100%;">

                <mat-card-title>Product Catagory</mat-card-title>



            </mat-card-header>
        </div>

        <mat-card-content>
            <div class="flex_row_space_between">
                <mat-form-field fxFlex="30%"  appearance="outline">
                    <input style="height:30px;"  matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Filter" />
                </mat-form-field>
                <button mat-button (click)="newCatagory()" color="primary"><mat-icon class="mat-18">create_new_folder</mat-icon> New Catagory</button>

            </div>

            <mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Id</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.order }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="img">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Image</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <img [src]="getImageUrl(element.img)" width="50" height="50" alt="">

                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.name }} [{{getElementLength(element.id)}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="Edit">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Edit</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <a mat-button (click)="editCat($event, element.id)">
                            <mat-icon class="mat-16">edit</mat-icon>
                        </a>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Delete">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Delete</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <a mat-button (click)="deleteCatagory($event, element.id)">
                            <mat-icon class="mat-16">delete</mat-icon>
                        </a>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Sub Catagory">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Sub Catagory</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <a style="padding-left: 20px;" mat-button-a routerLink="/admin/subcatagory" [queryParams]="{cid:element.id}">
                            <mat-icon class="mat-16  ">forward</mat-icon>
                        </a>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>
            <mat-paginator [pageSize]="10" [pageSizeOptions]="[1, 5, 10, 20]">
            </mat-paginator>
        </mat-card-content>


        <mat-card-footer>
        </mat-card-footer>
    </mat-card>
</section>