import { User } from './User';
import { Directive } from "@angular/core";


@Directive()
export class Notification {

  constructor(
    public id: number,
    public user: User,
    public hot_deals_recomendations: boolean,
    public info_about_your_Ads: boolean,
    public subscription: boolean,
    public messages: boolean
  ) {
  }




  ngOnInit(): void {


  }
  
}