 

 
export class PasswordChange  {

constructor(  
             public email:String,
             public oldPassword:String,
             public  newPassword:String 
             ){        
}
 
 
  
}