<footer>
    <div class="footer-container">
        <div class="footer-links">
        
              <a href="/about_us" i18n>About Us</a>
            <a href="/contact" i18n>Contact Us</a>
            <!-- <a href="/termsofservice" i18n>Terms of Service</a>
            <a href="/howitwork" i18n>How it Work</a> 
              <a href="/advertisepolicy" i18n>Terms of Service</a>  -->
             <!-- <p>ንኽትረኽቡና ሞባይል ፥ +211981400409  </p> -->
       
        </div>
        <div class="footer-info">
            
            <p>&copy; 2024 መኣዝን</p>
       
        </div>
    </div>
</footer>