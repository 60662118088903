import { SpecificationHeadOption } from "./SpecificationHeadOption";

export class SpecificationHead {
    constructor(
        public id: number,
        public key: string,
        public label: String,
        public required:boolean,
        public controlType:  "textbox"|"dropdown"|"textarea",
        public  type: ""|"text"|"email",
        public selectType: "PARENT"|"CHILD"|"NONE",
        public  parentkey:String,
        public pattern: String,
        public  placeholder : String,
        public value?: String,
        public parentvalue?:String,
        public order?: number,
        public options?: SpecificationHeadOption[]
         ) {   
            }
}
  