<div class="" style="padding-bottom: 50px;">
    <mat-card fxLayoutAlign="center center" style="padding-bottom: 20px;"> <a mat-button>
            <h2>Specifiation Setting</h2>
        </a></mat-card>

</div>
<div class="flex_row_start_start_wrap" style="margin: 10px;">
    <!-- form card -->
    <mat-card style="margin: 20px;">

        <form [formGroup]="specificationHeadForm" (ngSubmit)="submitForm()">

            <div class="flex_column_start_start"  style="padding: 20px;" >
                <mat-form-field appearance="outline">
                    <mat-label>Id</mat-label>
                    <input matInput type="text" formControlName="id" readonly>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Control Type</mat-label>
                    <mat-select (selectionChange)="onControlTypeChange($event)" formControlName="controlType" required>
                        <mat-option value="textbox">textbox</mat-option>
                        <mat-option value="textarea">textarea</mat-option>
                        <mat-option value="dropdown">dropdown</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="!hideSelectTypeForm" appearance="outline">
                    <mat-label>Select Type</mat-label>
                    <mat-select formControlName="selectType" (selectionChange)="onselectTypeChange($event)">
                        <mat-option value="PARENT">PARENT</mat-option>
                        <mat-option value="CHILD">CHILD</mat-option>
                        <mat-option value="NONE">NONE</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="!hideparentKey" appearance="outline">
                    <mat-label> Parent Key </mat-label>
                    <mat-select formControlName="parentkey" [required]="hideparentKey">

                        <mat-option *ngFor="let opt of spH_list" [value]="opt">
                            {{opt}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="!hidetextFom" appearance="outline">
                    <mat-label>Type</mat-label>
                    <mat-select formControlName="type">
                        <mat-option value=""> </mat-option>
                        <mat-option value="text">text</mat-option>
                        <mat-option value="email">email</mat-option>

                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Label</mat-label>
                    <input matInput type="text" formControlName="label" required>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Place Holder</mat-label>
                    <input matInput type="text" formControlName="placeholder" required>
                </mat-form-field>
                <!-- key -->
                <mat-form-field appearance="outline">
                    <mat-label>Key</mat-label>
                    <!-- pattern="[^' ']+" -->
                    <input matInput type="text" formControlName="key" pattern="[^' ']+"  required>
                </mat-form-field>
                <!-- value -->
                <!-- <mat-form-field appearance="outline">
            <mat-label>Value</mat-label>
            <input matInput type="text" formControlName="value" >
        </mat-form-field> -->
                <!-- required -->
                <div  style="width: 300px;">
                    <mat-checkbox formControlName="required">required</mat-checkbox>
                </div>
                <!-- pattern -->
                <mat-form-field *ngIf="!hidetextFom" appearance="outline">
                    <mat-label>pattern</mat-label>
                    <input matInput type="text" formControlName="pattern">
                </mat-form-field>



                <div class="" style="width: 100%" fxLayoutAlign="center center">
                    <button mat-raised-button type="submit" [disabled]="!specificationHeadForm.valid" color="primary">
                        Save
                    </button>
                </div>
            </div>



        </form>
    </mat-card>
    <!-- form card end -->
    <!-- option list start -->
    <div *ngIf="hidetextFom" fxFlex="45%">


        <app-specificationhead-option></app-specificationhead-option>


    </div>
    <!-- option list end -->
</div>