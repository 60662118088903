import { Pipe, PipeTransform } from '@angular/core';
import { ProductSubCatagory } from 'src/app/modules/ProductSubCatagory';

@Pipe({
  name: 'disableOption' 
})
export class DisableOptionPipe implements PipeTransform {

  transform(value: any, args?: any): unknown {

    return value.filter((x: any) => (x.disable=== null|| x.disable===false));
     
  }

}
