 
<mat-drawer-container class="wrapper2" hasBackdrop="">
    <mat-drawer #drawer mode="over">
        <div class="left-side-draw">

            <app-right-panel></app-right-panel>

        </div>
    </mat-drawer>
    <mat-drawer-content>
        <div class="wrapper">

            <div class="left-side">

                <app-right-panel></app-right-panel>

            </div>
            <div class="right-side">
                <div class="right-side-card">
                    <button class="right-side-menu" mat-button (click)="drawer.toggle()"><i
                            class="fas fa-bars"></i></button>
                </div>

                <div class="right-side-item">

                    <app-profile *ngIf="index===0"></app-profile>
                    <app-user-post-list *ngIf="index===1"></app-user-post-list>
                    <app-payment-list  *ngIf="index===2"></app-payment-list>  
                    <app-privacy-setting  *ngIf="index===3" ></app-privacy-setting>
                </div>




            </div>


        </div>




    </mat-drawer-content>
</mat-drawer-container>


<!--  

<app-personal-information class="lower-item"></app-personal-information>

<app-change-contact class="lower-item"></app-change-contact>

<app-change-password class="lower-item"></app-change-password>
 <app-change-profile-image class="lower-item"></app-change-profile-image> -->

 