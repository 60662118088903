import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-post-managment',
  templateUrl: './post-managment.component.html',
  styleUrls: ['./post-managment.component.css']
})
export class PostManagmentComponent implements OnInit {
   

  page_url:String=""
  index:number=0
  constructor(private route: Router){

  }
  title = 'angular-example';

  ngOnInit(){
    this.page_url=this.route.routerState.snapshot.url
    
  if(this.page_url==="/admin/post/status") {
    this.index=0;
     }else if(this.page_url==="/admin/post/abuse_notification") {
       this.index=1;
     }      else  {
       this.index=0;
       this.route.navigate(["/admin/post/status"])
     }
}
getDescription(){
    
  if(this.page_url==="/admin/post/status") {
    return  "Post Status "
     }else if(this.page_url==="/admin/post/abuse_notification") {
      return "Abuse Report Notification"
     } 
     else  {
       this.index=0;
       this.route.navigate(["/admin/post/status"])
     }
}
}
