import { Post } from './Post';
import { User } from './User';

 
export class Post_Fraud  {

constructor(  
             public id:number,
             public user:User,
             public post:Post,
             public reason:String,
             public admin_user?:User,
             public case_closed?:boolean,
             public closed_date?:Date,
             public conculusion?:String,
             public createdDate?:Date,
             public  updatedDate?:Date,
             
             ){       
           }
 
}