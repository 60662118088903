 
  <div class="dialog  " >
    <div class="left">
        <h3>Adv Edit/New</h3>
        <form action=""      #f="ngForm"   >
          <mat-form-field appearance="outline">
            <input type="text" matInput placeholder="Id"  required name="id" disabled [(ngModel)]="data.id" >
          </mat-form-field>
          
            <mat-form-field appearance="outline">
              <input type="text" matInput placeholder="name" required name="name1" minlength="2" [(ngModel)]="data.name" >
          
            </mat-form-field>
            <mat-form-field appearance="outline">
              <input type="number" matInput placeholder="order" required name="order1"   [(ngModel)]="data.order" >
          
            </mat-form-field >
             
              <section   >
                <mat-checkbox  name="disable1" [checked]="data.disable"  [(ngModel)]="data.disable" >Disable This Catagory </mat-checkbox>
                 
              </section>
             
            <div mat-dialog-actions>
              <button mat-button (click)="onNoClick()">Cancel</button>
               <button   [disabled]="f.invalid" mat-button [mat-dialog-close]="data" cdkFocusInitial>Save</button>
            
        
          </div>
        </form>
    </div>
   <div class="right">
       <img  [src]="getImageUrl(data.img)"  width="100" height="100" alt="">
       <div class="">

        <button mat-raised-button color="primary" (click)="onClick($event)">
          <mat-icon>upload</mat-icon>
          Change Image
        </button>
        
        <input #fileUpload type="file" class="input_fileupload--hidden" (input)="onInput($event)"
          (change)="onFileSelected($event)"  [(ngModel)]="inputFileName" accept="image/x-png,image/jpeg">
        
      

      </div>
   </div>
  </div>
  