 
<div class="dialog-title">
  <h3>Confirm ?</h3>
</div>
<div class="dialog-body">
  <p>Image will be Deleted Permanently ?</p>
</div>
<div class="dialog-button">
  <button class="btn-cancel" mat-button color="warn" (click)="onNoClick()">No</button>
  <button class="btn-save"  mat-button  color="primary" [mat-dialog-close]="data" cdkFocusInitial>Yes</button>
</div>