 
import { Role } from './Role';

export class SalesLocation  {

constructor(  
             public  id:number,
             public  city :String,
             public state :String,
             public country :String,
             ){ }
   
}