import { Component, Inject, OnInit } from '@angular/core';
 
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { DepositReciept } from 'src/app/modules/DepositReciept';
import { DepositeReceiptService } from 'src/app/service/depositeSerivce/deposite-receipt-service.service';
 

@Component({
  selector: 'app-admin-receipt-detail',
  templateUrl: './admin-receipt-detail.component.html',
  styleUrls: ['./admin-receipt-detail.component.css']
})
export class AdminReceiptDetailComponent implements OnInit {



  enableVoidButton:boolean=true
  constructor( 
    public dialogRef: MatDialogRef<AdminReceiptDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DepositReciept,
    public snackBar: MatSnackBar,
    public  receiptDepositeService:DepositeReceiptService) {}
  
    ngOnInit(): void {
   console.log("receipt detail ==>"+JSON.stringify(this.data));
   
    
    }
    onNoClick(): void {
      this.dialogRef.close();
    }
    voidReceipt(){
      this.enableVoidButton=false
      this.receiptDepositeService.voidDepositeReceipt(this.data.id).subscribe(
        r=> {
          this.openSnackBar("Receipt Voided !!!!","Message")
          this.dialogRef.close();
        }, e => {
          this.openSnackBar(JSON.stringify(e.error.message),"Error")
          this.dialogRef.close();
        }
      )
     
    }
 
    openSnackBar(message: string, action: string) {

      this.snackBar.open(message, action, {
        duration: 2500,
      });
    }
   }
  