import { PostPayment } from "../PostPayment";
import { Post_status } from "../Post_status";
import { PostSpecificationSummary } from "./PostSpecificationSummary";


 
export class PostSave {

    constructor(
        public id: number,       // post id
        public user:number, 
        public description: String,   // product short name
        public  detail :String , // detail of product
        public remark:String,
        public  salesLocation:number,
        public productSubCatagory: number,    // product sub catagory
        
        public  specificationList: PostSpecificationSummary[],  // tabular specification of product
             
        public post_payment: PostPayment,
        public  serviceOption: 'FREE'|'WEEK' | 'MONTH' | 'YEAR' ,
     
        public  post_status?:Post_status,
        
        
        
        
    ) { }

  }