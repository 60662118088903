<section class="catagoryList" >
  <div class="wrapper">
     <div class="catagory-title">
      <h1 i18n>Latest Ads </h1>
    
   
   <hr>
     </div>
      <div class="items  ">

        <div class="product-card  "   *ngFor="let p of posts  | async  " >
          <div class="product-image">
             <a  (click)="openAds(p.id)" > <img [src]="getImageUrl(p)"     alt=""></a>
               <div class="image-number "> <i class="fas fas fa-camera"></i> {{displayImangeNumber(p)}} </div>
       
          </div>
          <div class="product-data">
            <div class="post-title"><a   (click)="openAds(p.id)"   > <p>{{p.description}} </p></a></div>
            <div class="price-tag  flex_row_space_between">
              <p>{{displayPrice(p)}}</p>
              <p *ngIf="adminRole()" ><i   class="far fa-eye"></i> {{p.view}}</p>

            </div>   <!-- <span *ngIf="displayNegotiable(p)" class="negotiable-tag">(negotiable)</span> -->
         
                     
          </div>
      </div> 

       
  
      </div>
      <div class="paginator">
        <!-- <mat-paginator [pageSizeOptions]="[10]"></mat-paginator> -->
        <mat-paginator [length]="100"
              [pageSize]="100"
              [pageSizeOptions]="[ 10, 25,50,100]"
              aria-label="Select page">
</mat-paginator>

      </div>
      <div class="view-all">
        <a routerLink="/cat"  mat-button  i18n>View All Ads</a>
       
      </div>
  </div>

</section>