<div class="wrapper">
    <div class="notice-bord  " *ngIf="this.post.post_status.status=='ERROR'">
        <p>{{this.post.post_status.rejectionReason}}</p>
        <p>And Apply agian!!!</p>
    </div>
    <div class="notice-bord  " *ngIf="this.post.post_status.status=='DISABLED'">
        <p>Post Disabled By Admin !!!</p>
    </div>
    <div class="notice-bord  " *ngIf="this.post.post_status.status=='PENDING'">
        <p>Post is waiting for Evaluation !!!</p>
    </div>
    <div class="detail-left ">


        <div class="main-image ">
            <img src="{{imagePath}} " alt=" ">
             

            <div class="image-number ">
                <p>
                    <mat-icon>camera_alt</mat-icon>{{getIndex_lenth()}}</p>
            </div>
            <a class="prev2 " (click)="preveousSlides() ">
                <mat-icon>chevron_left</mat-icon>
            </a>
            <a class="next2 " (click)="nextSlides() ">
                <mat-icon>chevron_right</mat-icon>
            </a>

        </div>
        <div class="product-info ">

            <div class="post-date ">
                <p>
                    <mat-icon>watch_later</mat-icon> Posted {{post.post_status.apply_date| dateAgo}}</p>
                <p>
                    <mat-icon>location_on</mat-icon> {{post.salesLocation.state}}, {{post.salesLocation.city}} </p>
                <p>
                    <mat-icon>visibility</mat-icon> {{post.view}} views </p>
            </div>
            <div class="price-tag">{{displayPrice()}}<span *ngIf="displayNegotiable()" class="negotiable-tag">(negotiable)</span></div>
            <div class="post-title ">
                <h1>{{post.description}}</h1>
            </div>
            
        </div>
        <div class="post-specification">
            <h1>   Specifiation </h1>
            <hr>
             <div class="specification-table">
                <table   *ngFor="let sp of post.specificationList"  >
                    <tr  class="table-row">
                      <td class="specificaiton-name">{{sp.specification.key}}</td>
                     
                      <td class="specification-value">{{sp.value}}</td>
                    </tr>
                 </table>
             </div>
        </div>
            
 
        <div class="seller-loction ">
            <h2> Detail </h2>
            <hr>
            <div > {{post.detail}} </div>
        </div>

        <div class="seller-loction ">
            <h2>Remark </h2>
            <hr>
            <div > {{post.remark}} </div>
        </div>
        <div class="seller-loction ">
            <h2> Extra Ads Info </h2>
            <hr>
            <div ><b> Ads Status : </b><i>{{post.post_status.status}}</i></div>
            <div ><b> Apply Date : </b><i>{{post.post_status.apply_date  |  customDatePipe }}</i></div>
            <div *ngIf="post.post_status.status==='ACTIVE'" ><b> Expire Date : </b><i>{{post.post_status.expire_date |  customDatePipe}}</i></div>
        </div>

        <div class="decision seller-loction " *ngIf="this.post.post_status.status=='PENDING'">
            <h1> Evaluation Decession </h1>
            <hr>

            <form (ngSubmit)="submitForm()" [formGroup]="form">
                <div class="">
                    <mat-radio-group aria-label=" Please Select " (change)="decisionChange() " formControlName="decision" required>
                        <mat-radio-button value="1">Accept</mat-radio-button>


                        <mat-radio-button value="0">Reject</mat-radio-button>

                    </mat-radio-group>
                </div>
                <div class="" *ngIf="!error_description_required">
                    <mat-form-field appearance="outline">
                        <mat-label>Reason For Rejection</mat-label>
                        <textarea matInput formControlName="error_description" placeholder=" Reason For Reject Please, this reason will be send to user " [required]="!error_description_required"></textarea>
                    </mat-form-field>

                </div>


                <div class="submit_button " fxLayoutAlign="space-between ">
                    <button class="adv-button" mat-button type="submit " [disabled]="!form.valid && !error_description_required">Submit</button>
                </div>

            </form>

        </div>
    </div>
    <div class="right-side ">
        <div class="seller-detail ">
            <div class="seller-img ">

                <img [src]="getUserImage() " width="100 " height="100 " alt=" ">
            </div>
            <div class="seller-info ">

                <p>Seller : <a class="link " href="# "> {{post.user.fullName}}</a> </p>

                <p>Phone : <span style="color: #00a651; ">{{post.user.contact.phone}}</span> </p>

            </div>
        </div>



    </div>


</div>