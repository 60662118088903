
<div id="section1" style="display: none;" #section1></div>
<app-lodding-spinner></app-lodding-spinner>
<div class="wrapper"  >
    <div class="detail-left">

        <div class="main-image">
            <img src="{{imagePath}}" (click)="showImageInFullScreen()" >
            
   
    <div class="image-number">
        <p> <mat-icon>camera_alt</mat-icon>{{getIndex_lenth()}}</p></div>
            <a class="prev2" (click)="preveousSlides()"><mat-icon>chevron_left</mat-icon></a>
            <a class="next2" (click)="nextSlides()"><mat-icon>chevron_right</mat-icon> </a>

        </div>
        
        <div class="product-info ">
            
            <div class="post-date">
                <p> <i class="far fa-clock"></i> {{post.post_status.apply_date| dateAgo}}</p>
                <p><i class="fas fa-map-marker-alt"></i> {{post.salesLocation.state}}, {{post.salesLocation.city}} </p> 
                <p><i class="far fa-eye"></i> {{post.view}}  </p> 
            </div>
            <div class="price-tag">{{displayPrice()}}<span *ngIf="displayNegotiable()" class="negotiable-tag">(negotiable)</span></div>
            <div class="post-title">
                <h1>{{post.description}}</h1> 
            </div>
          

</div>

            <div class="post-specification">
                <h1 i18n>   Specifiation </h1>
                <hr>
                 <div class="specification-table">
                    <table   *ngFor="let sp of post.specificationList"  >
                        <tr  class="table-row">
                          <td class="specificaiton-name">{{sp.specification.key}}</td>
                         
                          <td class="specification-value">{{sp.value}}</td>
                        </tr>
                     </table>
                 </div>
            </div>
  

         
            
        
        <div class="seller-loction">
          <h1  i18n> Detail </h1>
          <hr>
          <div [innerHtml]="post.detail"> </div>
        </div>
    </div>
      <div class="right-side">
        <div class="seller-detail">
            <div class="seller-img">
            <!-- <imአg src="assets/img/1.png" alt=""> -->
            <img src="{{getUserImageUrl(post.user.image_name)}}" width="100" height="100" alt="">
           </div>
           <div class="seller-info">
               <p><span i18n>Seller : </span><a class="link"  > {{post.user.fullName}}</a> </p>
              <p  ><span i18n>Phone :</span> <span style="color: #00a651;">{{post.user.contact.phone}}</span>  </p>
              <!-- <p  class="view_contact" *ngIf="!isUserLogIn()"><span i18n>Phone :</span> <span style="color: #00a651;"> <a mat-flat-button (click)="viewPhoneNumber()"  >View Phone Number</a></span>  </p>
               -->
              <p><span i18n>Last seen :</span> {{post.user.lastSeen | dateAgo}}</p>
              <div class=""> <a class="link" routerLink="/user" [queryParams]="{uid:post.user.id}" href=""><span i18n>View My other items</span></a></div>
           </div>
        </div>
        
        <!-- <div class="abuse-detail">
            <div class="abuse-header"  i18n  >Safty Tips</div>
            <div class="safty_tips">
                <ul>
                    <li><p i18n>Remember, don't send any pre-payments</p></li>
                     <li><p  i18n>Meet the seller at a safe public place</p></li>
                     <li><p  i18n>Inspect the goodsorService to make sure <br>they meet your needs </p></li>
                     <li><p i18n>Check all documentation and only pay if you're <br> satisfied </p></li>

                </ul>
            </div>
            <div class="abuse-buttton">
                <button mat-button (click)="onAbuseClick()"i18n><i   class="fas  fa-flag"></i > Report Abuse</button>
            </div>
        </div> -->
        

    </div>
 

</div>