import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';

import { GlobalConstants } from 'src/app/utility/global-constants';

import { SystemInfo } from '../modules/system-info';

const AUTH_API = GlobalConstants.serverUrl+'adv/admin/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class SystemInfoService {
 
  constructor(
    private http: HttpClient
  ) {}


 
  deleteSystemInfo(id:number){ 
    return  this.http.delete(AUTH_API + 'systeminfo/'+id, httpOptions)
     }

    saveSystemInfo (loc:SystemInfo){
      return  this.http.post<SystemInfo >(AUTH_API + 'systeminfo' ,loc, httpOptions)
      
    }

  getallSystemInfo (){ 
        return  this.http.get<SystemInfo[]>(AUTH_API + 'systeminfo' , httpOptions)
    }
 
  getSystemInfoById(selectedid:number){  
    return  this.http.get<SystemInfo>(AUTH_API + 'systeminfo/'+selectedid , httpOptions)
   
  }
 
  
}
