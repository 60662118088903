import { Pipe, PipeTransform } from '@angular/core';
import { SpecificationHeadOption } from 'src/app/modules/SpecificationHeadOption';

@Pipe({
  name: 'filterList'
})
export class FilterListPipe implements PipeTransform {
  transform(value: SpecificationHeadOption[], text: string): any {
  // console.log("pipe value "+JSON.stringify( value));
 //console.log("pipe  text "+JSON.stringify( text));    
//console.log("pipe  text "+JSON.stringify(value.filter((x: SpecificationHeadOption) => x.pvalue.match(text))));  
    if (text === "" || text === undefined) return []
    else
      return value.filter((x: SpecificationHeadOption) => x.pvalue.match(text));


  }

}
