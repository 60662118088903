import { formatNumber } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { Post } from 'src/app/modules/Post';
import { CatagoyrService } from 'src/app/service/catagory/catagory.service';
import { PostService } from 'src/app/service/post/post.service';
import { TokenStorageService } from 'src/app/service/tokenStorage/token-storage.service';
import { GlobalConstants } from 'src/app/utility/global-constants';

const AUTH_API = GlobalConstants.serverUrl+'adv/';
@Component({
  selector: 'app-catagory-page',
  templateUrl: './catagory-page.component.html',
  styleUrls: ['./catagory-page.component.css']
})
export class CatagoryPageComponent implements OnInit {

  catagories
  public  posts: Observable<any>;
 
  @ViewChild(MatPaginator) paginator: MatPaginator;
 
  
  // obs: Observable<any>;
   dataSource: MatTableDataSource<Post> = new MatTableDataSource<Post>();
  constructor(public catService:CatagoyrService,
              public  postService:PostService,
              public snackBar: MatSnackBar,
              public  tokenStorage:TokenStorageService,
              private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    console.log("i am here");
    
    this.getSource()

    this.changeDetectorRef.detectChanges();
      this.dataSource.paginator = this.paginator;
     this.posts= this.dataSource.connect()

 
   
  }
  async  getSource(){
    const s =await  this.postService.getallPost().toPromise()
    if(s != undefined){
       this.dataSource.data=s
       console.log("posst size ==> "+s.length)
    }else {
      this.openSnackBar("couldn't retrieve Posts !!!", "error")
    }

    const p =await  this.catService.getListOfCatagoryByPost().toPromise()
    if(p != undefined){
       this.catagories=p
      
       
    }else {
      this.openSnackBar("couldn't retrieve catagories !!!", "error")
    }


     }
 
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  getImageUrl(post:Post){
    if(post.postImage.length<1){
      return "assets/img/avatar.png"
    }else {
      return    AUTH_API+"img/"+post.postImage[0].name
    }

  }
  displayPrice(post:Post){
    if(post.post_payment.option=== "PRICE"){ 
      return  ""+ this.numberWithCommas(post.post_payment.price_amount) +" "+post.post_payment.price_currency.shortName
   }else if(post.post_payment.option=== "CONTACT"){
      return  "CONTACT"
 
   }
   else if(post.post_payment.option=== "COMMISSION"){
     return  "COMMISSION"
 
   } else if(post.post_payment.option=== "RANGE"){
      return ""+ this.numberWithCommas(post.post_payment.min)+"-"+ this.numberWithCommas(post.post_payment.max) +" "+post.post_payment.price_currency.shortName
   }
 }
 displayImangeNumber(p:Post){
     return   p.postImage.length
     
 }

 numberWithCommas(x) {
  return formatNumber(Number(x), 'en-US', '1.0-0');
   
}
 ngOnDestroy() {
  if (this.dataSource) { 
    this.dataSource.disconnect(); 
  }
}

adminRole(){
  return this.tokenStorage.isAdminRole();
 }


}
