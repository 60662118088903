<section class="flex_column_center_center"  >
  <mat-card   style="margin-top:10px;" >
   
    <mat-card-header>
             <mat-card-title i18n>Reset Password</mat-card-title>

    </mat-card-header>
 
    
    <mat-card-content     >
      <form class="form" (ngSubmit)="!newpass.invalid && onSubmit()" #newpass="ngForm"  class="flex_column_center_center">  
        
      <mat-form-field appearance="outline">
        <mat-label  i18n>Enter New password</mat-label>
        <input type="password" matInput [(ngModel)]="model.password" minlength="6" name="password" id="password"
          required #password="ngModel" />
          <mat-error  i18n>minimum length 6 characters</mat-error>

      </mat-form-field> 
    
      <mat-form-field appearance="outline">
        <mat-label  i18n>Retype New Password</mat-label>
        <input type="password" matInput [(ngModel)]="model.pass" minlength="6" id="pass" name="pass" required
          #pass="ngModel" />
          <mat-error  i18n>minimum length 6 characters</mat-error>

      </mat-form-field>
      <button class="adv-button" type="submit" mat-raised-button   [disabled]="!newpass.form.valid">Submit</button>
     
    </form>
    </mat-card-content>

    <mat-card-actions align="start">
     

    </mat-card-actions>

   
  </mat-card>
</section>