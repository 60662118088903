 

export class Currency  {

constructor(  
             public  id:number,
             public  longName :String,
             public shortName :String,
             ){ }
                  
  
}