
<mat-card>
    <h1>version of the code</h1>
    <hr />
    <div class="content">
    <p>Code Version : 1.0001 </p>
    <p>Deployemnt Date : 15/03/2023 </p>
    <p>Deployemnt Number: 0000000002 </p>
   </div>
</mat-card>
