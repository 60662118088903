import { Component, OnInit } from '@angular/core';
 
@Component({
  selector: 'app-casher-page',
  templateUrl: './casher-page.component.html',
  styleUrls: ['./casher-page.component.css']
})
export class CasherPageComponent implements OnInit {
 
 

  constructor( ) { }

  ngOnInit(): void {
  }

  
}
