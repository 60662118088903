 

 
 <mat-card   >
  <mat-card-title   i18n >Change  Address</mat-card-title>
  

    <mat-card-content>
      <form (ngSubmit)="onSubmit()" [formGroup]="form">
           <div class="form_item" >
           <mat-form-field appearance="outline">
               <mat-label i18n>Address</mat-label>
               <input    matInput  type="text" formControlName="address"  required  >
             </mat-form-field>
             <mat-form-field appearance="outline">
              <mat-label i18n>City</mat-label>
              <mat-select formControlName="location" required>
                  <mat-option *ngFor="let sal of salesLocation" [value]="sal">
                      {{sal.city}}
                  </mat-option>
              </mat-select>
          </mat-form-field>
             <div class="action">
              <button class="adv-button" mat-raised-button color="primary" type="submit"    [disabled]="!form.valid" i18n>Save Change</button> 
            
             </div>
              </div>
       </form>
    </mat-card-content>
    <mat-card-actions align="start">
       
        
    </mat-card-actions>
 
</mat-card>


