import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MaterialModule}  from '../app/material/material.module'
 
 
import { ActivateComponent } from './common/activate/activate.component';
import { ErrorComponent } from './common/error/error.component';
import { FooterComponent } from './common/footer/footer.component';
import { ForgotpasswordComponent } from './common/forgotpassword/forgotpassword.component';
import { HomeComponent } from './common/home/home.component';
import { LoginComponent } from './common/login/login.component';
import { LogoutComponent } from './common/logout/logout.component';
import { NewpasswordComponent } from './common/newpassword/newpassword.component';
import { RegisterComponent } from './common/register/register.component';
import { ProductCatagoryComponent } from './admin/product-catagory/product-catagory.component';
import { ProductSubCatagoryComponent } from './admin/product-sub-catagory/product-sub-catagory.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpIntercepterService } from './service/http/http-intercepter.service';
//  import { CKEditorModule } from 'ckeditor4-angular';
 import { CatagoryFormComponent } from './admin/product-catagory/catagoryForm/catagory-form/catagory-form.component';
 import { SubCatagoryFormComponent } from './admin/product-sub-catagory/subCatagoryForm/sub-catagory-form/sub-catagory-form.component';
import { SubCatagoryYesNoComponent } from './admin/product-sub-catagory/dialog/sub-catagory-yes-no/sub-catagory-yes-no.component';
import { UserFormComponent } from './admin/setting/users/userForm/user-form/user-form.component';
import { UserYesNoDialogComponent } from './admin/setting/users/userYesNoDialog/user-yes-no-dialog/user-yes-no-dialog.component';
import { SettingComponent } from './admin/setting/setting.component';
import { UsersComponent } from './admin/setting/users/users.component';
import { CurrencyComponent } from './admin/setting/currency/currency.component';
import { CurrencyFormComponent } from './admin/setting/currency/currency-form/currency-form.component';
import { CurrencyYesNoFormComponent } from './admin/setting/currency/currency-yes-no-form/currency-yes-no-form.component';
import { LocationComponent } from './admin/setting/location/location.component';
import { LocationFormComponent } from './admin/setting/location/location-form/location-form.component';
import { LocationYesNoFormComponent } from './admin/setting/location/location-yes-no-form/location-yes-no-form.component';
import { PostComponent } from './user/post/post.component';
import { PostNewComponent } from './user/post/post-new/post-new.component';
 import { EditpostComponent } from './user/post/editpost/editpost.component';

 
import { PostdisplayComponent } from './common/post/postdisplay/postdisplay.component';
import { PostImageDialogComponent } from './common/post/post-image-dialog/post-image-dialog.component';
import { ProfileComponent } from './user/profile/profile.component';
import { PersonalInformationComponent } from './user/profile/personal-information/personal-information.component';
import { ChangeAddressComponent } from './user/profile/change-address/change-address.component';
import { ChangePasswordComponent } from './user/profile/change-password/change-password.component';
import { ChangePhoneComponent } from './user/profile/change-phone/change-phone.component';
import { ChangeProfileImageComponent } from './user/profile/change-profile-image/change-profile-image.component';
import { CatagoryYesNoComponent } from './admin/product-catagory/catagory-yes-no/catagory-yes-no.component';
 
import { EditPostYesNoDialogComponent } from './user/post/editpost/edit-post-yes-no-dialog/edit-post-yes-no-dialog.component';
import { SpecificationheadComponent } from './admin/specificationhead/specificationhead.component';
import { SpecificationheadFormComponent } from './admin/specificationhead/specificationhead-form/specificationhead-form.component';
import { SpecificationheadYesNoDialogComponent } from './admin/specificationhead/specificationhead-yes-no-dialog/specificationhead-yes-no-dialog.component';
import { SpecificationheadOptionComponent } from './admin/specificationhead-option/specificationhead-option.component';
import { SpecificationheadOptionFormComponent } from './admin/specificationhead-option/specificationhead-option-form/specificationhead-option-form.component';
import { SpecificationheadOptionYesNoDialogComponent } from './admin/specificationhead-option/specificationhead-option-yes-no-dialog/specificationhead-option-yes-no-dialog.component';
import { RoleComponent } from './admin/setting/users/role/role.component';
import { RoleYesNoDialogComponent } from './admin/setting/users/role/role-yes-no-dialog/role-yes-no-dialog.component';
import { RoleFormComponent } from './admin/setting/users/role/role-form/role-form.component';
import { MgPostListComponent } from './managment/mg-post-list/mg-post-list.component';
import { MgPostDisplayComponent } from './managment/display/mg-post-display/mg-post-display.component';
 
import { AdminPostlistComponent } from './admin/post_managment/admin-postlist/admin-postlist.component';
import { UserPostDisplayComponent } from './user/post/user-post-display/user-post-display.component';
import { HomepageComponent } from './home/homepage/homepage.component';
import { CatagoryPageComponent } from './home/catagory-page/catagory-page.component';
import { SubcatagoryPageComponent } from './home/subcatagory-page/subcatagory-page.component';
import { UserPageComponent } from './home/user-page/user-page.component';
import { PostDisplayPageComponent } from './home/post-display-page/post-display-page.component';
import { CoracelComponent } from './home/homepage/coracel/coracel.component';
import { HomeCatagoryListComponent } from './home/homepage/home-catagory-list/home-catagory-list.component';
import { HeaderComponent } from './common/header/header.component';
import { SidenavListComponent } from './common/sidenav-list/sidenav-list.component';
import { LatestPostSectionComponent } from './home/homepage/latest-post-section/latest-post-section.component';
import { UserPostListComponent } from './user/post/user-post-list/user-post-list.component';
import { FilterListPipe } from './user/post/filter-list.pipe';
import { DepositeReceiptFormComponent } from './finance/deposite-receipt-form/deposite-receipt-form.component';
import { DepositeReceiptlistComponent } from './finance/deposite-receiptlist/deposite-receiptlist.component';
import { CasherPageComponent } from './finance/casher-page/casher-page.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';

import { AdminFinanceComponent } from './admin/admin-finance/admin-finance.component';
 

import { AdminFinanceReportComponent } from './admin/admin-finance/admin-finance-report/admin-finance-report.component';
import { DepositReciptDetailComponent } from './finance/deposit-recipt-detail/deposit-recipt-detail.component';
import { AdminUserDetailComponent } from './admin/admin-user-detail/admin-user-detail.component';
import { FinanceUserDetailComponent } from './finance/finance-user-detail/finance-user-detail.component';
import { ManagmentUserDetailComponent } from './managment/managment-user-detail/managment-user-detail.component';
import { AdminReceiptListComponent } from './admin/admin-finance/admin-receipt-list/admin-receipt-list.component';
import { AdminReceiptDetailComponent } from './admin/admin-finance/admin-receipt-detail/admin-receipt-detail.component';
import { AdminPostDisplayComponent } from './admin/post_managment/admin-post-display/admin-post-display.component';
import { PaymentComponent } from './user/payment/payment.component';
import { PrivacySettingComponent } from './user/privacy-setting/privacy-setting.component';
import { PaymentListComponent } from './user/payment/payment-list/payment-list.component';
import { SearchPageComponent } from './home/search/search-page/search-page.component';
import { DateAgoPipe } from './utility/pipes/date-ago.pipe';
import { ConfirmationDialogComponent } from './utility/confirmation-dialog/confirmation-dialog.component';
import { MessageDialogComponent } from './utility/message-dialog/message-dialog.component';
import { AbuseDialogComponent } from './utility/abuse-dialog/abuse-dialog.component';
import { LoginDialogComponent } from './utility/login-dialog/login-dialog.component';
import { PostManagmentComponent } from './admin/post_managment/post-managment.component';
import { PostFraudComponent } from './admin/post_managment/post-fraud/post-fraud.component';
import { PostFraudDisplayComponent } from './admin/post_managment/post-fraud-display/post-fraud-display.component';
 
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { SystemInfoListComponent } from './admin/setting/system-info-list/system-info-list.component';
import { SystemInfoFormComponent } from './admin/setting/system-info-list/system-info-form/system-info-form.component';
import { SearchFormComponent } from './utility/search-form/search-form.component';
 
import { SupportComponent } from './missillenious/support/support.component';
import { ContactComponent } from './missillenious/contact/contact.component';
import { PrivacyComponent } from './missillenious/privacy/privacy.component';
 
import { TermsOfServiceComponent } from './missillenious/terms-of-service/terms-of-service.component';
import { HowItWorkComponent } from './missillenious/how-it-work/how-it-work.component';
import { AdvertisePolicyComponent } from './missillenious/advertise-policy/advertise-policy.component';
 
import { Demo2Component } from './demo/demo2/demo2.component';
 
import { PrepostComponent } from './user/post/prepost/prepost.component';
import { ReceiptDetailDialogComponent } from './user/payment/receipt-detail-dialog/receipt-detail-dialog.component';
import { PostDetailDialogComponent } from './user/payment/post-detail-dialog/post-detail-dialog.component';
import { FaqComponent } from './missillenious/faq/faq.component';
import { DisableOptionPipe } from './utility/pipes/disable-option.pipe';
import { ManageNotificationComponent } from './user/privacy-setting/settting-form/manage-notification/manage-notification.component';
 
import { NgSpinnerComponent } from './utility/ng-spinner/ng-spinner.component';
import { Demo1Component } from './demo/demo1/demo1.component';
import { UserPostListResponsiveComponent } from './user/post/user-post-list/user-post-list-responsive/user-post-list-responsive.component';
import { NoAdsFoundComponent } from './home/no-ads-found/no-ads-found.component';
import { ActivatUserAccountDialogComponent } from './admin/setting/activat-user-account-dialog/activat-user-account-dialog.component';
import { VersionComponent } from './missillenious/version/version.component';
 
  import { UserSendEmailDialogComponent } from './admin/setting/users/user-send-email-dialog/user-send-email-dialog.component';
import { SentEmailListComponent } from './admin/setting/sent-email-list/sent-email-list.component';
import { SentEmailDetailDialogComponent } from './admin/setting/sent-email-list/sent-email-detail-dialog/sent-email-detail-dialog.component';
import { SettingRightPanelListComponent } from './admin/setting/setting-right-panel-list/setting-right-panel-list.component';
import { AdminPostRightPanelComponent } from './admin/post_managment/admin-post-right-panel/admin-post-right-panel.component';
import { GoogleLoginProvider, GoogleSigninButtonModule, SocialAuthServiceConfig, SocialLoginModule } from '@abacritt/angularx-social-login';
import { SocialLoginComponent } from './common/social-login/social-login.component';
import { LoddingSpinnerComponent } from './utility/lodding-spinner/lodding-spinner.component';
import { UserPagePanelComponent } from './user/user-page-panel/user-page-panel.component';
import { RightPanelComponent } from './user/right-panel/right-panel.component';
import { CustomDatePipe } from './utility/pipes/custom-date-pipe.pipe';
import { AboutUsComponent } from './missillenious/about-us/about-us.component';
 
import { CkeditorComponent } from './utility/ckeditor/ckeditor.component';
import { RouterModule } from '@angular/router';
 
 
  

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidenavListComponent,
    ActivateComponent,

    ErrorComponent,
    FooterComponent,
    ForgotpasswordComponent,
    HomeComponent,
    LoginComponent,
    LogoutComponent,
    NewpasswordComponent,
    RegisterComponent,
    UsersComponent,
    ProductCatagoryComponent,
    ProductSubCatagoryComponent,
    CatagoryFormComponent,
   
    SubCatagoryFormComponent,
    SubCatagoryYesNoComponent,
    UserFormComponent,
    UserYesNoDialogComponent,
    SettingComponent,
    LocationComponent,
    LocationFormComponent,
    LocationYesNoFormComponent,
    CurrencyComponent,
    CurrencyFormComponent,
    CurrencyYesNoFormComponent,
    PostComponent,
    PostNewComponent,
     EditpostComponent,
    PostImageDialogComponent,
    PostdisplayComponent,
    ProfileComponent,
    PersonalInformationComponent,
    ChangeAddressComponent,
    ChangePasswordComponent,
    ChangePhoneComponent,
    ChangeProfileImageComponent,
    CatagoryYesNoComponent,
    EditPostYesNoDialogComponent,
    SpecificationheadComponent,
    SpecificationheadFormComponent,
    SpecificationheadYesNoDialogComponent,
    SpecificationheadOptionComponent,
    SpecificationheadOptionFormComponent,
    SpecificationheadOptionYesNoDialogComponent,
    RoleComponent,
    RoleYesNoDialogComponent,
    RoleFormComponent,
    MgPostListComponent,
    MgPostDisplayComponent,
    AdminPostDisplayComponent,
    AdminPostlistComponent,
    UserPostDisplayComponent,
    HomepageComponent,
    CatagoryPageComponent,
    SubcatagoryPageComponent,
    UserPageComponent,
    PostDisplayPageComponent,
    CoracelComponent,
    HomeCatagoryListComponent,
    LatestPostSectionComponent,
    UserPostListComponent,
    FilterListPipe,
    DepositeReceiptFormComponent,
    DepositeReceiptlistComponent,
    CasherPageComponent,

    AdminFinanceComponent,
    
    AdminFinanceReportComponent,
    DepositReciptDetailComponent,
    AdminUserDetailComponent,
    FinanceUserDetailComponent,
    ManagmentUserDetailComponent,
    AdminReceiptListComponent,
    AdminReceiptDetailComponent,
    PaymentComponent,
    PrivacySettingComponent,
    PaymentListComponent,
    SearchPageComponent,
    DateAgoPipe,
    ConfirmationDialogComponent,
    MessageDialogComponent,
    AbuseDialogComponent,
    LoginDialogComponent,
    PostManagmentComponent,
    PostFraudComponent,
    PostFraudDisplayComponent,
    SystemInfoListComponent,
    SystemInfoFormComponent,
    SearchFormComponent,
    SupportComponent,
    ContactComponent,
    PrivacyComponent,
    TermsOfServiceComponent,
    HowItWorkComponent,
    AdvertisePolicyComponent,
  Demo1Component,
    Demo2Component,
    PrepostComponent,
    ReceiptDetailDialogComponent,
    PostDetailDialogComponent,
    FaqComponent,
    DisableOptionPipe,
    ManageNotificationComponent,
    NgSpinnerComponent,
    UserPostListResponsiveComponent,
    NoAdsFoundComponent,
    ActivatUserAccountDialogComponent,
    VersionComponent,
    UserSendEmailDialogComponent,
    SentEmailListComponent,
    SentEmailDetailDialogComponent,
    SettingRightPanelListComponent,
    AdminPostRightPanelComponent,
    SocialLoginComponent,
    LoddingSpinnerComponent,
    UserPagePanelComponent,
    RightPanelComponent ,
    CustomDatePipe,
    AboutUsComponent 
   
 
   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
      HttpClientModule,
        ReactiveFormsModule,
    MatSelectModule,
    MatFormFieldModule,
    // NgxMatSelectSearchModule,
        BrowserAnimationsModule,
        SocialLoginModule,
        GoogleSigninButtonModule,
        

  
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          // below for local host
          // {
          //   id: GoogleLoginProvider.PROVIDER_ID,
          //   provider: new GoogleLoginProvider(
          //     '533846012610-6d33ivmomcvctsjdip4iqqa4hfc6jfdi.apps.googleusercontent.com'  
          //   ),
            
          // }
           // end of local host
           //below  for meazn.com  google credential
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '712442122013-a3l8bsg9afb7adtnu3nfodcfvm11sn6v.apps.googleusercontent.com'  
            ),
            
          }
          //end for meazn.com  google credential
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    },
    {provide: HTTP_INTERCEPTORS, useClass: HttpIntercepterService, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
