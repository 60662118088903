<!-- 
<mat-card>
    <h1>CONTACT US</h1>
    <hr />
    <div class="content">
   <p>suqtayi customer support team is always ready to answer your questions and provide all the necessary assistance. </p>
 <p> suqtayi.com customer care department - you can email your questions, suggestions, and comments at meazn.ss&#64;gmail.com.</p>
  <p><strong>Check our Frequently Asked Questions  <a routerLink="/faq">FAQ</a></strong> </p>





        </div>
</mat-card> -->


<mat-card>
  
    <div class="content">
      <h1>&nbsp;ብዛዕባና</h1>
      <h2>&nbsp;እንቋዕ ናብ &ldquo;መኣዝን&rdquo; ብደሓን መጻእኩም!</h2>
      <h4>&nbsp;ዓደግትን ሸየጥትን ብዘይ ገለ ጸገምን ስሉጥን መንገዲ ኣብ ሓደ ንምምጻእ ዝተዳለወ መድረኽ ኢዩ። ንተጠቃሚ ምቹእ ቦታ ንምፍጣር ካብ ዝነበረ ፍቕሪ ዝተወልደ መርበብ ሓበሬታና፡ ውጽኢት ናይ ሓያል &nbsp;ጻዕርን ምህዞን እዩ። ተልእኾና ንዝኾነ ቋንቋ ትግርኛ ንዝመልኽ ሰብ ኣቑሑትን ኣገልግሎታትን ክገዝእ፣ ክሸይጥ፣ ወይ ክነግድ ዝደሊ ዘተኣማምንን ማእከል ሓበሬታ ምድላው ኢዩ ።</h4>
      <h2>&nbsp;ነቅርቦ ኣገልግሎት</h2>
      <ul>
      <li>
      <h3>ዝሽየጥ ንብረት ሓዲሽ ይኹን ወይ ክትጥቀመሉ ዝጸናሕካ ክትሸጦ ምስ ትደሊ ዓዳጊ ንምርካብ ዝሕግዝ ባይታ ኢዩ ።</h3>
      </li>
      <li>
      <h3>ገዛኢ ምስ ትኸውን ውን ክትገዝኦ ዝደለኻን ካብ ገዛኻ ከይወጸኻ ክትረኽቦ ትኽእል</h3>
      </li>
      <li>
      <h3>በዓል ሞያ ምስ ትኸውን ሞያኻ ዝደልዩ ሰባት ከምዘለዉ ርዱእ ኮይኑ ንዓኦም ንምርካብ &hellip;</h3>
      </li>
      </ul>
      <h3>&nbsp;ኣብ መኣዝን ምዕዳግ፡ ምሻጥን ንግዲን ብዝተኻእለ መጠን ብዝቀለለ ንምግባሩ ንጽዕር። &nbsp;</h3>
      <h3>&nbsp;መርበብ ሓበሬታና ክትድህስሱን ምስ ካልኦት ምትእስሳር ክሳብ ክንደይ ቀሊል ምዃኑ ክትዕዘቡን ንዕድም። ምኩር ሸያጢ ትኹን ወይ ንመጀመርታ ግዜ ተውዓውዕ፡ &nbsp;ኣብ ነፍሲ ወከፍ ስጉምቲ ክንሕግዘካ ባህታ ይፈጥረልና ። &nbsp;</h3>
      <h3>&nbsp;ክትረኽቡና ምስ እትደልዩ</h3>
      
      <h3> <i class="fas fa-phone"></i>&nbsp;&nbsp;&nbsp; : <a href="tel:+211981400409">+211981400409 </a></h3>
  
      <h3><i class="far fa-envelope"></i> &nbsp; ፥ <a href="mailto:meazn.ss@gmail.com">meazn.ss&#64;gmail.com</a></h3>
      
      <h3> <i class="fab fa-whatsapp"></i>&nbsp;&nbsp;&nbsp; : <a href="https://wa.me/message/3ME2SYIJCIYSE1">+211981400409 </a></h3>
  
        </div>
</mat-card>
