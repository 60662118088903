<div class="dialog-title">
    <h3>{{title}}</h3>
  </div>
  <div class="dialog-body">
    <P>{{message}}</P>
  </div>
  <div class="dialog-button">
    
    <button class="btn-save"  mat-button  color="primary" (click)="onOk()">OK</button>
  </div>
