<mat-drawer-container class="wrapper2" hasBackdrop="">
  <mat-drawer #drawer mode="over">
    <div class="left-side-draw">

      <div class="left-side" *ngIf="!searchNotFound">
        <div class="cat-sub-list">
        </div>
      </div>
    </div>
  </mat-drawer>
  <mat-drawer-content>

    <div class="wrapper"  >

      <div class="left-side" *ngIf="!searchNotFound">
        <div class="cat-sub-list">
        </div>
      </div>
      <div class="right-side" *ngIf="!searchNotFound">

        <!-- -->
        <div class="card-list">
          <div class="product-card  " class="product-card  " *ngFor="let p of posts  | async  ">
            <div class="product-image">
              <a routerLink="/display" [queryParams]="{pid:p.id}"> <img [src]="getImageUrl(p)" alt=""></a>
              <div class="image-number "> <i class="fas fas fa-camera"></i> {{displayImangeNumber(p)}} </div>

            </div>
            <div class="product-data">
              <div class="price-tag">{{displayPrice(p)}}</div>
              <div class="post-title"><a routerLink="/display" [queryParams]="{pid:p.id}">
                  <p>{{p.description}} </p>
                </a></div>
            </div>
          </div>





        </div>
        <div class="paginator">
          <mat-paginator [pageSizeOptions]="[10]"></mat-paginator>
        </div>

      </div>
      <div class="right-side flex_row_center_center" *ngIf="searchNotFound">
        <h2 i18n>Opps No Result was found !!!!</h2>
      </div>

    </div>

  </mat-drawer-content>
</mat-drawer-container>