<mat-card>
    <h1> Privacy  Policy</h1>
    <hr>
    <div class="content">

<p>This privacy policy is for this website [suqtayi.com] and served by suqtayi.com and governs the privacy of its
    users who choose to use it.</p>
<p>The policy sets out the different areas where user privacy is concerned and outlines the obligations &amp;
    requirements of the users, suqtayi.com and website owners. Furthermore the way suqtayi.com processes, stores
    and protects user data and information will also be detailed within this policy.</p>
<p><strong>The Website</strong></p>
<p>suqtayi.com and its owners take a proactive approach to user privacy and ensure the necessary steps are taken to
    protect the privacy of its users throughout their visiting experience. suqtayi.com complies with the laws of the
    Federation of Nigeria and requirements for user privacy.</p>
<p><strong>Use of Cookies</strong></p>
<p>suqtayi.com uses cookies to better the users experience while visiting the website. Where applicable
    suqtayi.com uses a cookie control system allowing the user on their first visit to the website to allow or
    disallow the use of cookies on their computer / device. This complies with recent legislation requirements for
    websites to obtain explicit consent from users before leaving behind or reading files such as cookies on a user’s
    computer / device.</p>
<p>Cookies are small files saved to the user’s computer’s hard drive that track, save and store information about the
    user’s interactions and usage of the website. This allows the website, through its server to provide the users with
    a tailored experience within this website.</p>
<p>Users are advised that if they wish to deny the use and saving of cookies from this website on to their computers
    hard drive they should take necessary steps within their web browsers security settings to block all cookies from
    this website and its external serving vendors.</p>
<p>suqtayi.com uses tracking software to monitor its visitors to better understand how they use it. This software is
    provided by Google Analytics which uses cookies to track visitor usage. The software will save a cookie to your
    computer’s hard drive in order to track and monitor your engagement and usage of the website, but will not store,
    save or collect personal information.&nbsp;</p>
<p>Other cookies may be stored to your computer’s hard drive by external vendors when this website uses referral
    programs, sponsored links or adverts. Such cookies are used for conversion and referral tracking and typically
    expire after 30 days, though some may take longer. No personal information is stored, saved or collected.</p>
<p><strong>Contact &amp; Communication</strong></p>
<p>Users contacting suqtayi.com website and/or its owners do so at their own discretion and provide any such personal
    details requested at their own risk. Your personal information is kept private and stored securely until a time it
    is no longer required or has no use, as detailed in the Data Protection Act 1998. Every effort has been made to
    ensure a safe and secure form to email submission process but advise users using such form to email processes that
    they do so at their own risk.</p>
<p>suqtayi.com and its owners use any information submitted to provide you with further information about the
    products / services they offer or to assist you in answering any questions or queries you may have submitted. This
    includes using your details to subscribe you to any email newsletter program the website operates but only if this
    was made clear to you and your express permission was granted when submitting any form to email process. Or whereby
    you the consumer have previously purchased from or enquired about purchasing from the company a product or service
    that the email newsletter relates to. This is by no means an entire list of your user rights in regard to receiving
    email marketing material. Your details are not passed on to any third parties.</p>
<p><strong>Security</strong></p>
<p>suqtayi.com takes precautions to protect users’ information. When you submit sensitive information via this
    website, your information is protected both online and offline.</p>
<p>Wherever we collect sensitive information (such as credit card data), that information is encrypted and transmitted
    to us in a secure way. You can verify this by looking for a closed lock icon at the bottom of your web browser, or
    looking for “https” at the beginning of the address of the web page.</p>
<p>While we use encryption to protect sensitive information transmitted online, we also protect your information
    offline. Only employees who need the information to perform a specific job (for example, billing or customer
    service) are granted access to personally identifiable information. The computers/servers in which we store
    personally identifiable information are kept in a secure environment.</p>
<p><strong>Email Newsletter</strong></p>
<p>suqtayi.com operates an email newsletter program, used to inform subscribers about products and services supplied
    by suqtayi.com. Users can subscribe through an online automated process should they wish to do so but do so at
    their own discretion. Some subscriptions may be manually processed through prior written agreement with the user.
</p>
<p>Subscriptions are taken in compliance with Anti-spam laws of the Federal Republic of Nigeria. All personal details
    relating to subscriptions are held securely and in accordance with the Data Protection Act 1998. No personal details
    are passed on to third parties nor shared with companies / people outside of suqtayi.com. Under the Data
    Protection Act 1998 you may request a copy of personal information held about you by this website’s email newsletter
    program. A small fee will be payable. If you would like a copy of the information held on you please contact us at
    hello[&#64;]suqtayi.com</p>
<p>Email marketing campaigns published by suqtayi.com may contain tracking facilities within the actual email.
    Subscriber activity is tracked and stored in a database for future analysis and evaluation. Such tracked activity
    may include; the opening of emails, forwarding of emails, the clicking of links within the email content, times,
    dates and frequency of activity.</p>
<p>This information is used to refine future email campaigns and supply the user with more relevant content based around
    their activity.</p>
<p>In compliance with the Anti-Spam Laws and the Privacy and Electronic Communications Regulations 2003 subscribers are
    given the opportunity to un-subscribe at any time through an automated system. This process is detailed at the
    footer of each email campaign. If an automated un-subscription system is unavailable clear instructions on how to
    un-subscribe will by detailed instead.</p>
<p><strong>External Links</strong></p>
<p>Although this website only looks to include quality, safe and relevant external links, users are advised adopt a
    policy of caution before clicking any external web links mentioned throughout this website.</p>
<p>suqtayi.com cannot guarantee or verify the contents of any externally linked website despite her best efforts.
    Users should therefore note they click on external links at their own risk and suqtayi.com and its owners cannot
    be held liable for any damages or implications caused by visiting any external links mentioned.</p>
<p><strong>Adverts and Sponsored Links</strong></p>
<p>This website may contain sponsored links and adverts. These will typically be served through our advertising
    partners, to whom may have detailed privacy policies relating directly to the adverts they serve.</p>
<p>Clicking on any such adverts will send you to the advertisers website through a referral program which may use
    cookies and will track the number of referrals sent from this website. This may include the use of cookies which may
    in turn be saved on your computer’s hard drive. Users should therefore note they click on sponsored external links
    at their own risk and suqtayi.com and its owners cannot be held liable for any damages or implications caused by
    visiting any external links mentioned.</p>
<p><strong>Social Media Platforms</strong></p>
<p>Communication, engagement and actions taken through external social media platforms that suqtayi.com and its
    owners participate on are custom to the terms and conditions as well as the privacy policies held with each social
    media platform respectively.</p>
<p>Users are advised to use social media platforms wisely and communicate / engage upon them with due care and caution
    in regard to their own privacy and personal details. suqtayi.com nor its owners will never ask for personal or
    sensitive information through social media platforms and encourage users wishing to discuss sensitive details to
    contact them through primary communication channels such as by telephone or email.</p>
<p>suqtayi.com may use social sharing buttons which help share web content directly from web pages to the social
    media platform in question. Users are advised before using such social sharing buttons that they do so at their own
    discretion and note that the social media platform may track and save your request to share a web page respectively
    through your social media platform account.</p>
<p><strong>Shortened Links in Social Media</strong></p>
<p>This website and its owners through their social media platform accounts may share web links to relevant web pages.
    By default some social media platforms shorten lengthy urls [web addresses] (this is an example: http://bit.ly).</p>
<p>Users are advised to take caution and good judgement before clicking any shortened urls published on social media
    platforms by this website and its owners. Despite the best efforts to ensure only genuine urls are published many
    social media platforms are prone to spam and hacking and therefore suqtayi.com and its owners cannot be held
    liable for any damages or implications caused by visiting any shortened links.</p>
<p>Our Privacy Policy may change from time to time and all updates will be posted on this page. If you feel that we are
    not abiding by this privacy policy, you should contact us immediately.</p>

</div>
</mat-card>