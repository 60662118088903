<div class="wrapper">
    <div class="notice-bord  " *ngIf="this.post.post_status.status=='ERROR'">
        <p>{{this.post.post_status.rejectionReason}}</p>
        <p>And Apply agian!!!</p>
    </div>
    <div class="notice-bord  " *ngIf="this.post.post_status.status=='DISABLED'">
        <p>Post Disabled By Admin !!!</p>
    </div>
    <div class="notice-bord  " *ngIf="this.post.post_status.status=='PENDING'">
        <p>Post is waiting for Evaluation !!!</p>
    </div>
    <div class="detail-left ">
        <div class="main-image ">
            <img src="{{imagePath}} " alt=" ">
            
            <div class="image-number ">
                <p>
                    <mat-icon>camera_alt</mat-icon>{{getIndex_lenth()}}</p>
            </div>
            <a class="prev2 " (click)="preveousSlides() ">
                <mat-icon>chevron_left</mat-icon>
            </a>
            <a class="next2 " (click)="nextSlides() ">
                <mat-icon>chevron_right</mat-icon>
            </a>

        </div>

        <div class="product-info ">

            <div class="post-date ">
                <p>
                    <mat-icon>watch_later</mat-icon> Posted {{post.post_status.apply_date|date}}</p>
                <p>
                    <mat-icon>location_on</mat-icon> {{post.salesLocation.state}}, {{post.salesLocation.city}} </p>
                <p>
                    <mat-icon>visibility</mat-icon> {{post.view}} views </p>
            </div>
            <div class="price-tag">{{displayPrice()}}<span *ngIf="displayNegotiable()" class="negotiable-tag">(negotiable)</span></div>
            <div class="post-title ">
                <h1 style=" text-transform: capitalize;font-size: 1.8em;font-weight: 500;">{{post.description}}</h1>
            </div>
            
        </div>

            <div class="post-specification">
                <h1>   Specifiation </h1>
                <hr>
                 <div class="specification-table">
                    <table   *ngFor="let sp of post.specificationList"     >
                        <tr  class="table-row">
                          <td class="specificaiton-name" >{{sp.specification.key}}</td>
                        
                          <td class="specification-value"  >{{sp.value}}</td>
                        </tr>
                     </table>
                 </div>
            </div>


            <div class="seller-loction">
                <h1> Detail </h1>
                <hr>
                <div [innerHtml]="post.detail"> </div>
              </div>
          
              <div class="seller-loction">
            <h1> Extra Ads Info </h1>
            <hr>
            <div ><b> Ads Status : </b><i>{{post.post_status.status}}</i></div>
            <div ><b> Apply Date : </b><i>{{post.post_status.apply_date | customDatePipe}}</i></div>
            <div *ngIf="post.post_status.status==='ACTIVE'" ><b> Expire Date : </b><i>{{post.post_status.expire_date | customDatePipe}}</i></div>
        </div>
 
        <div class="decision seller-loction">
            <h1> Diable or Enable Ads</h1>
            <hr>
            <form>
                <div class=" " fxLayout="column " fxLayoutAlign="start start " fxLayoutGap="10px ">

                    <div class=" ">
                        <mat-checkbox class="example-margin " name="post_disable " [(ngModel)]="post_disable ">Disable Post</mat-checkbox>
                    </div>




                    <div class="submit_button " fxLayoutAlign="space-between ">
                        <button class="adv-button" mat-button (click)="submitForm() ">Save</button>

                    </div>
                </div>
            </form>

        </div>
    </div>
    <div class="right-side ">
        <div class="seller-detail ">
            <div class="seller-img ">

                <img [src]="getUserImage() " width="100 " height="100 " alt=" ">
            </div>
            <div class="seller-info ">

                <p>Seller : <a class="link " href="# "> {{post.user.fullName}}</a> </p>

                <p>Phone : <span style="color: #00a651; ">{{post.user.contact.phone}}</span> </p>

            </div>
        </div>



    </div>


</div>