<section fxLayout="row" fxLayoutAlign="start center" style="margin: 20px 20px 20px 20px;">

    <mat-card fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="0px" style="width: 100%;padding-top: 5px;" class="mat-elevation-z8">

        <div class="" fxLayout="row" fxLayoutAlign="center end" style="width: 100%;">
            <mat-card-header>
        <mat-card-title>List Of Deposites</mat-card-title>
    </mat-card-header>
        </div>

        <mat-card-content>
            <div fxLayoutAlign="space-between">
                <mat-form-field fxFlex="30%" appearance="outline">
                    <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Filter" />
                </mat-form-field>
                <div class="date-range">
                    <mat-form-field appearance="outline" >
                        <mat-label>Enter a date range</mat-label>
                        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                          <input matStartDate formControlName="start" placeholder="Start date">
                          <input matEndDate formControlName="end" placeholder="End date">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                      
                        <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                        <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                      </mat-form-field>
                      <button  mat-button (click)="dateRangeFilter()"><i class="fas fa-search " style="color: #00a651;"></i></button>
                </div>
                
               
            </div>

            <mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Id</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.receipt_No }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="customerName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
                    <mat-cell *matCellDef="let element"><a [routerLink]="['/finance/userdetail']" target="_blank" [queryParams]="{uid: element.user.id}" >{{ element.user.fullName }}</a></mat-cell>
                </ng-container>
                <ng-container matColumnDef="Date">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.createdDate | date}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="Amount">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Amount (SSP)</mat-header-cell>
                    <mat-cell *matCellDef="let element" >
                        <p *ngIf="element.receipt_void" style="color:red;">0 SSP</p>
                                <p *ngIf="!element.receipt_void">{{ element.amount | number}} SSP</p>
                                
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="void_receipt">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>void</mat-header-cell>
                    <mat-cell *matCellDef="let element" >
                        <p *ngIf="element.receipt_void" style="color:red;">VOID</p>
                        <p *ngIf="!element.receipt_void"></p>

                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="detail">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Detail</mat-header-cell>
                    <mat-cell *matCellDef="let element" ><span  ><a mat-icon-button (click)="depositDetail(element.id)"><i style="font-size: 1.2em;color: #00a651;" class="far fa-eye "></i></a></span></mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>

           
        </mat-card-content>


        <mat-card-footer fxLayout="row" fxLayoutAlign="space-around space-around">
           <div class="pagginator">
            <mat-paginator [pageSize]="10" [pageSizeOptions]="[1, 5, 10, 20]">
               
            </mat-paginator>
           </div> 
           <div class="total-amount">
            <div class="">total : <span ><b> {{total | number}}</b></span> SSP</div>
           </div>

           
        </mat-card-footer>
    </mat-card>
</section>
