import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-post-right-panel',
  templateUrl: './admin-post-right-panel.component.html',
  styleUrls: ['./admin-post-right-panel.component.css']
})
export class AdminPostRightPanelComponent  implements OnInit {

  page_url:String=""
  index:number=0
  constructor(private route: Router){

  }
  title = 'angular-example';

  ngOnInit(){
    this.page_url=this.route.routerState.snapshot.url
    
  if(this.page_url==="/admin/post/status") {
    this.index=0;
     }else if(this.page_url==="/admin/post/abuse_notification") {
       this.index=1;
     }  else  {
       this.index=0;
       this.route.navigate(["admin/post/status"])
     }
}
 
}
