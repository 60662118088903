 
<section>

   
<div  *ngIf="activated"   class="alert-message">
  
  <p  i18n >Account Activated Successfully !!! </p>
</div>
<div   *ngIf="!activated" class="alert-error">  
 
  <p   >{{errorMessage}} </p>
</div>  
<div class="signin_button">

</div>
<app-login></app-login>
</section> 