import { Injectable } from "@angular/core";

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenStorageService } from '../tokenStorage/token-storage.service';
import { GlobalConstants } from "src/app/utility/global-constants";
import { TokenRefreshResponse } from "src/app/modules/TokenRefreshResponse";
const AUTH_API = GlobalConstants.serverUrl+'adv/auth/social/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: "root"
})
export class SocialAuthentication {
 

  constructor(
    private http: HttpClient,
    private userToken:TokenStorageService
  ) {}

 
 
  loginWithGoogle(signUp): Observable<any> {
    return this.http.post(`${AUTH_API}google`, signUp, httpOptions);
  }
  loginWithFacebook(signUp): Observable<any> {
    return this.http.post(`${AUTH_API}facebook`, signUp, httpOptions);
  }
   
  getCurrentUserId() {
    return this.userToken.getCurrentUserId();
  }
}
 
