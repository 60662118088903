 
<!-- 
<app-header  ></app-header>
    
      <router-outlet    ></router-outlet> 
  

      <app-footer  ></app-footer> -->


      <div class="header"><app-header  ></app-header></div>
      <div class="body"><router-outlet    ></router-outlet></div>
      <div class="footer"><app-footer  ></app-footer></div>
