<section   class="post_list_bg_screen">
<mat-card  class="card_status">
  <div class="search-text">
    <mat-form-field  class="search-text-field" appearance="outline">
      <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Filter" />
  </mat-form-field>
  </div>
  <div class="status-button">
    <button mat-button  (click)="getSourceByStatus('ACTIVE')" style="color:#00a651" >ACTIVE ({{getCount('ACTIVE')}})</button>
    <button mat-button (click)="getSourceByStatus('PENDING')" style="color:rgb(138, 132, 132);" >PENDING ({{getCount('PENDING')}})</button>
    <button mat-button (click)="getSourceByStatus('ERROR')" style="color: rgb(228, 74, 74);" >ERROR ({{getCount('ERROR')}})</button>
    <button mat-button (click)="getSourceByStatus('DISABLED')" style="color:rgb(56, 74, 153);" >DISABLED ({{getCount('DISABLED')}})</button>
    <button mat-button  (click)="getSourceByStatus('ARCHIVE')" style="color: rgb(34, 31, 31);" >ARCHIVE ({{getCount('ARCHIVE')}})</button>
    <button mat-button (click)="getSourcedata()" style="color: rgb(104, 91, 91);" >ALL ({{getCount('ALL')}})</button>
   
  </div>

  </mat-card>
  <mat-card fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="0px">

    <!-- <div class=""    >
      <mat-card-header   >
        
          <mat-card-title>My Ads</mat-card-title>
        
           
        
           
        </mat-card-header>
      </div> -->

    <mat-card-content>
      <!-- <div fxLayoutAlign="space-between">
              <mat-form-field fxFlex="30%" >
                <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Filter" />
              </mat-form-field>
               
            </div> -->

      <mat-table  [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="id" class="mobile-id-label" >
                <mat-header-cell *matHeaderCellDef mat-sort-header >No</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <span class="mobile-label">No</span>
                  {{ element.id }}</mat-cell>
              </ng-container>  <section fxLayout="column" fxLayoutAlign="start center">
                <mat-card  class="card_status">
                  <div class="search-text">
                    <mat-form-field  class="search-text-field" appearance="outline">
                      <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Filter" />
                  </mat-form-field>
                  </div>
                  <div class="status-button">
                    <button mat-button  (click)="getSourceByStatus('ACTIVE')" style="color:#00a651" >ACTIVE ({{getCount('ACTIVE')}})</button>
                    <button mat-button (click)="getSourceByStatus('PENDING')" style="color:rgb(138, 132, 132);" >PENDING ({{getCount('PENDING')}})</button>
                    <button mat-button (click)="getSourceByStatus('ERROR')" style="color: rgb(228, 74, 74);" >ERROR ({{getCount('ERROR')}})</button>
                    <button mat-button (click)="getSourceByStatus('DISABLED')" style="color:rgb(56, 74, 153);" >DISABLED ({{getCount('DISABLED')}})</button>
                    <button mat-button  (click)="getSourceByStatus('ARCHIVE')" style="color: rgb(34, 31, 31);" >ARCHIVE ({{getCount('ARCHIVE')}})</button>
                    <button mat-button (click)="getSourcedata()" style="color: rgb(104, 91, 91);" >ALL ({{getCount('ALL')}})</button>
                   
                  </div>
                
                  </mat-card>
                  <mat-card fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="0px">
                
                    <!-- <div class=""    >
                      <mat-card-header   >
                        
                          <mat-card-title>My Ads</mat-card-title>
                        
                           
                        
                           
                        </mat-card-header>
                      </div> -->
                
                    <mat-card-content>
                      <!-- <div fxLayoutAlign="space-between">
                              <mat-form-field fxFlex="30%" >
                                <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Filter" />
                              </mat-form-field>
                               
                            </div> -->
                
                      <table mat-table [dataSource]="dataSource" matSort>
                        <ng-container matColumnDef="id" class="mobile-id-label" >
                                <mat-header-cell *matHeaderCellDef mat-sort-header >No</mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                  <span class="mobile-label">No</span>
                                  {{ element.id }}</mat-cell>
                              </ng-container>  
                        <ng-container matColumnDef="img">
                          <mat-header-cell *matHeaderCellDef></mat-header-cell>
                          <mat-cell *matCellDef="let element">
                            <span class="mobile-label">  </span>
                              <img src="{{getImage(element)}}" width="100" height="100" alt="">
                          
                            
                
                          </mat-cell>
                        </ng-container>
                      <ng-container matColumnDef="title">
                          <mat-header-cell *matHeaderCellDef  mat-sort-header >Title</mat-header-cell>
                          <mat-cell *matCellDef="let element">
                             
                              <span class="mobile-label">Title </span>
                                <p> {{ element.description }} </p>
                             
                            
                            
                          </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="catagory">
                          <mat-header-cell *matHeaderCellDef  mat-sort-header>Catagory</mat-header-cell>
                          <mat-cell *matCellDef="let element">
                            <span class="mobile-label">Catagory </span>
                            <p style="font-weight: 600;"> {{element.productSubCatagory.name}}</p>
                           </mat-cell>
                
                        </ng-container>
                        <ng-container matColumnDef="Status">
                          <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
                          <mat-cell *matCellDef="let element">
                            <span class="mobile-label">Status</span>
                            <p class="status" *ngIf="getStatus(element)=='PENDING'"  style="background-color:rgb(138, 132, 132);">PENDING</p>
                            <p class="status" *ngIf="getStatus(element)=='ACTIVE'" style="background-color:#00a651">ACTIVE</p>
                            <p class="status" *ngIf="getStatus(element)=='ERROR'" style="background-color: rgb(228, 74, 74);">ERROR</p>
                            <p class="status" *ngIf="getStatus(element)=='DISABLED'" style="background-color:rgb(241, 238, 238);">DISABLED</p>
                             <p class="status" *ngIf="getStatus(element)=='ARCHIVE'" style="background-color: rgb(34, 31, 31);color: white;">ARCHIVE</p>
                          </mat-cell>
                        </ng-container>
                
                        <ng-container matColumnDef="price">
                          <mat-header-cell *matHeaderCellDef>Price</mat-header-cell>
                          <mat-cell *matCellDef="let element">
                            <span class="mobile-label"> Price </span>
                            <p style="font-weight: 600;">{{getPrice(element) }}</p>
                            
                          </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="action">
                          <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
                          <mat-cell *matCellDef="let element">
                            <span class="mobile-label"> Action</span>
                            <div class="post_action">
                              <a routerLink="/user/postdisplay" [queryParams]="{pid:element.id}" class="display"  matTooltipPosition="above" matTooltip="display"><i class="  fas  fa-eye"  ></i></a>
                              <button mat-button (click)="onArchive(element.id)" *ngIf="showArchiveButton(element)"  class="archive"  matTooltipPosition="above"  matTooltip="Archive"><i class="fas  fa-archive"></i></button>
                              <a  *ngIf="showEditButton(element)"  routerLink="/user/editpost" [queryParams]="{pid:element.id}" class="edit"  matTooltipPosition="above"  matTooltip="Edit"><i class="fas fa-pencil-alt"></i></a>
                              <button mat-button *ngIf="showDeleteButton(element)"  (click)="confirmDelete(element.id)" class="delete"   matTooltipPosition="above" matTooltip="delete"><i class="fas fa-trash-alt"></i></button>
                           <!-- <button mat-button (click)="confirmDelete()">Action</button> -->
                              </div>
                
                          </mat-cell>
                        </ng-container>
                
                
                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                      </table>
                      <mat-paginator [length]="100"
                      [pageSize]="25"
                      [pageSizeOptions]="[10, 25, 50]"
                      aria-label="Select page">
        </mat-paginator>
                    </mat-card-content>
                
                
                    <mat-card-footer>
                    </mat-card-footer>
                  </mat-card>
                </section>
        <ng-container matColumnDef="img">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span class="mobile-label">  </span>
              <img src="{{getImage(element)}}" width="100" height="100" alt="">
          
            

          </mat-cell>
        </ng-container>
      <ng-container matColumnDef="title">
          <mat-header-cell *matHeaderCellDef  mat-sort-header >Title</mat-header-cell>
          <mat-cell *matCellDef="let element">
             
              <span class="mobile-label">Title </span>
                <p> {{ element.description }} </p>
             
            
            
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="catagory">
          <mat-header-cell *matHeaderCellDef  mat-sort-header>Catagory</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span class="mobile-label">Catagory </span>
            <p style="font-weight: 600;"> {{element.productSubCatagory.name}}</p>
           </mat-cell>

        </ng-container>
        <ng-container matColumnDef="Status">
          <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span class="mobile-label">Status</span>
            <p class="status" *ngIf="getStatus(element)=='PENDING'"  style="background-color:rgb(138, 132, 132);">PENDING</p>
            <p class="status" *ngIf="getStatus(element)=='ACTIVE'" style="background-color:#00a651">ACTIVE</p>
            <p class="status" *ngIf="getStatus(element)=='ERROR'" style="background-color: rgb(228, 74, 74);">ERROR</p>
            <p class="status" *ngIf="getStatus(element)=='DISABLED'" style="background-color:rgb(241, 238, 238);">DISABLED</p>
             <p class="status" *ngIf="getStatus(element)=='ARCHIVE'" style="background-color: rgb(34, 31, 31);color: white;">ARCHIVE</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="price">
          <mat-header-cell *matHeaderCellDef>Price</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span class="mobile-label"> Price </span>
            <p style="font-weight: 600;">{{getPrice(element) }}</p>
            
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span class="mobile-label"> Action</span>
            <div class="post_action">
              <a mat-icon-button routerLink="/user/postdisplay" [queryParams]="{pid:element.id}" target="_blank" class="display"  matTooltipPosition="above" matTooltip="display"><i class="  fas  fa-eye"  ></i></a>
              <button mat-icon-button (click)="onArchive(element.id)" *ngIf="showArchiveButton(element)"  class="archive"  matTooltipPosition="above"  matTooltip="Archive"><i class="fas  fa-archive"></i></button>
              <a  mat-icon-button *ngIf="showEditButton(element)"  routerLink="/user/editpost" [queryParams]="{pid:element.id}" class="edit"  matTooltipPosition="above"  matTooltip="Edit"><i class="fas fas fa-pencil-alt"></i></a>
              <button mat-icon-button *ngIf="showDeleteButton(element)"  (click)="confirmDelete(element.id)" class="delete"   matTooltipPosition="above" matTooltip="delete"><i class="fas fa-trash-alt"></i></button>
           <!-- <button mat-button (click)="confirmDelete()">Action</button> -->
              </div>

          </mat-cell>
        </ng-container>


        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator [length]="100"
      [pageSize]="25"
      [pageSizeOptions]="[10, 25, 50]"
      aria-label="Select page">
</mat-paginator>
    </mat-card-content>


    <mat-card-footer>
    </mat-card-footer>
  </mat-card>
</section>

<section class="post_list_sm_screen" style="  background-color: #d6d6d6;">
  <app-user-post-list-responsive></app-user-post-list-responsive>
</section>