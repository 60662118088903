 

 
 <mat-card   >
  

     <mat-card-content>
        <form (ngSubmit)="onSubmit()" [formGroup]="form">
            <div   class="form_item"  >
            <mat-form-field  appearance="outline">
                <mat-label i18n>Full User Name</mat-label>
                <input matInput type="text" formControlName="fullname"  required>
              </mat-form-field>

              <mat-form-field  appearance="outline">
                <mat-label  i18n>Email</mat-label>
                <input matInput type="email" formControlName="email" readonly="true" required>
              </mat-form-field>
            
              <div class="action">
                <button class="adv-button" mat-raised-button color="primary" type="submit"    [disabled]="!form.valid" i18n>Save Change</button> 
             
              </div>
              </div>
        </form>
     </mat-card-content>
     <mat-card-actions align="start">
        
         
     </mat-card-actions>
  
 </mat-card>

