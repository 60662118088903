import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Post_Fraud } from 'src/app/modules/Post_Fraud';
import { PostFraudService } from 'src/app/service/post/PostFraudService';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ConfirmDialogModel } from '../ConfirmDialogModel';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';

@Component({
  selector: 'app-abuse-dialog',
  templateUrl: './abuse-dialog.component.html',
  styleUrls: ['./abuse-dialog.component.css']
})
export class AbuseDialogComponent implements OnInit {
 postId
 reason
      constructor(public dialogRef: MatDialogRef<AbuseDialogComponent>,
        private   postfraudService:PostFraudService,
        public dialog: MatDialog,
      @Inject(MAT_DIALOG_DATA) public pid: number
        ) {
     this.postId=this.pid
       
      }
    
      ngOnInit() {

      }
    
      onConfirm(): void {
        console.log("reason ==>"+this.reason);
       var fraud=new Post_Fraud(-1,null,null,this.reason);
        this.postfraudService.setPost_Fraud(fraud,this.pid).subscribe(
          r=>{
            this.messageDialog("Message","Thank you For alerting us we will do our investigation!!!")
             
          },e=>{
            this.messageDialog("Error","Notification failled to  be saved !!!")
          }
        );
        
         this.dialogRef.close(true);
      }
    
      onDismiss(): void {
        // Close the dialog, return false
        this.dialogRef.close(false);
      }
     
      messageDialog(title,message){ 
  

        const dialogData = new ConfirmDialogModel(title, message);
      
        const dialogRef = this.dialog.open(MessageDialogComponent, {
          maxWidth: "400px",
          data: dialogData
        });
      
 
        }
    }
    
    