import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
 
import { Post } from '../../modules/Post';
import { TokenStorageService } from '../tokenStorage/token-storage.service';
import { GlobalConstants } from 'src/app/utility/global-constants';
import { PostRecipt } from 'src/app/modules/PostRecipt';
 
 
const AUTH_API = GlobalConstants.serverUrl+'adv/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class PostReceiptService {
  
  constructor(private http: HttpClient,
              private  tokenStorage: TokenStorageService) { }
 
 
 
          getPostReceiptByReceiptNumber(receipt_number: number) {
    return this.http.get<PostRecipt>(`${AUTH_API}postreceipt/${receipt_number}`, httpOptions);
  }
   
}
