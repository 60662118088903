<section style="padding-top: 40px;padding-bottom: 10px;"   class="flex_row_center_center">
  
  
  <app-social-login *ngIf="login_method_social"  (social_login)="socialLogin()"></app-social-login  >
  
    <mat-card   class="mat-elevation-z0" *ngIf="!login_method_social" >
    <form     [formGroup]="loginForm"
      (ngSubmit)="onSubmit()">
      
        <mat-card-title i18n>
          LogIn Form
        </mat-card-title>
 

      <mat-card-content  style="margin: 10px 5px 5px 5px;" >


        <mat-form-field  appearance="outline"  > 
          <mat-label i18n>Email </mat-label>
          <input type="text" matInput    formControlName="email">
      
          <mat-error  i18n>Invalid or missing email.</mat-error>
        </mat-form-field>
        <mat-form-field   appearance="outline">
          <mat-label i18n>Password</mat-label>
          <input type="password" matInput placeholder="Your password" minlength="6" formControlName="password">
          <mat-hint i18n>Please enter your password.</mat-hint>
          <mat-error i18n>minimum 6 characters.</mat-error>
        </mat-form-field>


      </mat-card-content>
      <mat-card-actions align="start">
        <button class="adv-button" type="submit" mat-raised-button color="primary" [disabled]="loginForm.invalid">Submit</button>


      </mat-card-actions>
      <mat-card-footer     >
     
          <a routerLink="/register"  i18n>   Sign Up </a> 
           <a routerLink="/forgotpassword"   i18n >   Forgot Password  </a> 
          
        
       
      </mat-card-footer>
    </form>

    <div  class="flex_column_center_center   "  style="padding-bottom: 10px; " >
      <mat-divider style="width: 100%;padding-top: 10px;"></mat-divider>
      <app-social-login></app-social-login>
      <!-- <p  >  <span i18n>Social Login click </span> <a  color="primary" mat-button (click)="socialLogin()">here</a></p>
     -->
    </div>
  </mat-card>

</section>
 