 

 
 <mat-card   >
  <mat-card-title   i18n >Change  Password</mat-card-title>
  <mat-card-content>
      <form (ngSubmit)="onSubmit()" [formGroup]="form">
           <div class="form_item" >
           <mat-form-field  appearance="outline">
               <mat-label i18n>Old Password</mat-label>
               <input  type="password"  matInput  formControlName="oldPassword"  required>
             </mat-form-field>
             <mat-form-field  appearance="outline">
                <mat-label i18n>New Password</mat-label>
                <input  type="password" matInput  formControlName="newPassword"  required>
              </mat-form-field>
              <mat-form-field  appearance="outline">
                <mat-label i18n>Re type New Password</mat-label>
                <input type="password" matInput  formControlName="newPassword2"  required>
              </mat-form-field>
             <div class="action">
              <button   class="adv-button" mat-raised-button color="primary" type="submit"    [disabled]="!form.valid" i18n >Save</button> 
            
             </div>
              </div>
       </form>
    </mat-card-content>
    <mat-card-actions align="start">
       
        
    </mat-card-actions>
 
</mat-card>

