import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DepositReciept } from 'src/app/modules/DepositReciept';
 

@Component({
  selector: 'app-receipt-detail-dialog',
  templateUrl: './receipt-detail-dialog.component.html',
  styleUrls: ['./receipt-detail-dialog.component.css']
})
export class ReceiptDetailDialogComponent implements OnInit {
 
  constructor( 
    public dialogRef: MatDialogRef<ReceiptDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DepositReciept) {}
  
    ngOnInit(): void {
    
    }
    onNoClick(): void {
      this.dialogRef.close();
    }
   }
  