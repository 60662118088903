import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { DataUrl, UploadResponse } from 'ngx-image-compress/public-api';
import { Post } from '../../modules/Post';
import { TokenStorageService } from '../tokenStorage/token-storage.service';
import { GlobalConstants } from 'src/app/utility/global-constants';
import { PostSave } from 'src/app/modules/post/PostSave';
import { UserTransaction } from 'src/app/modules/forms/user-transaction/user-transaction.module';
import { saveAs as fileSaverSave } from 'file-saver'
import { Observable, Observer } from 'rxjs';
import { PictureUpload } from 'src/app/modules/PictureUpload';
const AUTH_API = GlobalConstants.serverUrl+'adv/user/';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class UserPostService {
 
  constructor(private http: HttpClient,
    private tokenStorage: TokenStorageService
  ) {

  }

  getAllPost() {
    var uid = this.getCurrentUserId()
    return this.http.get<Post[]>(`${AUTH_API}post/user/${uid}`, httpOptions)
  }

  getAllPostByStatus(status: String) {
    var uid = this.getCurrentUserId()
    return this.http.get<Post[]>(`${AUTH_API}post_status/${status}/user/${uid}`, httpOptions)
  }

  getPostByIdOfUser(pid: number) {
    var uid = this.getCurrentUserId()
    return this.http.get<Post>(`${AUTH_API}post/${pid}/user/${uid}`, httpOptions)
  }
  getPostByIdOfUserForEdit(pid: number) {
    var uid = this.getCurrentUserId()
    return this.http.get<Post>(`${AUTH_API}post/${pid}/user/${uid}/edit`, httpOptions)
  }
  getallPostByUser() {
    var uid = this.getCurrentUserId()
    return this.http.get<Post[]>(`${AUTH_API}post/user/${uid}`, httpOptions)
  }
  savePost(postsave: PostSave) {
    
    console.log("postSave ==>"+JSON.stringify(postsave))
    const uid = this.tokenStorage.getUserId();
    // return this.http.post<Post>(AUTH_API + 'user/' + id + '/post', post, httpOptions);
    return this.http.post(`${AUTH_API}user/${uid}/post`, postsave, httpOptions);
  }

  getAllPostImage(pid:number) {
    var uid = this.getCurrentUserId()
    return this.http.get<PictureUpload[]>(`${AUTH_API}post/${pid}/image/user/${uid}`, httpOptions)
  }
  deleteImage(id: number, name: String) {
    var uid = this.getCurrentUserId()
    // return this.http.delete<any>(AUTH_API + 'post/' + id + '/image/' + name, httpOptions);
    return this.http.delete<any>(`${AUTH_API}post/${id}/image/${name}/user/${uid}`, httpOptions);
  }
  getAllPostTempImage() {
    var uid = this.getCurrentUserId()
    return this.http.get<PictureUpload[]>(`${AUTH_API}post/temp/image/user/${uid}`, httpOptions)
  }
  deleteTempPostImagesByName( name: String) {
    var uid = this.getCurrentUserId()
    // return this.http.delete<any>(AUTH_API + 'post/' + id + '/image/' + name, httpOptions);
    return this.http.delete<any>(`${AUTH_API}post/temp/image/${name}/user/${uid}`, httpOptions);
  }
  // below  reques for image management
  savePostImages(pid, imageURi: any) {
    const formData: FormData = new FormData();
       formData.append('files', this.getImageFile(imageURi));
         var uid = this.getCurrentUserId()
   return this.http.post(`${AUTH_API}post/${pid}/images/user/${uid}`, formData, {
      reportProgress: true,
      responseType: 'json'
    });
  }
  // save temporary  image for user
  saveTemporaryUploadImages( imageURi: any) {
    const formData: FormData = new FormData();
       formData.append('files', this.getImageFile(imageURi));
         var uid = this.getCurrentUserId()
   return this.http.post(`${AUTH_API}post/temp/images/user/${uid}`, formData, {
      reportProgress: true,
      responseType: 'json'
    });
  }
  sendPostToArchive(pid: number) {
    const uid = this.tokenStorage.getUserId();
    // return this.http.delete(AUTH_API + 'post/' + id, httpOptions);
    return this.http.post(`${AUTH_API}post/${pid}/user/${uid}/archive`, httpOptions);
  }

  deletePost(pid: number) {
    const uid = this.tokenStorage.getUserId();
  
    return this.http.delete(`${AUTH_API}post/${pid}/user/${uid}`, httpOptions);
  }
 

  editPost(postsave: PostSave, pid: Number) {
    const uid = this.tokenStorage.getUserId();
    return this.http.post<Post>(`${AUTH_API}user/${uid}/post/${pid}`, postsave, httpOptions);
  }
    
  getPostImages(id: number) {
    return this.http.get<any>(AUTH_API + 'post/' + id + '/images', httpOptions);
  }
  getUserTransactions() {
    return this.http.get<UserTransaction[]>(`${AUTH_API}usertransaction/user/` + this.getCurrentUserId(), httpOptions);
  }

  getCurrentUserId() {
    return this.tokenStorage.getCurrentUserId();
  }

  // dataURI to image converting
  getImageFile(data:DataUrl){
     
    const base64 = this.getSubString(data);
const imageName = "sss.png";
const imageBlob = this.dataURItoBlob(base64);
 const imageFile = new File([imageBlob], imageName, { type: 'image/png' });
 return imageFile;
  }
 
 dataURItoBlob(dataURI) {
  const byteString = window.atob(dataURI);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([int8Array], { type: 'image/png' });    
  return blob;
}
getSubString(data:DataUrl){
  var index=data.indexOf("base64,")
  return  data.substring(index+7,data.length)
}
}