import { SpecificationHead } from "./SpecificationHead";

export class SpecificationHeadOption {
    constructor(
        public id: number,
        public item: String,
        public pvalue:String,
                    ) {   
            }
}
 