import { SalesLocation } from './SalesLocation';
import { User } from './User';

 
export class Address  {

constructor(  
             public id:number,
             public  address :String,
             public location:SalesLocation ,
             public createdDate?:Date,
             public  updatedTime?:Date,
             public  user?:User
             ){       
           }
 
}