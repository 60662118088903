 
<mat-drawer-container class="wrapper2" hasBackdrop="">
    <mat-drawer #drawer mode="over">
        <div class="left-side-draw">

            <div class="personal-info">
                <img src="{{userImage}}" alt="">

                <div class="personal-name">{{userName}}</div>



            </div>

            <div class="personal-option">
                <ul>
                    <!-- <li class="active"><a href="#"><i class="fas fa-tachometer-alt"></i>Dashboard </a></li> -->
                    <li ><a routerLink="/user/profile"><i class="fas fa-user-cog"></i>Profile Setting </a>
                    </li>
                    <li><a routerLink="/user/post"><i class="fas fa-list-alt"></i>My Ads </a></li>

                    <li class="active"><a routerLink="/user/payment"><i class="fas fa-wallet"></i> Payment</a></li>
                    <li><a routerLink="/user/privacy"><i class="fas fa-cog"></i> Setting</a></li>


                </ul>
            </div>

        </div>
    </mat-drawer>
    <mat-drawer-content>
        <div class="wrapper">

            <div class="left-side">

                <div class="personal-info">
                    <img src="{{userImage}}" alt="">

                    <div class="personal-name">{{userName}}</div>



                </div>

                <div class="personal-option">
                    <ul>
                        <!-- <li class="active"><a href="#"><i class="fas fa-tachometer-alt"></i>Dashboard </a></li> -->
                        <li ><a routerLink="/user/profile"><i class="fas fa-user-cog"></i>Profile Setting
                            </a></li>
                        <li><a routerLink="/user/post"><i class="fas fa-list-alt"></i>My Ads </a></li>

                        <li class="active"><a routerLink="/user/payment"><i class="fas fa-wallet"></i> Payment</a></li>
                        <li><a routerLink="/user/privacy"><i class="fas fa-cog"></i> Setting</a></li>
                         

                    </ul>
                </div>


            </div>
            <div class="right-side">
                <div class="right-side-card">
                    <button class="right-side-menu" mat-button (click)="drawer.toggle()"><i class="fas fa-bars"></i></button>
                 </div>
                
              <div class="right-side-item">
                <app-payment-list></app-payment-list>  

              </div>
               



            </div>


        </div>




    </mat-drawer-content>
</mat-drawer-container>


    
     
  
 
