<div class="search-form">
            <div class="title"><h2 i18n>Search Form</h2></div>   
    <mat-form-field  class="serach-catagory"   appearance="outline">  
        <mat-label i18n >Catagory</mat-label>
        <mat-select    [(ngModel)]="data.catagory"  
           >
            <mat-option  Selected value="0">All </mat-option>
            <mat-optgroup *ngFor="let catagory of catagorys | disableOption" style="background-color: rgba(87, 102, 84, 0.5); display: block">
                {{catagory.name}}
                <mat-option *ngFor="let subcat of catagory.productSubCatagory | disableOption" [value]="subcat" style="background-color: white; display: block">
                    {{subcat.name}}
                </mat-option>
            </mat-optgroup>
        </mat-select>
    </mat-form-field>
      
    <mat-form-field  class="search-text"  appearance="outline">  
        <mat-label i18n>What are you looking for ?</mat-label>
        <input matInput [(ngModel)]="data.query" required   >
      </mat-form-field>

      <mat-form-field  class="serach-location"  appearance="outline">  
        <mat-label i18n>Location</mat-label>
        <mat-select    [(ngModel)]="data.location"  
           >
            <mat-option  Selected value="0">All </mat-option>
            <mat-option *ngFor="let loc of locationsList" [value]="loc" >
                {{loc.city}}
                  </mat-option>
        </mat-select>
    </mat-form-field>
    <div  class="btns" >
        <button  class="cancel-btn" color="warn"  mat-button  (click)="onNoClick()" i18n>Cancel</button>
<button  class="submit-btn"  mat-button  (click)="onSearchClick()"  [mat-dialog-close]="data"  [disabled]="!data.query" ><i class="fa fas fa-search"><span i18n>Search</span></i></button>
    </div>

</div> 
