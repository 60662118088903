import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
 

import { Router, ActivatedRoute } from '@angular/router';
import { CKEditor4 } from 'ckeditor4-angular';
 
import { Post } from 'src/app/modules/Post';
 
import { UserPostService } from 'src/app/service/post/User_post.service';
import { GlobalConstants } from 'src/app/utility/global-constants';
import { ImageViewerDialogComponent } from 'src/app/utility/image-viewer-dialog/image-viewer-dialog.component';
 
const AUTH_API = GlobalConstants.serverUrl+'adv/';

@Component({
  selector: 'app-user-post-display',
  templateUrl: './user-post-display.component.html',
  styleUrls: ['./user-post-display.component.css']
})

export class UserPostDisplayComponent implements OnInit {
 slideIndex = 0
  length:number
  imagePath = ""
  post: Post
  pid: number
  // full screen image property
  showFlag: boolean = false;
  selectedImageIndex: number = -1;
  currentIndex:number =-1
  
  constructor(private postService: UserPostService,
    private route: Router,
    private router: ActivatedRoute ,
    public dialog: MatDialog,
  ) {

  }

 async ngOnInit(){
    this.router.queryParams.subscribe(
      result => {
        this.pid = result.pid
        // console.log("post id=>" + this.pid)
      }, error => {
        this.route.navigate(['/error'])
      }

    )
    this.getSource()

  }
  async getSource() {
    const p = await this.postService.getPostByIdOfUser(this.pid).toPromise()
    if (p) { this.post = p }
    else this.route.navigate(['/error'])

    // set display setting
    this.length = this.post.postImage.length
    // console.log("post id=>" + JSON.stringify(this.post.postImage.length))
    console.log("post id=>" + JSON.stringify(this.post))
    this.showSlides();
  }



  // Next/previous controls
  preveousSlides() {
    if (this.slideIndex == 0) this.slideIndex = this.length - 1
    else this.slideIndex--
    console.log("previeous  slide ==>" + this.slideIndex)
    this.showSlides();
  }
  nextSlides() {
    console.log("next  slide ==>" + this.slideIndex)
    if (this.slideIndex >= (this.length - 1)) this.slideIndex = 0
    else this.slideIndex++
    this.showSlides();
  }
  getIndex_lenth(){
    return  (this.slideIndex+1)+"/"+(this.length)
  }
  displayPrice() {
    if (this.post.post_payment.option === 'PRICE') {
      return '' + this.post.post_payment.price_amount  +" "+ this.post.post_payment.price_currency.shortName ;
    } else if (this.post.post_payment.option === 'CONTACT') {
      return 'CONTACT';
    } else if (this.post.post_payment.option === 'COMMISSION') {
      return 'COMMISSION';
    } else if (this.post.post_payment.option === 'RANGE') {
      return '' + this.post.post_payment.min + '-' + this.post.post_payment.max  +" "+ this.post.post_payment.range_currency.shortName;
    }
  }
  displayNegotiable(){
    if(this.post.post_payment.option=== "PRICE")
      return this.post.post_payment.negotiable
  else return false
   }
  showSlides() {
    this.post.post_status.rejectionReason
    this.imagePath = `${AUTH_API}img/`+ this.post.postImage[this.slideIndex].name
  }

   // full screen image methods 
   showLightbox() {
    this.selectedImageIndex =this.slideIndex;
    this.showFlag = true;
}

closeEventHandler() {
    this.showFlag = false;
    this.currentIndex = -1;
}
getPostImageObect():any{
    var  imageObject: Array<object> =[]
      for (let index = 0; index < this.post.postImage.length; index++) {
         imageObject.push({'image':AUTH_API+"img/"+this.post.postImage[index].name})
          
      }
      return  imageObject;
 

}
   
getUserImageUrl(img:String){
  if(img===undefined || img === null ){
    return "assets/default_user.png"
  }else {
    return    AUTH_API+"img/"+img
  }

}
showImageInFullScreen() {
  //   console.log("images to dialog ==>"+JSON.stringify(this.post.postImage))
  const images =this.getPostImageObect().map(obj => obj.image)
       this.dialog.open(ImageViewerDialogComponent, {
       data: { images: images },
       panelClass: 'full-screen-dialog'
     });
  
   }
}
