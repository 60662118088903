import {Component, OnDestroy, OnInit} from '@angular/core';
import { LoadingService } from 'src/app/service/LodingService/loding-service.service';
 
@Component({
  selector: 'app-demo1',
   templateUrl: './demo1.component.html',
  styleUrls: ['./demo1.component.css']
})
export class Demo1Component  implements OnInit {

  constructor(private loadingService: LoadingService) { }

  ngOnInit(): void {
    this.loadingService.show();
  }

}
