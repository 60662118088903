import { formatNumber } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Post } from 'src/app/modules/Post';
import { CommonService } from 'src/app/service/common/common.service';
import { PostService } from 'src/app/service/post/post.service';
import { SubcatagoryService } from 'src/app/service/subcatagory/subcatagory.service';
import { GlobalConstants } from 'src/app/utility/global-constants';

const AUTH_PATH=GlobalConstants.serverUrl

@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.css']
})
export class SearchPageComponent implements OnInit {

  query
  loc:number
  subcat:number     
  
  subCatagories
  cid:number

  searchNotFound:boolean =false
  public  posts: Observable<any>;
 
  @ViewChild(MatPaginator) paginator: MatPaginator;
 
  
  // obs: Observable<any>;
   dataSource: MatTableDataSource<Post> = new MatTableDataSource<Post>();
  constructor(public scatService:SubcatagoryService,
              public  commonService:CommonService,
               public  postService:PostService,
              public snackBar: MatSnackBar,
              public router:ActivatedRoute,
              public  route:Router,
              private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    //{"query":"76875","loc":"2","subcat":"49"}
      this.router.queryParams.subscribe(
        result=>{
         this.query=result.query
         this.loc=result.loc
         this.subcat=result.subcat
 
         if(!(this.query != "" && this.loc >=0 && this.subcat >= 0)) 
         {  this.route.navigate(['/error'])  }
      
        },error => {  this.route.navigate(['/error'])  }
      )
   this.getSource()

    this.changeDetectorRef.detectChanges();
      this.dataSource.paginator = this.paginator;
     this.posts= this.dataSource.connect()

 
   
  }
  async  getSource(){
    const s =await  this.commonService.searchPost(this.query,this.subcat,this.loc).toPromise()
    if(s != undefined){
       this.dataSource.data=s
  if(s.length === 0)  this.searchNotFound=true
    }else {
      this.openSnackBar("couldn't retrieve Posts !!!", "error")
    }
                }
 
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  getImageUrl(post:Post){
    if(post.postImage.length<1){
      return "assets/img/avatar.png"
    }else {
      return    AUTH_PATH+"adv/img/"+post.postImage[0].name
    }

  }
  displayPrice(post:Post){
    if(post.post_payment.option=== "PRICE"){ 
      return  ""+ this.numberWithCommas(post.post_payment.price_amount) +" "+post.post_payment.price_currency.shortName
   }else if(post.post_payment.option=== "CONTACT"){
      return  "CONTACT"
 
   }
   else if(post.post_payment.option=== "COMMISSION"){
     return  "COMMISSION"
 
   } else if(post.post_payment.option=== "RANGE"){
      return ""+ this.numberWithCommas(post.post_payment.min)+"-"+ this.numberWithCommas(post.post_payment.max) +" "+post.post_payment.price_currency.shortName
   }
 }
 displayImangeNumber(p:Post){
     return   p.postImage.length
     
 }

 numberWithCommas(x) {
  return formatNumber(Number(x), 'en-US', '1.0-0');
   
}
 ngOnDestroy() {
  if (this.dataSource) { 
    this.dataSource.disconnect(); 
  }
}
}
