import { FormGroup, FormControl, Validators } from '@angular/forms';
import { User } from 'src/app/modules/User';
import { AuthenticationService } from 'src/app/service/login/Authentication.service';
import { TokenStorageService } from 'src/app/service/tokenStorage/token-storage.service';
import { Router } from '@angular/router';
import { MatSnackBar, } from '@angular/material/snack-bar';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.css']
})
export class LoginDialogComponent implements OnInit {

  // constructor() { }

  // ngOnInit(): void {
  // }

 
  public loginForm: FormGroup;
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  roles: string[] = [];
  user=new User('','','',-1,null)

  constructor(public dialogRef: MatDialogRef<LoginDialogComponent>,
   // @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel,
              private authService: AuthenticationService,
              private tokenStorage: TokenStorageService ,
              private router : Router ,
              private snackbar:MatSnackBar) {}

  ngOnInit(): void {
   this.tokenStorage.signOut();   
     this.loginForm = new FormGroup({
          email: new FormControl('', {
            validators: [Validators.required, Validators.email]
          }),
          password: new FormControl('', { validators: [Validators.required] })
        });
 
  }
     onSubmit() {
        this.user.email=this.loginForm.value.email;
        this.user.password=this.loginForm.value.password;
             
        
      this.authService.login(this.user).subscribe(
        data => {
          
          this.tokenStorage.saveUser(data);
          this.tokenStorage.saveToken(data.token);
          this.tokenStorage.saveRefreshToken(data.refreshToken);
        
        
        
          
          this.isLoginFailed = false;
          this.isLoggedIn = true;
          this.roles = this.tokenStorage.getUser().roles;
          window.location.reload();
        },
        err => {
           
          this.errorMessage = err.error.message;
          this.isLoginFailed = true;
          this.openSnackBar(this.errorMessage)
          this.onDismiss()
        }
      );

 this.user.email=this.loginForm.value.email.replace(/\s/g, "");;
        this.user.password=this.loginForm.value.password;
             
        
      this.authService.login(this.user).subscribe(
        data => {
          console.log(" token == > "+JSON.stringify(data));
 
          this.tokenStorage.saveUser(data);
          this.tokenStorage.saveToken(data.token);
          this.tokenStorage.saveRefreshToken(data.refreshToken);
        
        
        
          
          this.isLoginFailed = false;
          this.isLoggedIn = true;
          this.roles = this.tokenStorage.getUser().roles;


          window.location.reload();
          // this.router.navigate(["/"])  .then(() => {
           
          // });
        
        },
        err => {
           
          this.errorMessage = err.error.message;
          this.isLoginFailed = true;
          this.openSnackBar(this.errorMessage)
        }
      );

    }
     openSnackBar( message) {
      this.snackbar.open(message, "Error", {
        duration: 2000,
      });
    }


    onConfirm(): void {
      // Close the dialog, return true
      this.dialogRef.close(true);
    }
  
    onDismiss(): void {
      // Close the dialog, return false
      this.dialogRef.close(false);
    }

  }