<div class="wrapper">
    <header>
        <div class="top-header">
         
                 
                    <div class="logo">
                      <a href="#" > <img src="assets/images/logo.jpg"  ></a>  
                    </div>
                
            
                <div class="search-bar   ">
                 <!-- <mat-form-field  class="search-text" appearance="outline">
                        <mat-label i18n>What are you looking for ?</mat-label>
                        <input matInput [(ngModel)]="query" required   >
                      </mat-form-field> -->

                      <mat-form-field appearance="outline"  class="search-text">
                        <mat-label i18n>What are you looking for ?</mat-label>
                        <input
                          matInput
                          type="text"
                             (keyup.enter)="onSearchClick()"
                          [(ngModel)]="query"
                        />
                      </mat-form-field>
 
                    <!-- <div class="submit-button">
                <button   mat-button  (click)="onSearchClick()"  [disabled]="!query" ><i class="fa fas fa-search"></i></button>
                    </div> -->
       
                </div> 
            
                <div class="login-post">
                    <div class="user" >

                        <a class="main-button1" mat-button [matMenuTriggerFor]="userMenu" *ngIf="userRoleLogedIn()">
                            <img src="{{getUserImage()}}" width="40" height="40" style="border-radius:50%;">
                        </a>
                        <a class="main-button1" mat-button routerLink="/login" *ngIf="!userLogedIn()"> <i class="fas fas fa-user-plus"></i> </a>
                        <!--  -->
                        <mat-menu #userMenu="matMenu" xPosition="after">

                            <button class="btn3" mat-menu-item routerLink="/user/profile" i18n> My Profile</button>
                            <button class="btn3" mat-menu-item routerLink="/logout" i18n>Log Out</button>
                        </mat-menu>
                    </div>
                    <a mat-button routerLink="/user/newpost" class="new_post_button" i18n>  Post Ads      </a>
                     

                </div>
                <div class="drop-down-hide-menu">
                    <a class="main-button1" mat-button [matMenuTriggerFor]="hidenMenu" *ngIf="userRoleLogedIn()">
                        <img src="{{getUserImage()}}" width="40" height="40" style="border-radius:50%;">
                    </a>
                    <a class="humburger-btn" mat-button [matMenuTriggerFor]="hidenMenu" *ngIf="!userRoleLogedIn()" ><i class="fas  fa fa-bars"></i></a>
                         <mat-menu #hidenMenu="matMenu" xPosition="after">
                            <button class="btn3" mat-menu-item (click)="onMiniSearchClick()" > <i class="fas fas fa-search"></i><span i18n>Search</span> </button>
                            <button  class="btn3" mat-menu-item routerLink="/user/newpost"  ><i class="fas fas fas fa-plus"></i><span i18n>Post Ads</span> </button>
                            <button *ngIf="userLogedIn()" class="btn3" mat-menu-item routerLink="/user/profile"><i class="fas fa-user"></i><span i18n>My Profile</span> </button>
                            <button *ngIf="userLogedIn()"  class="btn3" mat-menu-item routerLink="/logout"> <i class="fas fa-sign-out-alt"></i> <span i18n>Log Out</span> </button>
                            <button *ngIf="!userLogedIn()" class="btn3" mat-menu-item routerLink="/login"> <i class="fas  fa-user-plus"></i><span i18n>Log In</span> </button>
                               </mat-menu>
                </div>
 
        </div>
        <div class="top-header1">
            <div class="search-bar  search-bar-2">
               
                <mat-form-field  class="serach-catagory"   appearance="outline">   
                    <mat-label>Catagory</mat-label>
                    <mat-select    [(ngModel)]="catagory"  
                       >
                        <mat-option  Selected value="0" i18n>All </mat-option>
                        <mat-optgroup *ngFor="let catagory of catagorys">
                            {{catagory.name}}
                            <mat-option *ngFor="let subcat of catagory.productSubCatagory" [value]="subcat">
                                {{subcat.name}}
                            </mat-option>
                        </mat-optgroup>
                    </mat-select>
                </mat-form-field>
                  
                <mat-form-field  class="search-text" appearance="outline">  
                    <mat-label i18n>What are you looking for ?</mat-label>
                    <input matInput [(ngModel)]="query" required   >
                  </mat-form-field>

                  <mat-form-field  class="serach-location" appearance="outline">  
                    <mat-label i18n>Location</mat-label>
                    <mat-select    [(ngModel)]="location"  
                       >
                        <mat-option  Selected value="0" i18n>All </mat-option>
                        <mat-option *ngFor="let loc of locationsList" [value]="loc" >
                            {{loc.city}}
                              </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="submit-button">
            <button   mat-button  (click)="onSearchClick()"  [disabled]="!query" ><i class="fa fas fa-search"></i></button>
                </div>
   
            </div> 
        </div>
        <div class="wrapper2">
            <div class="second-menu  ">

                <div class="menu">
                    <button class="main-button" mat-button [matMenuTriggerFor]="adminMenu" *ngIf="adminRoleLogedIn()">Admin <i class="fas fa-caret-down"></i></button>
                    <mat-menu #adminMenu="matMenu" yPosition="above">
                        <button class="btn3" mat-menu-item routerLink="/admin/catagory">Product Catagory </button>
                        <button class="btn3" mat-menu-item routerLink="/admin/post">Post Managment</button>
                        <button class="btn3" mat-menu-item routerLink="/admin/setting">Admin Setting</button>
                        <button class="btn3" mat-menu-item routerLink="/admin/finance">Admin Finance</button>

                    </mat-menu>

                    <button class="main-button" mat-button [matMenuTriggerFor]="managerMenu" *ngIf="managerRoleLogedIn()">Manager <i class="fas fa-caret-down"></i></button>
                    <mat-menu #managerMenu="matMenu" yPosition="below">
                        <button class="btn3" mat-menu-item routerLink="/managment/post">Post</button>

                    </mat-menu>

                    <button class="main-button" mat-button [matMenuTriggerFor]="financeMenu" *ngIf="financeRoleLogedIn()">Finance <i class="fas fa-caret-down"></i></button>
                    <mat-menu #financeMenu="matMenu" yPosition="below">
                        <button class="btn3" mat-menu-item routerLink="/finance/casher">Casher</button>
                        
                    </mat-menu>




                    <!-- <ul>
            <li>Home</li>
            <li>Admin</li>
            <li>Managment</li>
            <li>Finance</li>
            <li>Contact</li>
            <li>About</li>
          </ul> -->
                </div>
            </div>
        </div>
    </header>



</div>