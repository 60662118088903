import { FocusTrap } from '@angular/cdk/a11y';
import { formatNumber } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Post } from 'src/app/modules/Post';
import { CatagoyrService } from 'src/app/service/catagory/catagory.service';
import { LoadingService } from 'src/app/service/LodingService/loding-service.service';
 
import { PostService } from 'src/app/service/post/post.service';
import { SubcatagoryService } from 'src/app/service/subcatagory/subcatagory.service';
import { TokenStorageService } from 'src/app/service/tokenStorage/token-storage.service';
import { GlobalConstants } from 'src/app/utility/global-constants';
import { Utility } from 'src/app/utility/Utility';
const AUTH_PATH=GlobalConstants.serverUrl
@Component({
  selector: 'app-subcatagory-page',
  templateUrl: './subcatagory-page.component.html',
  styleUrls: ['./subcatagory-page.component.css']
})
export class SubcatagoryPageComponent implements OnInit {

  subCatagories
  cid:number
  scid:number
  selected_subcat_id
  public  posts: Observable<any>;
   no_ads_show:boolean=false  
 
  @ViewChild(MatPaginator) paginator: MatPaginator;
 
  
  // obs: Observable<any>;
   dataSource: MatTableDataSource<Post> = new MatTableDataSource<Post>();
  constructor(public scatService:SubcatagoryService,
    public  tokenStorage:TokenStorageService,
              public catService:CatagoyrService,
              public  postService:PostService,
              public snackBar: MatSnackBar,
              public router:ActivatedRoute,
              public  route:Router,
              private loadingService: LoadingService,
              private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
      this.router.queryParams.subscribe(
        result=>{
          this.cid=result.cid
          this.scid=result.scid
          
          console.log("result =>"+JSON.stringify(result))
        },error => {
           
        //  this.route.navigate(['/error'])
        

        }
      )

    this.getSource()

    this.changeDetectorRef.detectChanges();
      this.dataSource.paginator = this.paginator;
     this.posts= this.dataSource.connect()

 
   
  }
  async getSourceBySubCatagory( scid:number){
    this.loadingService.show();

    const s =await  this.postService.getPostBySubCat(scid).toPromise()
    if(s != undefined){
      
       this.dataSource.data=s
       this.selected_subcat_id=scid
       console.log("posst 3453 SUBCAT size ==> "+s.length)
       if(this.dataSource.data.length> 0)  this.no_ads_show=false
       else  this.no_ads_show=true
       this.loadingService.hide();
    }else {
      this.openSnackBar("couldn't retrieve Posts !!!", "error")
      this.loadingService.hide();
    }
  }
  async  getSource(){
    this.loadingService.show();
    if( this.cid != undefined){
      const s =await  this.postService.getPostByCat(this.cid).toPromise()
      if(s != undefined){
         this.dataSource.data=s
        console.log("posst CAT size ==> "+s.length) 
          if(this.dataSource.data.length> 0)  this.no_ads_show=false
           else  this.no_ads_show=true
       this.loadingService.hide();
      }else {
        
        this.openSnackBar("couldn't retrieve Posts !!!", "error")
        this.loadingService.hide();
      }
    } 
    if( this.scid != undefined){
      const s =await  this.postService.getPostBySubCat(this.cid).toPromise()
      if(s != undefined){
         this.dataSource.data=s
       console.log("posst SUBCAT size ==> "+s.length)
       if(this.dataSource.data.length> 0)  this.no_ads_show=false
       else  this.no_ads_show=true
      }else {
        this.openSnackBar("couldn't retrieve Posts !!!", "error")
      }
    } 

    const p =await  this.scatService.getListOfCatagoryByPost(this.cid).toPromise()
    if(p != undefined){
        console.log("out put data => "+JSON.stringify(p))
        
          this.subCatagories=p
          
          this.checkforSubCat()
      
       }else {
    //  this.route.navigate(['/error'])    ///catagory not found 
         
     console.log("error 1");
     
           }
                    }

checkforSubCat(){
  var found=0
  if(this.scid  != undefined){
    
    for (let index = 0; index < this.subCatagories.length; index++) {
       if(this.subCatagories[index].id==this.scid){
               found=1
              break 
         }
      
    }
 
    if(found == 1)  this.getSourceBySubCatagory(this.scid)
    else    {
   // this.route.navigate(['/error'])    /// subcatagory not found 
 
    }   
  }  
}
 
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  getImageUrl(post:Post){
    return  Utility.getUserImageURL(post.postImage[0].name)
   
  }
  displayPrice(post:Post){
    if(post.post_payment.option=== "PRICE"){ 
      return  ""+ this.numberWithCommas(post.post_payment.price_amount) +" "+post.post_payment.price_currency.shortName
   }else if(post.post_payment.option=== "CONTACT"){
      return  "CONTACT"
 
   }
   else if(post.post_payment.option=== "COMMISSION"){
     return  "COMMISSION"
 
   } else if(post.post_payment.option=== "RANGE"){
      return ""+ this.numberWithCommas(post.post_payment.min)+"-"+ this.numberWithCommas(post.post_payment.max) +" "+post.post_payment.price_currency.shortName
   }
 }
 displayImangeNumber(p:Post){
     return   p.postImage.length
     
 }

 numberWithCommas(x) {
  return formatNumber(Number(x), 'en-US', '1.0-0');
 
}
 ngOnDestroy() {
  if (this.dataSource) { 
    this.dataSource.disconnect(); 
  }
}
 getPostCount(){
var count=0;
    if(this.dataSource.data.length>0 ){
     count= this.dataSource.data.length
     
  
    } else  count=0
    return count
}
adminRole(){
  return this.tokenStorage.isAdminRole();
 }

}
