import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalConstants } from 'src/app/utility/global-constants';
  
const SERVER_URL = GlobalConstants.serverUrl;

const pageMapping: Record<string, number> = {
  '/user/profile': 0,
  '/user/post': 1,
  '/user/payment': 2,
  '/user/privacy': 3,
};
@Component({
  selector: 'app-user-page-panel',
   templateUrl: './user-page-panel.component.html',
  styleUrl: './user-page-panel.component.css'
})
export class UserPagePanelComponent implements OnInit {


  page_url: string = ""
  index: number = 0

  constructor(private route: Router) { }

  ngOnInit(): void {


    this.page_url = this.route.routerState.snapshot.url;

    // Use the mapping object to set the index
    this.index = pageMapping[this.page_url] || 0;

    // If the URL is not in the mapping, navigate to the default URL
    if (!pageMapping[this.page_url]) {
      this.route.navigate(['/user/profile']);
    }
  }
}





