import {  ChangeDetectorRef,Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { formatNumber } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
 
import { MatDialog } from '@angular/material/dialog';
 
 
import { MatSnackBar } from '@angular/material/snack-bar';
  
import { Post } from 'src/app/modules/Post';
import { UserPostService } from 'src/app/service/post/User_post.service';
import { UserService } from 'src/app/service/user/user.service';
import { Post_status } from 'src/app/modules/Post_status';
import { GlobalConstants } from 'src/app/utility/global-constants';
import { ConfirmDialogModel } from 'src/app/utility/ConfirmDialogModel';
import { ConfirmationDialogComponent } from 'src/app/utility/confirmation-dialog/confirmation-dialog.component';
import { MessageDialogComponent } from 'src/app/utility/message-dialog/message-dialog.component';
import { Observable } from 'rxjs';

const AUTH_API = GlobalConstants.serverUrl+'adv/';
 
 
  @Component({
    selector: 'app-user-post-list-responsive',
    templateUrl: './user-post-list-responsive.component.html',
    styleUrls: ['./user-post-list-responsive.component.css']
  })
  export class UserPostListResponsiveComponent  implements OnInit {
 
    public  posts: Observable<any>;
 
    @ViewChild(MatPaginator) paginator: MatPaginator;

 // obs: Observable<any>;
 dataSource: MatTableDataSource<Post> = new MatTableDataSource<Post>();
 
  post_status_list
   status:Post_status
   result: string = '';
   
  constructor(
    private postService: UserPostService,
    public dialog: MatDialog,
    private snackbar:MatSnackBar,
    private  userService:UserService,
    private changeDetectorRef: ChangeDetectorRef
  
  ) {}
  ngOnInit(): void {
    this.getSourcedata();
    this.changeDetectorRef.detectChanges();
    this.dataSource.paginator = this.paginator;
   this.posts= this.dataSource.connect()
  }
 
 async getSourcedata(){
  const p=await  this.postService.getallPostByUser().toPromise()
  if(p)  this.dataSource.data =p

  const s=await  this.userService.getPostCatagoryByStatusOfUser().toPromise()
  if(s)this.post_status_list=s;
 }
 async getSourceByStatus(status:string){
   console.log("status data =>"+status)
  const p=await  this.postService.getAllPostByStatus(status).toPromise()
  if(p)  this.dataSource.data =p

  
 }
  
  ngAfterViewInit() {
 
    this.dataSource.paginator = this.paginator;
  }
 
  doFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
 
 getImageUrl(post:Post){
    if(post.postImage.length<1){
      return "assets/img/avatar.png"
    }else {
      return    AUTH_API +"img/"+post.postImage[0].name
    }
  }
  getStatus(post){
    return   post.post_status.status
    
  }
  
  displayPrice(post:Post){
    if(post.post_payment.option=== "PRICE"){ 
      return  ""+ this.numberWithCommas(post.post_payment.price_amount) +" "+post.post_payment.price_currency.shortName
   }else if(post.post_payment.option=== "CONTACT"){
      return  "CONTACT"
 
   }
   else if(post.post_payment.option=== "COMMISSION"){
     return  "COMMISSION"
 
   } else if(post.post_payment.option=== "RANGE"){
      return ""+ this.numberWithCommas(post.post_payment.min)+"-"+ this.numberWithCommas(post.post_payment.max) +" "+post.post_payment.price_currency.shortName
   }
 }
 numberWithCommas(x) {
  return formatNumber(Number(x), 'en-US', '1.0-0');
  
}
  openSnackBar( message,type) {
    this.snackbar.open(message, type, {
      duration: 2000,
    });
  }
  getCount(status:string){
    var n
    if(status=== undefined || this.post_status_list=== undefined) return 0
    if(status === Post_status.active ){
      n= this.post_status_list.find(x => x.status.toLowerCase()=== Post_status.active.toLowerCase())
      if(n != undefined)  return  n.qty
     return  0
      
    }else if(status===Post_status.pending ){
       n= this.post_status_list.find(x => x.status.toLowerCase()===  Post_status.pending.toLowerCase())
      if(n != undefined)  return  n.qty
     return  0
    }else if(status===Post_status.disabled ){
             n= this.post_status_list.find(x => x.status.toLowerCase()=== Post_status.disabled.toLowerCase())
            if(n != undefined)  return  n.qty
                return  0
    } else if(status===Post_status.error ){
       n= this.post_status_list.find(x => x.status.toLowerCase()=== Post_status.error.toLowerCase())
      if(n != undefined)  return  n.qty
          return  0 
    }else if(status===Post_status.archive ){
      n= this.post_status_list.find(x => x.status.toLowerCase()=== Post_status.archive.toLowerCase())
     if(n != undefined)  return  n.qty
         return  0 
   }else if(status==='ALL' ){
       var sum=0
       this.post_status_list.forEach(a => sum += a.qty);
        return  sum
      
    }
    
}
showArchiveButton(element){
  if(element.post_status.status === Post_status.active) return true;
  else  return false ;
}
showEditButton(element){
  if(element.post_status.status === Post_status.disabled  || element.post_status.status === Post_status.active ) return false;
  else  return true ;
}
showDeleteButton(element){
  if(element.post_status.status === Post_status.pending  || 
           element.post_status.status === Post_status.archive  ) return true;
  else  return false ;
}



// start to delete  option
confirmDelete(id:number): void {
 this.deletePostDialog("Confirmation","Do you really want to Delete Post ?",id);
}
deletePostDialog(title,message,id){
  
    const dialogData = new ConfirmDialogModel(title, message);

  const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
    maxWidth: "400px",
    data: dialogData
  });

  dialogRef.afterClosed().subscribe(dialogResult => {
     dialogResult;
    if( dialogResult){
        this.postService.deletePost(id).subscribe(
          r=>{
            this.messageDialog("Message","Post Deleted !!!")
            location.reload();
               
             },
             e=>{
               this.messageDialog("Error","Post Delete Fail !!!")
             }
           );
    }
  });

}
// end to delete dialog 
movePostToAcrhive(title,message,id){
  
  const dialogData = new ConfirmDialogModel(title, message);

const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
  maxWidth: "400px",
  data: dialogData
});

dialogRef.afterClosed().subscribe(dialogResult => {
   dialogResult;
  if( dialogResult){
    this.postService.sendPostToArchive(id).subscribe(
      r=>{
     this.messageDialog("Message","Post Sent to Archive !!!")
     location.reload();
      },
      e=>{
        this.messageDialog("Error","Post Sent to Archive Fail !!!")
      }
    );
  }
});

}

onArchive(id:number): void {

  this.movePostToAcrhive("Confirmation","Do you really want To move the post To Archieve?",id);
}
// start  to message Dialog 
messageDialog(title,message){ 
  

  const dialogData = new ConfirmDialogModel(title, message);

  const dialogRef = this.dialog.open(MessageDialogComponent, {
    maxWidth: "400px",
    data: dialogData
  });

//   dialogRef.afterClosed().subscribe(dialogResult => {
//     //this.result = dialogResult;
//   });
  }
}