import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './common/home/home.component';
import { RegisterComponent } from './common/register/register.component';
import { LoginComponent } from './common/login/login.component';
import { ErrorComponent } from './common/error/error.component';

import { LogoutComponent } from './common/logout/logout.component';
import { ActivateComponent } from './common/activate/activate.component';
import { ForgotpasswordComponent } from './common/forgotpassword/forgotpassword.component';
 
import { NewpasswordComponent } from './common/newpassword/newpassword.component';
import { ProductCatagoryComponent } from './admin/product-catagory/product-catagory.component';
import { ProductSubCatagoryComponent } from './admin/product-sub-catagory/product-sub-catagory.component';
 
 
import { UsersComponent } from './admin/setting/users/users.component';
import { SettingComponent } from './admin/setting/setting.component';
import { PostNewComponent } from './user/post/post-new/post-new.component';
 
 
 import { EditpostComponent } from './user/post/editpost/editpost.component';
import { SpecificationheadComponent } from './admin/specificationhead/specificationhead.component';
import { SpecificationheadFormComponent } from './admin/specificationhead/specificationhead-form/specificationhead-form.component';
import { LogInGuardService } from './service/router-guard/LogIn-guard.service';

import { UserGuardService } from './service/router-guard/user-guard.service';
import { AdmGuardService } from './service/router-guard/adm-guard.service';
import { ManagerGuardService } from './service/router-guard/manager-guard.service';
import { MgPostListComponent } from './managment/mg-post-list/mg-post-list.component';
import { MgPostDisplayComponent } from './managment/display/mg-post-display/mg-post-display.component';
 
import { UserPostDisplayComponent } from './user/post/user-post-display/user-post-display.component';
import { HomepageComponent } from './home/homepage/homepage.component';
import { SubcatagoryPageComponent } from './home/subcatagory-page/subcatagory-page.component';
import { UserPageComponent } from './home/user-page/user-page.component';
import { PostdisplayComponent } from './common/post/postdisplay/postdisplay.component';
import { PostDisplayPageComponent } from './home/post-display-page/post-display-page.component';
import { CatagoryPageComponent } from './home/catagory-page/catagory-page.component';
import { FinanceGuardService } from './service/router-guard/finance-guard.service';
import { CasherPageComponent } from './finance/casher-page/casher-page.component';
import { DepositeReceiptFormComponent } from './finance/deposite-receipt-form/deposite-receipt-form.component';

import { AdminFinanceComponent } from './admin/admin-finance/admin-finance.component';
import { AdminFinanceReportComponent } from './admin/admin-finance/admin-finance-report/admin-finance-report.component';
import { ManagmentUserDetailComponent } from './managment/managment-user-detail/managment-user-detail.component';
import { AdminUserDetailComponent } from './admin/admin-user-detail/admin-user-detail.component';
import { FinanceUserDetailComponent } from './finance/finance-user-detail/finance-user-detail.component';
import { AdminPostDisplayComponent } from './admin/post_managment/admin-post-display/admin-post-display.component';
import { SearchPageComponent } from './home/search/search-page/search-page.component';
 
import { PostManagmentComponent } from './admin/post_managment/post-managment.component';
import { PostFraudDisplayComponent } from './admin/post_managment/post-fraud-display/post-fraud-display.component';
 
import { SupportComponent } from './missillenious/support/support.component';
import { PrivacyComponent } from './missillenious/privacy/privacy.component';
import { TermsOfServiceComponent } from './missillenious/terms-of-service/terms-of-service.component';
import { HowItWorkComponent } from './missillenious/how-it-work/how-it-work.component';
import { AdvertisePolicyComponent } from './missillenious/advertise-policy/advertise-policy.component';
import { ContactComponent } from './missillenious/contact/contact.component';
import { Demo1Component } from './demo/demo1/demo1.component';
import { Demo2Component } from './demo/demo2/demo2.component';
import { FaqComponent } from './missillenious/faq/faq.component';
import { VersionComponent } from './missillenious/version/version.component';
import { LoddingSpinnerComponent } from './utility/lodding-spinner/lodding-spinner.component';
import { UserPagePanelComponent } from './user/user-page-panel/user-page-panel.component';
import { AboutUsComponent } from './missillenious/about-us/about-us.component';
 
 
 

const routes: Routes = [
  {path:"",component:HomepageComponent},
  {path:"display",component:PostDisplayPageComponent},
  {path:"cat",component:CatagoryPageComponent},
  {path:"subcat",component:SubcatagoryPageComponent},
  {path:"user",component:UserPageComponent},


  {path:"search",component:SearchPageComponent}, //{ qyery: this.query,loc:l,subcat:c } 
  {path:"",component:HomepageComponent},

  //common section
  
  {path:"login",component:LoginComponent},
  {path:"home",component:HomeComponent},
  {path:"logout",component:LogoutComponent,canActivate:[LogInGuardService]},
  {path:"forgotpassword",component:ForgotpasswordComponent},
  {path:"activate/:token",component:ActivateComponent},
  {path:"register",component:RegisterComponent},
 
{ path:"newpassword/:token",component:NewpasswordComponent},

  {path:"display/adv/:id",component:PostdisplayComponent},

// admin  pages      
  {path:"admin/catagory",component:ProductCatagoryComponent,canActivate:[AdmGuardService]},
   {path:"admin/users",component:UsersComponent,canActivate:[AdmGuardService]},
   {path:"admin/setting",component:SettingComponent,canActivate:[AdmGuardService]},
   {path:"admin/setting/user",component:SettingComponent,canActivate:[AdmGuardService]},
   {path:"admin/setting/sales_location",component:SettingComponent,canActivate:[AdmGuardService]},
   {path:"admin/setting/currency",component:SettingComponent,canActivate:[AdmGuardService]},
   {path:"admin/setting/sent_mail",component:SettingComponent,canActivate:[AdmGuardService]},
   {path:"admin/setting/system_property",component:SettingComponent,canActivate:[AdmGuardService]},
   {path:"admin/specification",component:SpecificationheadComponent,canActivate:[AdmGuardService]},
   {path:"admin/specificationform",component:SpecificationheadFormComponent,canActivate:[AdmGuardService]},
   {path:"admin/subcatagory",component:ProductSubCatagoryComponent,canActivate:[AdmGuardService]},
   {path:"admin/post",component:PostManagmentComponent,canActivate:[AdmGuardService]},
   {path:"admin/post/status",component:PostManagmentComponent,canActivate:[AdmGuardService]},
   {path:"admin/post/abuse_notification",component:PostManagmentComponent,canActivate:[AdmGuardService]},
  
   {path:"admin/post/frauddisplay",component:PostFraudDisplayComponent,canActivate:[AdmGuardService]},
    {path:"admin/postdisplay",component:AdminPostDisplayComponent,canActivate:[AdmGuardService]},
    {path:"admin/finance",component:AdminFinanceComponent,canActivate:[AdmGuardService]},
    {path:"admin/financereport",component:AdminFinanceReportComponent,canActivate:[AdmGuardService]},
{path:"admin/userdetail",component:AdminUserDetailComponent,canActivate:[AdmGuardService]},
 
    // managment pages   userdetail
   {path:"managment/postdisplay",component:MgPostDisplayComponent,canActivate:[ManagerGuardService]},
   {path:"managment/post",component:MgPostListComponent,canActivate:[ManagerGuardService]},
   {path:"managment/userdetail",component:ManagmentUserDetailComponent,canActivate:[ManagerGuardService]},
 
 //finance pages
 {path:"finance",component:CasherPageComponent,canActivate:[FinanceGuardService]},
 {path:"finance/casher",component:CasherPageComponent,canActivate:[FinanceGuardService]},
 {path:"finance/deposite_form",component:DepositeReceiptFormComponent,canActivate:[FinanceGuardService]},
 {path:"finance/userdetail",component:FinanceUserDetailComponent,canActivate:[FinanceGuardService]},
 
 
 // {path:"finance/report",component:DepositeReceiptFormComponent,canActivate:[FinanceGuardService]},

  //user pages
  {path:"user/newpost",component:PostNewComponent,canActivate:[LogInGuardService]},
  {path:"user/editpost",component:EditpostComponent,canActivate:[UserGuardService]},
  {path:"user/postdisplay",component:UserPostDisplayComponent,canActivate:[UserGuardService]},

  // {path:"user/profile",component:ProfileComponent,canActivate:[UserGuardService]},
  // {path:"user/post",component:PostComponent,canActivate:[UserGuardService]},
  // {path:"user/payment",component:PaymentComponent,canActivate:[UserGuardService]},
  // {path:"user/privacy",component:PrivacySettingComponent,canActivate:[UserGuardService]},
 
  {path:"user/profile",component:UserPagePanelComponent,canActivate:[UserGuardService]},
  {path:"user/post",component:UserPagePanelComponent,canActivate:[UserGuardService]},
  {path:"user/payment",component:UserPagePanelComponent,canActivate:[UserGuardService]},
  {path:"user/privacy",component:UserPagePanelComponent,canActivate:[UserGuardService]},
  
  {path:"demo",component:Demo1Component},
  {path:"demo2",component:Demo2Component},
  {path:"spinner",component:LoddingSpinnerComponent},
  {path:"support",component:SupportComponent},
  {path:"privacy",component:PrivacyComponent},
  {path:"termsofservice",component:TermsOfServiceComponent},
  {path:"howitwork",component:HowItWorkComponent},
  {path:"advertisepolicy",component:AdvertisePolicyComponent},
  {path:"faq",component:FaqComponent},
  {path:"contact",component:ContactComponent},
  {path:"about_us",component:AboutUsComponent},
 
  {path:"v_",component:VersionComponent},
  {path:"error",component:ErrorComponent},
  {path:"**",component:ErrorComponent},
  
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
