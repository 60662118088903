<app-lodding-spinner></app-lodding-spinner>
 
<mat-drawer-container class="wrapper2" hasBackdrop="">
  <mat-drawer #drawer mode="over">
    <div class="left-side-draw">
      <div class="cat-sub-list">
        <div class="back_cat" style="border-bottom: 3px solid #d6d6d6; padding-left: 20px;">
          <a routerLink="/cat"  i18n > <i class="fas fa-arrow-left"></i>  To Catagory  </a> 
        </div>
       
        <ul *ngFor="let scat of  subCatagories"  >
         
            <li  class="scat_li"  [ngStyle]="{'background-color':selected_subcat_id ==scat.id ? '#00a651' : '' }" ><button mat-button [ngStyle]="{'background-color':selected_subcat_id ===scat.id ? '#00a651' : '' }"  (click)="getSourceBySubCatagory(scat.id)" >{{scat.name}} <span>({{scat.qty}})</span></button></li>
            <!-- routerLink="/subcat" [queryParams]="{scid:scat.id}" -->
             
        </ul>
  </div>
          

    </div>
  </mat-drawer>
  <mat-drawer-content>
    <div class="wrapper">

      <div class="left-side">
        <div class="cat-sub-list">
            <div class="back_cat" style="border-bottom: 3px solid #d6d6d6; padding-left: 20px;">
              <a routerLink="/cat"   i18n> <i class="fas fa-arrow-left"></i>  To Catagory  </a> 
            </div>
           
            <ul *ngFor="let scat of  subCatagories"  >
             
                <li  class="scat_li"  [ngStyle]="{'background-color':selected_subcat_id ==scat.id ? '#00a651' : '' }" ><button mat-button [ngStyle]="{'background-color':selected_subcat_id ===scat.id ? '#00a651' : '' }"  (click)="getSourceBySubCatagory(scat.id)" >{{scat.name}} <span>({{scat.qty}})</span></button></li>
                <!-- routerLink="/subcat" [queryParams]="{scid:scat.id}" -->
                 
            </ul>
      </div>
            
  
      </div>
      <div class="right-side"  >
        <!-- <mat-card  *ngIf="(getPostCount()>0)" >
          <button mat-icon-button (click)="drawer.toggle()" ><i class="fas fa-ellipsis-v"></i></button>

      
         
       </mat-card> -->
       <div class="right-side-card" *ngIf="(getPostCount()>0)">
        <button class="right-side-menu" mat-button (click)="drawer.toggle()"><i class="fas fa-bars"></i></button>
     </div>
        <div class="card-list"   >
          <div class="product-card  " class="product-card  " *ngFor="let p of posts  | async  " >
            <div class="product-image">
               <a routerLink="/display" [queryParams]="{pid:p.id}"> <img [src]="getImageUrl(p)"     alt=""></a>
                 <div class="image-number "> <i class="fas fas fa-camera"></i> {{displayImangeNumber(p)}} </div>
         
            </div>
            <div class="product-data">
              <div class="price-tag  flex_row_space_between">
                <p>{{displayPrice(p)}}</p>
                <p *ngIf="adminRole()" ><i   class="far fa-eye"></i>  {{p.view}}</p>
  
              </div> 
             <div class="post-title"><a   routerLink="/display" [queryParams]="{pid:p.id}"> <p>{{p.description}} </p></a></div>
             
                       
            </div>
        </div> 
       
      </div>
        <div class="paginator" *ngIf="(getPostCount()>0)">
          <mat-paginator [length]="100"
          [pageSize]="25"
          [pageSizeOptions]="[10, 25, 50]"
          aria-label="Select page">
</mat-paginator>
          </div>
           <app-no-ads-found *ngIf="no_ads_show"  style="min-width:100%" ></app-no-ads-found>  

        </div>
      
   
  </div>   
   
  
     
     
  </mat-drawer-content>
</mat-drawer-container>
