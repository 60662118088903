   <section>

    

        <dir class="container" *ngIf="!isSuccessful">
            <form #f="ngForm" (ngSubmit)="onSubmit(f)">
            <div *ngIf="f.submitted && isSignUpFailed" class="alert-error">
                <span i18n> failed!</span><br />{{ errorMessage }}
            </div>
           

            <div class="title">
                <h1 i18n>User Ragistration</h1>
            </div>
            <div class="form-field">
                <mat-form-field  appearance="outline">
                    <mat-label  i18n>Full Name</mat-label>
                    <input type="text" matInput   required ngModel #fullNameInput="ngModel" name
                        name="fullName">
                    <!-- <mat-error *ngIf="fullNameInput.hasError('required')">Empty is not allawed</mat-error>
     -->
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label  i18n>Your Email</mat-label>
                    <input type="email" matInput   required ngModel #emailInput="ngModel" email 
                        name="email">
                    <!-- <mat-error *ngIf="!emailInput.hasError('required')">E-mail is invalid</mat-error> -->
                    <!-- <mat-error *ngIf="emailInput.hasError('required')">Empty is not allawed</mat-error>
                   
               -->
                </mat-form-field  >
                <mat-form-field hintLabel="Should be Atleast 6 charachters" appearance="outline">
                    <mat-label i18n>Your Password</mat-label>
                    <input type="password" matInput ngModel name="password" #pwInput="ngModel" minlength="6"
                        >
                    <!-- <mat-hint align="end">{{pwInput.value?.length}}/6</mat-hint>
                    <mat-error>Short Password</mat-error> -->
                </mat-form-field>
                <mat-form-field hintLabel="Should be Atleast 6 charachters" appearance="outline">
                    <mat-label i18n >Retype Password</mat-label>
                    <input type="password" matInput ngModel name="password2" #pwInput2="ngModel" minlength="6"
                        >
                    <!-- <mat-hint align="end">{{pwInput2.value?.length}}/6</mat-hint> -->
                    <!--     
                    <mat-error>Short Password</mat-error> -->
                </mat-form-field>  
                <mat-form-field   appearance="outline">
                    <mat-label  i18n>phone number</mat-label>
                    <input type="text" matInput pattern="09[128]{1}[0-9]{7}"   min="10" max="10" ngModel name="phone" #phone="ngModel" required="" minlength="6"
                        >
                        <mat-hint i18n>Ex 09********</mat-hint>
                </mat-form-field>
                <div class="agree">
                    <mat-checkbox ngModel name="agree"  style="color: #00a651;" required ><span i18n style="color: #00a651;">Agreed To Terms of Service</span>
                    </mat-checkbox> 
                    <!-- <a routerLink="/termsofservice" target="_blank" i18n>Terms of Service</a> -->
                    
                     
                </div>

            </div>
            <div class="action">
                <button type="submit" mat-raised-button  color="primary" class="adv-button" [disabled]="f.invalid || !enableSubmit">Submit</button>
            </div>


        </form>
        </dir>
 
        <dir class="container_login"  *ngIf="isSuccessful">
            <div   class="alert-message">
                <p  i18n> Your registration is successful In Ordr to activate your account
                    please go to your email : {{form.email}} </p>
            </div>

    <app-login></app-login>
        </dir>
</section>