import { User } from './User';

 
export class PictureUpload  {

constructor(  
             public id:number,
             public name:String,
             public  url?:String,
              ){        
}
 
 
  
}