 

 
 <mat-card   >
    <mat-card-title   i18n >Change  Phone Number</mat-card-title>

    <mat-card-content>
        <form (ngSubmit)="onSubmit()" [formGroup]="form">
        <div class="form_item" >
            <mat-form-field appearance="outline">
                <mat-label i18n>Telephone</mat-label>
                 
                <input   matInput type="text"  pattern="09[128]{1}[0-9]{7}"   min="10" max="10" formControlName="phone"  required >
                <mat-icon matSuffix>mode_edit</mat-icon>
               
                <mat-hint i18n>Ex 09********</mat-hint>
                

              </mat-form-field>
              <div class="action">
                <button class="adv-button"  mat-raised-button color="primary" type="submit"    [disabled]="!form.valid" i18n >Save Change</button> 
            
              </div>
              </div>
       </form>
    </mat-card-content>
    <mat-card-actions align="start">
       
        
    </mat-card-actions>
 
</mat-card>


