<div class="allContainer" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="10px">
 
  <div class="left_container" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
    <div class="user_image">
      <mat-card>
        <div class="" *ngIf="isImageNotNull()">
        <img matCardImage src="{{url}}" alt="assets/img/avatar.png" style="width: 200px" />
          
        </div>
        <div *ngIf="!isImageNotNull()">
          <img matCardImage src="assets/img/avatar.png" alt="assets/img/avatar.png" style="width: 100px;height: 100px;" />
            
          </div>
      </mat-card>
      
    </div>

  
   

</div>
  <div class="tab_section">
    <mat-tab-group [disableRipple]="false">
        <mat-tab label="Personal Information"  > 
          <div class="personal_info">
            <mat-card>
              <hr />
              Personal Information
              <hr />
        
              <table style="width: 300px; margin-top: 0px">
                <tr style="border-bottom: 1px">
                  <td><b>Full Name</b></td>
                  <td>{{ data.fullName }}</td>
                </tr>
                <tr>
                  <td><b>Email</b></td>
                  <td>{{ data.email }}</td>
                </tr>
                <tr>
                  <td><b>Last Seen</b></td>
                  <td>{{ data.lastSeen | date }}</td>
                </tr>
              </table>
              <hr />
              Adress
              <hr />
              <table style="width: 250px; margin-top: 10px">
                <tr style="border-bottom: 1px">
                  <td><b>Address</b></td>
                  <td>{{ address.address }}</td>
                </tr>
                <tr>
                  <td><b>City</b></td>
                  <td>{{ address.city }}</td>
                </tr>
                <tr>
                  <td><b>Country</b></td>
                  <td>{{ address.country }}</td>
                </tr>
                <tr>
                  <td><b>Phone Number </b></td>
                  <td>{{ address.phone }}</td>
                </tr>
              </table>
            </mat-card>
          </div>
        
        
        </mat-tab>
        <mat-tab label="Roles"  >   <app-role [userId]="data.id"></app-role>  </mat-tab>

        <mat-tab label="Admin Control"  >   
       
         
              <form action="">
                
                <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px" style="height: 40%;">
                  <mat-form-field appearance="outline">
                    <p>Extra Note For Admin</p>
                    <textarea matInput   name="description" [(ngModel)]="data.description"></textarea>
                  </mat-form-field>
                  <div class="">
                    <mat-checkbox name="disabledByAdmin" style="margin-right: 20px" [(ngModel)]="data.disabledbyAdmin"
                      class="example-margin">Disable User</mat-checkbox>
                  </div>
                  <div class="form_submit_section">
                    <div mat-dialog-actions>
                      <button mat-button (click)="onNoClick()">Cancel</button>
                      <button mat-button [mat-dialog-close]="data" cdkFocusInitial>
                        Save
                      </button>
                    </div>
                  </div>
                </div>
        </form>
  </mat-tab>


    </mat-tab-group>
  </div>
</div>