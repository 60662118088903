import { DatePipe } from '@angular/common';
import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DepositReciept } from 'src/app/modules/DepositReciept';
import { DepositeReceiptService } from 'src/app/service/depositeSerivce/deposite-receipt-service.service';
 import { DepositReciptDetailComponent } from '../deposit-recipt-detail/deposit-recipt-detail.component';

@Component({
  selector: 'app-deposite-receiptlist',
  templateUrl: './deposite-receiptlist.component.html',
  styleUrls: ['./deposite-receiptlist.component.css']

})
export class DepositeReceiptlistComponent implements OnInit {
  displayedColumns = ['id', 'customerName', 'Date', 'Amount','void_receipt', 'detail'];
  dataSource = new MatTableDataSource<DepositReciept>();

  total = 0
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;


  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });


  constructor(
    private deposteReciptService: DepositeReceiptService,
    public dialog: MatDialog,
    private snackbar: MatSnackBar,
    // private datePipe: DatePipe, 
  ) { }
  ngOnInit(): void {
    this.getSourcedata();
  }
  getSourcedata() {
    this.deposteReciptService.getAllDepositeReceiptByCasher().subscribe(
      data => {

        this.dataSource.data = data
        this.setTotal()
      },
      error => {
        this.snackbar.open("Retrieve Data Fail", "error")._dismissAfter(2000)
      }
    );

  }


  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  doFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  setTotal() {
    this.total = 0
    this.dataSource.data.forEach(element => {
      if(!element.receipt_void){
        this.total = this.total + element.amount
      }
   
    });
  }

  dateRangeFilter() {
    var datePipe = new DatePipe('en-GB');

    this.deposteReciptService.getAllDepositeReceiptCasherByDate(
      datePipe.transform(this.range.value.start, 'dd-MM-yyyy'),
      datePipe.transform(this.range.value.end, 'dd-MM-yyyy')
    ).subscribe(
      data => {

        this.dataSource.data = data
        this.setTotal()
      },
      error => {
        this.snackbar.open("Retrieve Data Fail", "error")._dismissAfter(2000)
      }
    );
  }
  depositDetail(id: number) {
  this.deposteReciptService.getDepositeReceiptById(id).subscribe(
    result => {
      this.openDialog(result)
    },
    error => {
      this.snackbar.open("Retrieve Data Fail", "error")._dismissAfter(2000)
    }
  )
   
  }

  openDialog(depositDetail: DepositReciept): void {
    const dialogRef = this.dialog.open(DepositReciptDetailComponent, {
      width: '650px',
      data: depositDetail,
    });
  }
}
