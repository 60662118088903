  
        <asl-google-signin-button    type='standard' size='medium' text="signin_with" width="150px"
        logo_alignment="center"   >    </asl-google-signin-button>

  <!-- <mat-card  class="flex_column_center_center">
     <div class="title" i18n>Social Login</div>
     <div class="sub-title" i18n>Choose your preferred social network to login</div>
     <div class="social_buttons flex_column_center_center" style="width: 180px;"> -->
      <!-- google button  --> 
      <!-- <asl-google-signin-button    type='standard' size='large' text="signin_with" width="300px"
      logo_alignment="center"
      > </asl-google-signin-button> -->
      <!-- google button end -->
        <!-- <button mat-flat-button color="primary"  ><i class="fab fa-facebook-square"></i> <span>Sign in with Facebook</span></button>
       <button mat-flat-button  style="background-color: rgb(0, 183, 255);color: white;" > <i class="fab fa-twitter"></i> Sign in with Twitter</button>
      <button mat-flat-button  style="background-color: rgba(132, 141, 145, 0.856);color: white;"  > <i class="fab fa-github"></i>Sign in with GitHub</button>
        -->
      <!-- </div>
     <div class="login_with_email">
      <p> <span  i18n>Login with Email  click  </span><a  color="primary" mat-button (click)="loginWithEmail()"  i18n>here</a></p>
     </div>
</mat-card>  
  -->
 
 
