 
  
<mat-drawer-container class="wrapper2" hasBackdrop="">
   <mat-drawer #drawer mode="over">
       <div class="left-side-draw">

           
   <app-setting-right-panel-list></app-setting-right-panel-list>

       </div>
   </mat-drawer>
   <mat-drawer-content>
       <div class="wrapper">

           <div class="left-side">

            <app-setting-right-panel-list></app-setting-right-panel-list>


           </div>
           <div class="right-side">
               <div class="right-side-card">
                   <button class="right-side-menu" mat-button (click)="drawer.toggle()"><i class="fas fa-bars"></i></button>
                </div>
               
             <div class="right-side-item">
                <mat-card style="height: 50px;">
                    <h2>Adminator Setting Page > {{getDescription()}}</h2>
                </mat-card>

               <app-users  *ngIf="index===0"></app-users>
                <app-location *ngIf="index===1"></app-location> 
                <app-currency *ngIf="index===2"></app-currency> 
                <app-sent-email-list *ngIf="index===3"></app-sent-email-list> 
               <app-system-info-list *ngIf="index===4"></app-system-info-list> 

             </div>
              



           </div>


       </div>




   </mat-drawer-content>
</mat-drawer-container>




