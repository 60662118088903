import { Component } from '@angular/core';

@Component({
  selector: 'app-activat-user-account-dialog',
  templateUrl: './activat-user-account-dialog.component.html',
  styleUrls: ['./activat-user-account-dialog.component.css']
})
export class ActivatUserAccountDialogComponent {

}
