import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
 
import { Post } from '../../modules/Post';
import { TokenStorageService } from '../tokenStorage/token-storage.service';
import { GlobalConstants } from 'src/app/utility/global-constants';
import { Post_Fraud } from 'src/app/modules/Post_Fraud';
 
 
const AUTH_API = GlobalConstants.serverUrl+'adv/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class PostFraudService {
  
  constructor(private http: HttpClient,
              private  tokenStorage: TokenStorageService) { }

 
getAllPost_Fraud() {
    
    return this.http.get<Post_Fraud[]>(`${AUTH_API}postfraud}`, httpOptions);
  }
  getPost_Fraud(id:number) {
  //  const uid = this.tokenStorage.getUserId();
    return this.http.get<Post_Fraud>(`${AUTH_API}postfraud/${id}`, httpOptions);
  }
  setPost_Fraud(f:Post_Fraud,pid:number) {
     const uid = this.tokenStorage.getUserId();
      return this.http.post(`${AUTH_API}postfraud/user/${uid}/post/${pid}`,f, httpOptions);
    }
   
   deletePostFraud(id: number) {
    return this.http.delete(`${AUTH_API}postfraud/${id}`, httpOptions);
  }
  getCurrentUserId() {
    return this.tokenStorage.getCurrentUserId();
  }
  

}
