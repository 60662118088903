 

    <mat-card fxLayout="column"    style="width: 100%;">  
        <mat-card-header fxLayoutAlign="center center" >
            <mat-card-title>Option List</mat-card-title>
           
        </mat-card-header>
        
        <mat-card-content>
          <div  fx-row fxLayout="row" fxLayoutAlign="space-between" >
              <mat-form-field fxFlex="40%" appearance="outline">
                <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Filter" />
              </mat-form-field>
              <a mat-button    (click)="newOption()" ><mat-icon class="mat-18">create_new_folder</mat-icon>New Option</a>
      
            </div>
            
            <mat-table [dataSource]="dataSource" matSort style="width:100%;">
              <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Id</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.id }}</mat-cell>
              </ng-container>
            
              <ng-container matColumnDef="item">
                <mat-header-cell *matHeaderCellDef mat-sort-header>item</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.item }}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="pvalue">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Parent Value</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.pvalue }}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="Edit">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Edit</mat-header-cell>
                  <mat-cell *matCellDef="let element"><a    mat-button  (click)="editOption($event, element.id)"  ><mat-icon class="mat-16">edit</mat-icon></a>  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Delete">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Delete</mat-header-cell>
                  <mat-cell *matCellDef="let element"><a    mat-button  (click)="deleteOption($event, element.id)"  ><mat-icon class="mat-16">delete</mat-icon></a>  </mat-cell>
                </ng-container>
 
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>
            <mat-paginator [pageSize]="10" [pageSizeOptions]="[1, 5, 10, 20]">
            </mat-paginator>
        </mat-card-content>
        <mat-card-actions align="start">
           
            
        </mat-card-actions>
 
    </mat-card>
 


