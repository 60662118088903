

export class SignupRequest {

    constructor(
        public fullName: String,
        public email: String,
        public socialProvider: "Local"|"Google"|"Facebook",
        public token:String,
        public  password: String,
        public  phone: String,
       
    ) { }


}