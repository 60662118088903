<section>


    <div class="left-section">
 
 
 
        <div class="personal-option">
            <ul>
                <!-- <li class="active"><a href="#"><i class="fas fa-tachometer-alt"></i>Dashboard </a></li> -->
                <li ><a routerLink="/admin/finance"><i class="fas  fa-list"></i>Deposite List </a></li>
                 <li class="active"><a routerLink="/admin/financereport"><i class="fas fa-wallet"></i> Report</a></li>
                <!-- <li><a href="#"><i class="fas fa-cog"></i> Privacy Settings</a></li> -->
 
 
            </ul>
        </div>
    </div>
    <div class="right-section" fxLayout="column"   fxLayoutGap="20px">
        
            
  </div>
 </section>

