import { Injectable } from "@angular/core";

const TOKEN_KEY = "auth-token";
const REFRESHTOKEN_KEY = 'auth-refreshtoken';
const USER_KEY = "auth-user";

@Injectable({
  providedIn: "root",
})
export class TokenStorageService {
  
  // constructor() {}

  // signOut() {
  //   // window.localStorage.clear();
  //     window.localStorage.clear();
    
  // }

  // public saveToken(token: string) {
   
  //   // window.localStorage.removeItem(TOKEN_KEY);
  //   // window.localStorage.setItem(TOKEN_KEY, token);
  //     window.localStorage.removeItem(TOKEN_KEY);
  //    window.localStorage.setItem(TOKEN_KEY, token);

  //    window.localStorage.removeItem(TOKEN_KEY);
  //    window.localStorage.setItem(TOKEN_KEY, token);
 
  //    const user = this.getUser();
  //    if (user.id) {
  //      this.saveUser({ ...user, accessToken: token });
  //    }
  // }

  // public getToken(): string {
  //   return window.localStorage.getItem(TOKEN_KEY);
  // }

  // public saveUser(user) {
  //   // window.localStorage.removeItem(USER_KEY);
  //   // window.localStorage.setItem(USER_KEY, JSON.stringify(user));
  //       window.localStorage.removeItem(USER_KEY);
  //   window.localStorage.setItem(USER_KEY, JSON.stringify(user));
  // }

  // public getUser() {
    
  //   // return JSON.parse(window.localStorage.getItem(USER_KEY));
  //    return JSON.parse(window.localStorage.getItem(USER_KEY));
  // }

  // public getUserImageName(){
  //   return JSON.parse(window.localStorage.getItem(USER_KEY)).image_name;
  // }

  // public getUserId() {
  //   return JSON.parse(window.localStorage.getItem(USER_KEY)).id;
  // }

  // public saveRefreshToken(token: string): void {
  //  window.localStorage.removeItem(REFRESHTOKEN_KEY);
  //  window.localStorage.setItem(REFRESHTOKEN_KEY, token);
  // }

  // public getRefreshToken(): string | null {
  //   returnwindow.localStorage.getItem(REFRESHTOKEN_KEY);
  // }


  constructor() { }

  signOut(): void {
   window.localStorage.clear();
  }

  public saveToken(token: string): void {
   window.localStorage.removeItem(TOKEN_KEY);
   window.localStorage.setItem(TOKEN_KEY, token);

    const user = this.getUser();
    if (user.id) {
      this.saveUser({ ...user, token: token });
    }
  }

  public getToken(): string | null {
    return window.localStorage.getItem(TOKEN_KEY);
  }

  public saveRefreshToken(token: string): void {
   window.localStorage.removeItem(REFRESHTOKEN_KEY);
   window.localStorage.setItem(REFRESHTOKEN_KEY, token);
  }

  public getRefreshToken(): string | null {
    return window.localStorage.getItem(REFRESHTOKEN_KEY);
  }

  public saveUser(user: any): void {
   window.localStorage.removeItem(USER_KEY);
   window.localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(): any {
    return JSON.parse(window.localStorage.getItem(USER_KEY));
  }

    public getUserId() {
    return JSON.parse(window.localStorage.getItem(USER_KEY)).id;
  }
    public isUserLoggedIn() {
    let cond = window.localStorage.getItem(USER_KEY);
      
      if(cond)  return  true;
      else  return  false;
  }
  public isRoleAvailable(role:string) {
    let u = this.getUser();
      if(u === null)  return false;

    if (u.roles.indexOf(role) > -1) {
      return  true;
    } else {
      
      return false;
    }
  }
    public getCurrentUserId() {
    return JSON.parse(window.localStorage.getItem(USER_KEY)).id;
  }
    public getUserName() {
   
      return JSON.parse(window.localStorage.getItem(USER_KEY)).username;
  }

  isAdminRole() {
   return  this.isRoleAvailable("ROLE_ADMIN");
  }
}

