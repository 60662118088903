<mat-card fxLayout="column"  fxLayoutGap="10px"  class="user-card" fx-of class="mat-elevation-z8"  >  
        
        
    <mat-card-content>
    <div fxLayoutAlign="space-between">
          <mat-form-field  appearance="outline">
            <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Filter" />
          </mat-form-field>
          <button mat-button (click)="newSystemInfo()" ><mat-icon class="mat-18">create_new_folder</mat-icon> New System Info</button>
              </div>
        
        <table mat-table [dataSource]="dataSource" matSort style="width: 100%;" class="mat-elevation-z8" style="margin-bottom: 20px;">
          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Id</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.id }}</mat-cell>
          </ng-container>
        
          <ng-container matColumnDef="name" >
            <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.name}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="value">
            <mat-header-cell *matHeaderCellDef mat-sort-header>value</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.value}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="action">
              <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
              <mat-cell *matCellDef="let element">
                  <a    mat-button  (click)="editSystemInfo($event, element.id)" ><mat-icon class="mat-16">edit</mat-icon></a> 
                  <a    mat-button  (click)="deleteSystemInfo($event, element.id)" ><mat-icon class="mat-16">delete</mat-icon></a> 
                 </mat-cell>
            </ng-container>
          
          
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </table>
        <mat-paginator [pageSize]="10" [pageSizeOptions]="[1, 5, 10, 20]">
        </mat-paginator>
    </mat-card-content>
   

</mat-card>

