<mat-card>
  <h1>FREQUENTLY ASKED QUESTIONS</h1>
  <hr />
  <div class="content">
    <p><strong>How do I place an ad?</strong></p>
    <p>
      It's very easy to place an ad: After Registration click "Post Ads" button  above
      right.
    </p>
    <p><strong>What does it cost to advertise?</strong></p>
    <p>The publication is 100% free throughout the website.</p>
    <p><strong>If I post an ad, will I also get more spam e-mails?</strong></p>
    <p>
      Absolutely not because your email address is not visible on the website.
    </p>
    <p><strong>How long will my ad remain on the website?</strong></p>
    <p>
      In general, an ad is automatically deactivated from the website after 3
      months. You will receive an email a week before D-Day and another on the
      day of deactivation. You have the ability to put them online in the
      following month by logging into your account on the site. After this
      delay, your ad will be automatically removed permanently from the website.
    </p>
    <p><strong>I sold my item. How do I delete my ad?</strong></p>
    <p>
      Once your product is sold , log in to your account to remove your
      ad.
    </p>
  </div>
</mat-card>
 
