 
<mat-drawer-container class="wrapper2" hasBackdrop="">
    <mat-drawer #drawer mode="over">
        <div class="left-side-draw">
            <div class="personal-info">
                <img src="{{getUserImageUrl(user.image_name)}}"  alt="">
                <div class="personal-name"><h2>{{user.fullName}}</h2></div>
                <div class="personal-joined"><h3 ><span i18n>Joined :</span>  {{user.createdDate |dateAgo }}</h3></div>
                <p *ngIf="isUserLogIn()"><span i18n>Phone :</span> <span style="color: #00a651;">{{user.contact.phone}}</span>  </p>
                <p  class="view_contact" *ngIf="!isUserLogIn()"><span i18n>Phone : </span><span style="color: #00a651;"> <a mat-flat-button (click)="viewPhoneNumber()"  >View Phone Number</a></span>  </p>
            </div>

        </div>
    </mat-drawer>
    <mat-drawer-content>
        <div class="wrapper2">

            <div class="left-side">
                <div class="personal-info">
                    <img src="{{getUserImageUrl(user.image_name)}}"  alt="">
                    <div class="personal-name"><h2>{{user.fullName}}</h2></div>
                    <div class="personal-joined"><h3 ><span i18n>Joined :</span>  {{user.createdDate |dateAgo }}</h3></div>
                    <p *ngIf="isUserLogIn()"><span i18n>Phone :</span> <span style="color: #00a651;">{{user.contact.phone}}</span>  </p>
                    <p  class="view_contact" *ngIf="!isUserLogIn()"><span i18n>Phone : </span><span style="color: #00a651;"> <a mat-flat-button (click)="viewPhoneNumber()"  >View Phone Number</a></span>  </p>
                </div>


            </div>
            <div class="right-side">
                <mat-card>
                    <button class="right-side-menu" mat-icon-button (click)="drawer.toggle()"><i class="fas fa-ellipsis-v"></i></button>
                 </mat-card>
                <div class="ads-list"   >
 
  
 
                    <div class="product-card"  *ngFor="let p of posts | async   ">
                     
                  
                        <div class="product-image" >
                            <a routerLink="/display" [queryParams]="{pid:p.id}"> <img src="{{getImageUrl(p)}}" width="150" height="150"   alt=""></a> 
                            
                     
                        </div>
                        <div class="product-data">
                          <div class="post-date"><p>  posted on {{p.post_status.apply_date |  dateAgo}}</p></div>
                          <div class="price-tag"><h2>{{displayPrice(p)}}</h2></div>
                          <div class="post-title"><h3><p>{{p.description}}</p></h3></div>
                          <div class="add-info"><p>{{p.detail}}</p></div>
                          
                        </div>
                    </div>
            
            
               <div class="paginator">
                <mat-paginator [length]="100"
                [pageSize]="25"
                [pageSizeOptions]="[10, 25, 50]"
                aria-label="Select page">
  </mat-paginator>
                  </div>
               </div>



            </div>


        </div>




    </mat-drawer-content>
</mat-drawer-container>




<section >


    
   
       
 
</section>
