<mat-card fxLayout="column"  fxLayoutGap="10px"  class="user-card" fx-of class="mat-elevation-z8"  >  
        
        
    <mat-card-content>
    <div fxLayoutAlign="space-between">
 
              </div>
        
        <table mat-table [dataSource]="dataSource" matSort style="width: 100%;"   style="margin-bottom: 20px;">
          <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef >Date</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.date |date }}</mat-cell>
          </ng-container>
        
          <ng-container matColumnDef="type" >
            <mat-header-cell *matHeaderCellDef >Type</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.transactionType}}</mat-cell>
          </ng-container>
           
          <ng-container matColumnDef="deposite">
            <mat-header-cell *matHeaderCellDef >Deposite</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.deposite_amount}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="expense">
            <mat-header-cell *matHeaderCellDef >Expense</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.expense_amaount}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="balance">
            <mat-header-cell *matHeaderCellDef >Balance</mat-header-cell>
            <mat-cell *matCellDef="let element" style="font-style: italic;font-weight: 500;color: #686263;">{{ element.balance}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="show">
            <mat-header-cell *matHeaderCellDef ></mat-header-cell>
            <mat-cell *matCellDef="let element"> <button mat-button color="primary" (click)="showRowDetail(element)"> <i class="fas fa-eye"></i> </button> </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </table>
        <div class="balance">
          <p>Balance : <u>{{getBalance()}} SSP</u></p>
        </div>
        <mat-paginator [pageSize]="20" [pageSizeOptions]="[20, 50, 100]">
        </mat-paginator>
    </mat-card-content>
   

</mat-card>

