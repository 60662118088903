
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Post_Fraud } from 'src/app/modules/Post_Fraud';
import { AdminPostFraudService } from 'src/app/service/post/AdminPostFraudService';
import { GlobalConstants } from 'src/app/utility/global-constants';
const AUTH_PATH=GlobalConstants.serverUrl+"adv/"
@Component({
  selector: 'app-post-fraud',
  templateUrl: './post-fraud.component.html',
  styleUrls: ['./post-fraud.component.css']
})
export class PostFraudComponent implements OnInit {
  displayedColumns = ['id', 'abuseReporter','case_status','date','detail'];
  dataSource = new MatTableDataSource<Post_Fraud>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  title:String ;
  title_date;
 

  constructor(
    private postFraudService: AdminPostFraudService,
    public dialog: MatDialog,
    private snackbar:MatSnackBar
  ) {}
  ngOnInit(): void {

 

    this.getSourcedata();

  }
  getSourcedata(){
    this.postFraudService.getAllPostFraud().subscribe(
      data=> {
        
        this.dataSource.data =data
      },
      error=>{
        this.snackbar.open("Retrieve Data Fail","error")._dismissAfter(2000)
      }
    );
    
  }
  getSourcedataWidthArgs(closed :boolean){
    this.postFraudService.getAllPost_FraudByC(closed).subscribe(
      data=> {
        
        this.dataSource.data =data
      },
      error=>{
        this.snackbar.open("Retrieve Data Fail","error")._dismissAfter(2000)
      }
    );
    
  }
   
//  async getSourcedataNotPending(status:String){
//      const  a=await  this.postFraudService.getAllPostByStatus(this.title).toPromise()
//       if(a)   this.dataSource.data=a
      
//     }

//   ngAfterViewInit() {

//     this.dataSource.paginator = this.paginator;
//   }

 
 
  
  openSnackBar( message,type) {
    this.snackbar.open(message, type, {
      duration: 2000,
    });
  }
 
 
 

  onStatusChange(ev){
       console.log("status change ==>"+JSON.stringify(ev.value))
  if(ev.value ==="all") this.getSourcedata()
  else  this.getSourcedataWidthArgs(ev.value)
}
}
