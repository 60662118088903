import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SentEmailToUser } from 'src/app/modules/SentEmailToUser';
 

@Component({
  selector: 'app-sent-email-detail-dialog',
  templateUrl: './sent-email-detail-dialog.component.html',
  styleUrls: ['./sent-email-detail-dialog.component.css']
})
export class SentEmailDetailDialogComponent  implements OnInit {
  title: string;
  message: string;

  constructor(public dialogRef: MatDialogRef<SentEmailDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SentEmailToUser) {
    // Update view with given values
     
  }

  ngOnInit() {
  }

  onOk(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }
 
}

