<form action=""      #f="ngForm"   >
    <section fxLayout="column" fxLayoutAlign="center center" > 
   
   
    <h1 mat-dialog-title> New/Edit Option  </h1>
   <div mat-dialog-content fxLayout="column"  style="width: 100%;" >
     
     <mat-form-field appearance="outline">
       <input type="text" matInput  placeholder="Id"  required name="id" disabled [(ngModel)]="data.id" >
     </mat-form-field>
     
     <mat-form-field  appearance="outline">
       <input type="text" matInput placeholder="name" required name="name1" minlength="2" [(ngModel)]="data.item" >
   
     </mat-form-field>
     <mat-form-field  appearance="outline">
      <input type="text" matInput placeholder="Parent Value"   name="pvalue"   [(ngModel)]="data.pvalue" >
  
    </mat-form-field>
    
 
   </div>
   <div mat-dialog-actions>
     <button mat-button (click)="onNoClick()">Cancel</button>
      <button   [disabled]="f.invalid" mat-button [mat-dialog-close]="data" cdkFocusInitial>Save</button>
   </div>
   
   </section>
   </form>
