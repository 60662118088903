<!-- public id: number,
public receipt_No: number,
public amount: number,
public description?:String,
public  post?:Post,
public created_date?:Date -->

<div class="container">
    <div class="title">Ads Detail Note</div>
    <div class="body">
        <div class="description">  <pre>{{postReceipt.description}}</pre>  </div>
    </div>
</div>