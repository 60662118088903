import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { MessageDialogComponent } from 'src/app/utility/message-dialog/message-dialog.component';
import { ConfirmDialogModel } from 'src/app/utility/ConfirmDialogModel';
 


@Injectable({
  providedIn: 'root'
})
export class MessageDialogSerice {
 
  constructor(private dialog: MatDialog) {}

  messageDialog(message:string,title:string): void {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: '300px',
      data: new ConfirmDialogModel(title,message),
    });

       dialogRef.afterClosed().subscribe((result) => {
      if(result){
        
      }else {
         
      }
      
    });
  
  }
  
  
}
