import { Component } from '@angular/core';

@Component({
  selector: 'app-no-ads-found',
  templateUrl: './no-ads-found.component.html',
  styleUrls: ['./no-ads-found.component.css']
})
export class NoAdsFoundComponent {

}
