import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Validators, FormBuilder, FormControl } from '@angular/forms';
import { UserService } from 'src/app/service/user/user.service';
import { User } from 'src/app/modules/User';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DataUrl, NgxImageCompressService, UploadResponse } from 'ngx-image-compress';
 

@Component({
  selector: 'app-change-profile-image',
  templateUrl: './change-profile-image.component.html',
  styleUrls: ['./change-profile-image.component.css']
})
export class ChangeProfileImageComponent implements OnInit {
  @Input() chooseLabel = 'Upload Image'
  @Input() deleteButtonLabel
  @Input() deleteButtonIcon = 'close'
  @ViewChild('fileUpload') fileUpload: ElementRef
 
  url:string
    //new upload variables
    imgResultAfterCompress: DataUrl 
 
    imgResultSingle: UploadResponse 
    imgResultCount:number=0

  constructor(private userService: UserService,
              private router:Router,
              private snackbar: MatSnackBar,
              private imageCompress: NgxImageCompressService,
               ) { }

  ngOnInit(): void {
   

    this.getSource()
  }
  async getSource() {
  const s=await   this.userService.getUserImageUrl().toPromise();
 
    if(s != undefined) {
      this.url=s.message
    }else{
      this.url = "assets/img/avatar.png"
      this.openSnackBar("User Image retrieve fail ","Error")
    }
  }

   async onSubmit() {
       
      if(this.imgResultAfterCompress){
      //  this.inputFileName = event.dataTransfer ? event.dataTransfer.files : event.target.files;

      const   i=await  this.userService.saveUserImage(this.imgResultAfterCompress).toPromise()
      if(i){
        this.openSnackBar("Uplode Done ","Message")
           
      location.reload()
      } else {
           
        this.openSnackBar("Upload Fail","Error")
            }
        }
   
     }
  
  
  async uploadSingleFile() {
     
      this.imageCompress
        .uploadFile()
        .then(async (multipleOrientedFiles: UploadResponse) => {
          this.imgResultSingle = multipleOrientedFiles;
        const imgwidth=await this.getImageWidth(this.imgResultSingle.image)
        var size= this. getImageSize(this.imgResultSingle.image)
             if(imgwidth>800){
            console.log("  Image width is greater than 800px ==>"+ imgwidth);
            console.log("  Image size  ==>"+ size);
            if(   size<5) {  //  if( size>0.5 && size<5) {
             var ratio=(Number)((0.8*100)/size)
            //  console.log("  ratio output "+ ratio);
             this.imageCompress
             .compressFile(this.imgResultSingle.image, this.imgResultSingle.orientation, ratio, 80)
             .then((result: DataUrl) => {
             
               this.imgResultAfterCompress = result;
               this.onSubmit()
              
                   });
            }else {
              if(size>= 5){
                this.openSnackBar("max image shouldn't be Greater than 5MB ","Error")
                console.log("max image size 5MB");
              } 
            }if(size<=0.5){
              console.log("Image No need for compression ");

           
              this.imgResultAfterCompress=this.imgResultSingle.image
              this.onSubmit()
           
            }
          }else{
            this.openSnackBar(" image width should be Greater 800px ","Error")
            console.log("min Image width 800px ==>"+ imgwidth);
             }
             });
      
        
       
  
    }
    getImageWidth(file:any) {
    
      return new Promise<number>((resolve, reject) => {
      const img = new Image();
          img.src = file as string;
          img.onload = () => {
            const height = img.naturalHeight;
            const width = img.naturalWidth;
            resolve(width)
          };
        
      })
     }
      getImageSize(file:any){
        var size=this.imageCompress.byteCount(file);
        return (Number)(size/(1024*1024))
      }

 
  openSnackBar( message,type) {
    this.snackbar.open(message, type, {
      duration: 2000,
    });   
  }
}