import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-how-it-work',
  templateUrl: './how-it-work.component.html',
  styleUrls: ['./how-it-work.component.css']
})
export class HowItWorkComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


  howToRegister(){
    const url = 'https://youtu.be/5taf2e6QbKY';
    window.open(url, '_blank');
  }

}
