import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SentEmailToUser } from 'src/app/modules/SentEmailToUser';
import { SentEmailToUserService } from 'src/app/service/SentEmailToUserService/sent-email-to-user-service.service';
 
@Component({
  selector: 'app-user-send-email-dialog',
  templateUrl: './user-send-email-dialog.component.html',
  styleUrls: ['./user-send-email-dialog.component.css']
})
export class UserSendEmailDialogComponent implements OnInit {

  emailForm: FormGroup;
  constructor(public dialogRef: MatDialogRef<UserSendEmailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public user_id: number,
    public  sentEmailToUserService:SentEmailToUserService,
    public snackBar: MatSnackBar) {
    
      this.emailForm = new FormGroup({
        title: new FormControl('', Validators.required),
         message: new FormControl('', Validators.required)
      });
  }

  ngOnInit() {
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }


 

 

 

    submitForm() {
    if (this.emailForm.valid) {
 
         let email=new SentEmailToUser(this.emailForm.value.title,this.emailForm.value.message)
         console.log("sent Email  To Client ==> "+JSON.stringify(email));
      this.sentEmailToUserService.saveNewSentEmailToUser(email,this.user_id).subscribe(
       r=>{
        this.openSnackBar("Email Sent Successfully!!","Message")
        this.dialogRef.close(true);
       },e=>{
          this.openSnackBar(JSON.stringify(e.error.message),"Error")
          this.dialogRef.close(true);
       }


     )
        
    }
  }
  //import {MatSnackBar} from '@angular/material';
  //constructor(public snackBar: MatSnackBar) {}
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
  
 