<div class="container">
  <h2>Send Email To Client</h2>
  <form [formGroup]="emailForm" (ngSubmit)="submitForm()">
    <mat-form-field appearance="outline">
      <mat-label>Title</mat-label>
      <input matInput  formControlName="title" required>
      <mat-error *ngIf="emailForm.get('title').invalid && emailForm.get('title').touched">
        Tiitle is required.
      </mat-error>
    </mat-form-field>
  
      <mat-form-field appearance="outline">
        <mat-label>Message</mat-label>
        <textarea matInput formControlName="message" rows="5" required></textarea>
       
        <mat-error *ngIf="emailForm.get('message').invalid && emailForm.get('message').touched">
          Message is required.
        </mat-error>
      </mat-form-field>
  <div class="button">
    <button mat-raised-button color="primary" type="submit" [disabled]="emailForm.invalid">Submit</button>

  </div>
     <mat-hint>* Please Don't include in your message  Dear .... and  Best Regards ... it will be includede automatically</mat-hint> 
    </form>
</div>
