import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
 
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { AuthenticationService } from 'src/app/service/login/Authentication.service';
import { TokenStorageService } from 'src/app/service/tokenStorage/token-storage.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SignupRequest } from 'src/app/modules/forms/SignupRequest';
import { SocialAuthentication } from 'src/app/service/login/SocialAuthentication.service';

 
@Component({
  selector: 'app-social-login',
 templateUrl: './social-login.component.html',
  styleUrl: './social-login.component.css'
})
export class SocialLoginComponent {
 
  user!: SocialUser;

  @Output() social_login = new EventEmitter<string>();
  
  constructor(private authService: SocialAuthService,
    private socialAuth: SocialAuthentication,
              private tokenStorage: TokenStorageService ,
              private router : Router ,
              private snackbar:MatSnackBar
  ) { }

  
  ngOnInit() {
    this.authService.authState.subscribe((user) => {
      this.user = user;
      this.loginWithGoogle()
      console.log("Social User Data 1==> "+JSON.stringify(user));
    });
  }
 
  signInWithGoogle(): void {
   
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(user => {
      this.user = user;
      this.loginWithGoogle()
      console.log("Social User Data2 ==> "+JSON.stringify(user));
      
    });
  }
  // signOut():void{
  //   this.authService.signOut()
  // }

 loginWithGoogle() {
   var  signUpRequest=new SignupRequest(this.user.name,this.user.email,"Google",this.user.idToken,"","")
    
  this.socialAuth.loginWithGoogle(signUpRequest).subscribe(
    data => {
      this.tokenStorage.saveUser(data);
      this.tokenStorage.saveToken(data.token);
      this.tokenStorage.saveRefreshToken(data.refreshToken);
      console.log("RETURN DATA == > "+JSON.stringify(data))

      this.router.navigate(["/"])  .then(() => {
        window.location.reload();
      });
    
    },
    err => {
     console.log("social login error ==>"+JSON.stringify(err));
     
      this.openSnackBar("Login Fail Try with Email")
    }
  );
}
openSnackBar( message) {
  this.snackbar.open(message, "Error", {
    duration: 2000,
  });
}

// change social login to email login
loginWithEmail(){
  this.social_login.emit("false");
}
}
