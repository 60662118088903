import { Component } from "@angular/core";
import { FormControl } from "@angular/forms";
import { CommonService } from "src/app/service/common/common.service";
import { CurrencyService } from "src/app/service/currency/currency.service";

 

@Component({
  selector: 'app-demo2',
  templateUrl: './demo2.component.html',
  styleUrls: ['./demo2.component.css']
})
export class Demo2Component   {
   
  data!:any
  constructor(private commonService:CommonService) {}

  onClick(){
    this.commonService.test().subscribe(
      r=> {
          this.data=r
      },e=> {
        this.data=e;
      }
    )
  }
}