import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MessageDialogSerice } from '../messageDialog/MessageDialog.service';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  loading = new Subject<boolean>();
  private isDestroyed: boolean = false;
  constructor(private messageDialogService: MessageDialogSerice) {
 
    }

  show() {
    // console.log("process Data button ==>2");
    this.isDestroyed=false
    this.loading.next(true);
    this.checkConnection();
   
  }

  hide() {
    this.loading.next(false);
    this.isDestroyed=true
    // console.log("process Data button ==>3");
  }

  // next test code
  private checkConnection() {
    // Start a timer for 10 seconds


    const timer = setTimeout(() => {
      if (!this.isDestroyed) {
        // console.log("process Data button ==>3");
        this.showError();
      }
    }, 10000);
 
  }
  

  private showError() {
    this.hide()
    this.messageDialogService.messageDialog("your Connection is slow ", "Warning")
  }
}
