<form action=""      #f="ngForm"   >
    <section fxLayout="column" fxLayoutAlign="center center" > 
   
   
    
   <div mat-dialog-content fxLayout="column"  style="width: 100%;" >
   
     <mat-form-field appearance="fill">
        <mat-label>Select Role</mat-label>
        <mat-select  [(ngModel)]="data" placeholder="name" required name="name">
          <mat-option *ngFor="let role of roles" [value]="role">
            {{role.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
 
     </div>
   <div mat-dialog-actions>
     <button mat-button (click)="onNoClick()">Cancel</button>
      <button   [disabled]="f.invalid" mat-button [mat-dialog-close]="data" cdkFocusInitial>Add</button>
   </div>
   
   </section>
   </form>
