import { User } from "./User";

export class Post_status {

  public static pending:String="PENDING"
  public static active:String="ACTIVE"
  public static error:String="ERROR"
  public static disabled:String="DISABLED"
  public static archive:String="ARCHIVE"
  public static expired:String="EXPIRED"
  public static deleted:String="DELETED"


   constructor(

    public id: number,
    public status: "PENDING"|"ACTIVE"|"ERROR"|"DISABLED"|"ARCHIVE"|"EXPIRED"|"DELETED",
    public apply_date?:Date ,
    public rejectedByUser?: User,
    public rejectionReason?:String,
    public rejected_date?: Date,
    public expire_date?:Date,
    public deleted_date?:Date,
    public approvedByUser?: User,
    public approved_date?: Date,
    public diasabledByUser?: User,
    public disabled_date?: Date

  ) {}
}
