<!-- <div class="post-management">
    <mat-tab-group   [selectedIndex]="0"   
    >
       <mat-tab label="STATUS"    >   <app-admin-postlist></app-admin-postlist> </mat-tab>
       <mat-tab label="ABUSE NOTIFICATION"  > <app-post-fraud></app-post-fraud></mat-tab>
     
       
        
   </mat-tab-group>
 </div> --> 
  
<mat-drawer-container class="wrapper2" hasBackdrop="">
  <mat-drawer #drawer mode="over">
      <div class="left-side-draw">

          
  <app-admin-post-right-panel></app-admin-post-right-panel>

      </div>
  </mat-drawer>
  <mat-drawer-content>
      <div class="wrapper">

          <div class="left-side">

           <app-admin-post-right-panel></app-admin-post-right-panel>


          </div>
          <div class="right-side">
              <div class="right-side-card">
                  <button class="right-side-menu" mat-button (click)="drawer.toggle()"><i class="fas fa-bars"></i></button>
               </div>
              
            <div class="right-side-item">
               <mat-card style="height: 50px;">
                   <h2>Adminator Post Managment > {{getDescription()}}</h2>
               </mat-card>
 
           <app-admin-postlist *ngIf="index===0"></app-admin-postlist>  
             <app-post-fraud  *ngIf="index===1"></app-post-fraud> 
            
              
            </div>
             



          </div>


      </div>




  </mat-drawer-content>
</mat-drawer-container>






