import { Component, OnInit } from '@angular/core';
import {  Validators, FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Notification } from 'src/app/modules/Notification';
import { PasswordChange } from 'src/app/modules/PasswordChange';
import { AuthenticationService } from 'src/app/service/login/Authentication.service';
import { UserService } from 'src/app/service/user/user.service';

@Component({
  selector: 'app-manage-notification',
  templateUrl: './manage-notification.component.html',
  styleUrls: ['./manage-notification.component.css']
})
export class ManageNotificationComponent {

   notification_id=-1
  notification=new Notification(-1,null,true,true,true,true);
  form=this.fb.group({
    subscription: [true ],
    messages: [true ],
    info_about_your_Ads: [true],
    hot_deals_recomendations: [true],

   });
  constructor(private  userService:UserService,
              private fb: FormBuilder,
              private snackbar:MatSnackBar) { }

  ngOnInit(): void {
     this.userService.getUserNotification().subscribe(
      r=>{
        this.notification=r;
        this.notification_id=r.id
        
        this.setDataToForm()
        
      },e=>{
        this.openSnackBar("Getting Email Notifcation Setting Fail","Error")
      }
     )
   
  }
  public setDataToForm(){
    this.form.setValue({
      subscription: this.notification.subscription,
    messages: this.notification.messages,
    info_about_your_Ads: this.notification.info_about_your_Ads,
    hot_deals_recomendations: this.notification.hot_deals_recomendations,
   });
  }
  

  onSubmit(){
    this.notification.user=null;
    this.notification.hot_deals_recomendations=this.form.value.hot_deals_recomendations
    this.notification.info_about_your_Ads=this.form.value.info_about_your_Ads;
    this.notification.subscription=this.form.value.subscription
    this.notification.messages=this.form.value.messages
 this.notification.id=this.notification_id
    console.log("this. notification =>"+JSON.stringify(this.notification))
  this.userService.setUserNotification(this.notification).subscribe(
    r=>{
      this.openSnackBar(r.message,"Info")

    } ,e=> {
      this.openSnackBar(JSON.stringify(e),"Error")
    }
  )
 

     }

 
 openSnackBar(message: string, action: string) {
  this.snackbar.open(message, action, {
    duration: 2500,
  });
 }
     
}
