 
              <div class="right-side-item">
                <app-personal-information class="lower-item"></app-personal-information>

                <app-change-address class="lower-item"></app-change-address>

                <app-change-password class="lower-item"></app-change-password>

                <app-change-phone class="lower-item"></app-change-phone>

                <app-change-profile-image class="lower-item"></app-change-profile-image>

              </div>
               


 
 